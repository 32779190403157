<iframe
  [src]="getUrl()"
  [style.height]="data().height"
  [style.width]="data().width"
  style="border-style: none"
  id="iframe"
  allow="camera; microphone"
>
  <p>Your browser does not support iframes.</p>
</iframe>
