import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { UserConfigurationDto } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import { NotificationService } from "@smallstack/i18n-components";
import { WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { BaseWidgetComponent, FormComponent, SchemaFormTableComponent, Widget } from "@smallstack/widget-core";
import { CustomerStore } from "../../stores/customer.store";
import { UserStore } from "../../stores/user.store";

@Widget({
  name: "UserConfigurationEditor",
  templateName: "Benutzer Konfigurationseditor",
  templateDescription: "Ein Editor um die Benutzerkonfigurationen zu bearbeiten.",
  icon: "wrench",
  dataSchema: {
    type: "object",
    properties: {
      userOrCustomer: {
        type: "string",
        title: "Benutzer oder Kunde",
        description: "Wähle Sie hier ob Benutzer oder Kunden bearbeitet werden sollen.",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_SELECT,
          values: [
            {
              value: "user",
              label: "Benutzer"
            },
            {
              value: "customer",
              label: "Kunde"
            }
          ]
        }
      },
      userContextVariable: {
        type: "string",
        title: "Kontext Variable",
        description: "Kontext Variable, welche den Benutzer enthält."
      },
      schema: {
        type: "object",
        "x-schema-form": {
          widget: "monaco"
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-user-configuration-editor-widget",
  imports: [CommonModule, LoadingElementDirective, FormComponent, SchemaFormTableComponent],
  templateUrl: "./user-configuration-editor-widget.component.html",
  styleUrls: ["./user-configuration-editor-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserConfigurationEditorWidgetComponent extends BaseWidgetComponent {
  private readonly user = computed(() => this.getContextReplacedData("userContextVariable"));
  protected values: any = {};
  protected currentValues = computed(() => {
    const user = this.user();
    const values: any = {};
    if (user?.configuration)
      for (const configuration of user.configuration) values[configuration.key] = configuration.value;
    return values;
  });

  constructor(
    private readonly customerStore: CustomerStore,
    private readonly userStore: UserStore,
    private notificationService: NotificationService
  ) {
    super();
  }

  protected setValues(values: any): void {
    this.values = values;
  }

  protected saveConfiguration() {
    return async (): Promise<void> => {
      const user = this.user();
      if (!user?.configuration) user.configuration = [];
      for (const key in this.values) {
        const index = user.configuration?.findIndex((c: UserConfigurationDto) => c.key === key);
        if (index !== -1) user.configuration[index].value = this.values[key];
        else user.configuration.push({ key, value: this.values[key] });
      }
      if (this.data().userOrCustomer === "user") await this.userStore.put(user);
      else await this.customerStore.put(user);
      this.notificationService.notification.success("Konfiguration gespeichert");
      if (this.widgetTreeService.dialogRef) this.widgetTreeService.dialogRef.close();
    };
  }
}
