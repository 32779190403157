import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from "@angular/material/dialog";
import { MatFormField, MatSuffix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { ContactDto } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { BehaviorSubject, Subscription } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-crm-contact-person-select",
  templateUrl: "./contact-person-select.component.html",
  styleUrls: ["./contact-person-select.component.scss"],
  imports: [
    MatFormField,
    MatInput,
    MatSuffix,
    IconComponent,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    MatDialogClose,
    I18nComponent
  ]
})
export class ContactPersonSelectComponent implements OnDestroy {
  @Input()
  public contactPersons: ContactDto[];

  @Output()
  public readonly selectedContactPerson: EventEmitter<ContactDto> = new EventEmitter();

  public filteredContactPersons: ContactDto[];

  private searchText$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private sub: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() data: any,
    @Optional() private dialogRef: MatDialogRef<any>
  ) {
    if (data?.contactPersons) this.contactPersons = data.contactPersons;

    this.filteredContactPersons = this.contactPersons;
    this.sub = this.searchText$
      .pipe(
        debounceTime(300),
        tap((searchText) => {
          if (typeof searchText === "string") {
            this.filteredContactPersons = this.contactPersons?.filter((cp) => {
              if (cp.firstName?.toLowerCase().includes(searchText.toLowerCase())) return true;
              if (cp.lastName?.toLowerCase().includes(searchText.toLowerCase())) return true;
              if (cp.email?.toLowerCase().includes(searchText.toLowerCase())) return true;
              return false;
            });
          } else this.filteredContactPersons = this.contactPersons;
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public selectContactPerson(person: ContactDto): void {
    this.selectedContactPerson.emit(person);
    if (this.dialogRef) this.dialogRef.close(person);
  }

  public searchChanged(searchChanged: any): void {
    this.searchText$.next(searchChanged.srcElement.value);
  }

  public clearSearch(): void {
    this.searchText$.next("");
  }
}
