import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ContextService, CountUpDirective, CountUpOptions } from "@smallstack/common-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { convertNumberFormat } from "@smallstack/legacy-utils";
import { LoaderComponent } from "@smallstack/store-components";
import { BaseWidgetComponent, WidgetConfigurationSchema } from "@smallstack/widget-core";

@Component({
  selector: "external-count-widget",
  templateUrl: "./external-count-widget.component.html",
  styleUrls: ["./external-count-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LoaderComponent, CountUpDirective, I18nComponent]
})
export class ExternalCountWidgetComponent extends BaseWidgetComponent implements OnInit {
  public static configuration: WidgetConfigurationSchema = {
    type: "object",
    properties: {
      propertyName: { type: "string", title: "Welche Eigenschaft soll angezeigt werden?" },
      title: { type: "string", title: "Text unter der Zahl", default: "" },
      externalDataSourceUrl: { type: "string", title: "Externe URL für Datenabfrage", default: "" },
      usePersonalToken: { type: "boolean", title: "Persönlichen Authentifizierungstoken verwenden?", default: false },
      enableCountUp: { type: "boolean", default: true, title: "Animation der Zahlen?" }
    }
  };

  public countUp: number;
  public countString: string;
  public enableCountUp: boolean = true;
  public link: string;
  public options: CountUpOptions = { separator: ".", decimal: "," };

  private serviceToken: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private contextService: ContextService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.enableCountUp = this.data().enableCountUp;

    const headers: any = {};

    if (this.data().usePersonalToken) {
      this.serviceToken = this.contextService.context().token;
      headers.Authorization = "Bearer " + this.serviceToken;
    }

    if (this.data().externalDataSourceUrl) {
      this.httpClient.get<any>(this.data().externalDataSourceUrl, { headers }).subscribe((response) => {
        if (response) {
          const tmp = convertNumberFormat(response[this.data().propertyName]);
          this.countUp = tmp.digits;
          this.countString = tmp.letter;
          if (this.countUp % 1 > 0) this.options.decimalPlaces = 1;
          else this.options.decimalPlaces = 0;
          this.cdr.markForCheck();
        }
      });
    }
  }

  public widgetClick(): Promise<boolean> {
    if (this.link) {
      return this.router.navigate(["../" + this.link], { relativeTo: this.route });
    }
  }
}
