import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { ActivatedRoute, Params } from "@angular/router";
import { ContactDto } from "@smallstack/axios-api-client";
import { BreakpointService, DetailDialogData, NonEmptyStringPipe } from "@smallstack/common-components";
import { ContactsStore } from "@smallstack/crm-components";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import { I18nComponent } from "@smallstack/i18n-components";
import { StoreState } from "@smallstack/store";
import {
  FilterName,
  ListContainerComponent,
  MatSortStoreDirective,
  StoreSearchComponent
} from "@smallstack/store-components";
import { AvatarImageComponent, IconComponent } from "@smallstack/theme-components";
import { TYPE_CONTACTS } from "@smallstack/typesystem";
import { DisplayNamePipe } from "@smallstack/user-components";
import { BaseWidgetComponent, TypeDialogService, WidgetConfigurationSchema } from "@smallstack/widget-core";
import { ContactDetailDialogComponent } from "./contact-detail-dialog/contact-detail-dialog.component";

@Component({
  selector: "smallstack-contacts-list-widget",
  templateUrl: "./contacts-list-widget.component.html",
  styleUrls: ["./contacts-list-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    StoreSearchComponent,
    ListContainerComponent,
    MatSortStoreDirective,
    MatSortModule,
    MatTableModule,
    I18nComponent,
    AvatarImageComponent,
    DisplayNamePipe,
    AsyncPipe,
    IconComponent,
    NonEmptyStringPipe
  ]
})
export class ContactsListWidgetComponent extends BaseWidgetComponent implements OnInit {
  public readonly FILTER_NAMES: FilterName[] = [
    { label: "Vorname", value: "firstName" },
    { label: "Nachname", value: "lastName" },
    { label: "Firma", value: "company" },
    { label: "Email", value: "email" },
    { label: "Firmen Telefon", value: "companyPhone" },
    { label: "Telefon", value: "phone" },
    { label: "Mobiltelefon", value: "mobile" }
  ];

  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        columns: {
          title: "Anzuzeigende Daten",
          description: "Definieren Sie, welche Felder des Kontakts als Tabellenspalten angezeigt werden sollen.",
          type: "array",
          items: {
            type: "string",
            enum: ["avatar", "firstName", "lastName", "company", "email", "phone", "mobile", "actions"],
            "x-i18n-prefix": "@@widgets.contacts.columns."
          } as SchemaFormPropertyOptions,
          default: ["avatar", "firstName", "lastName", "email", "actions"],
          "x-schema-form": {
            "x-multiselect-mode": "multiselect"
          }
        },
        showSearchBar: {
          title: "Suchleiste anzeigen",
          type: "boolean",
          default: true
        }
      }
    };
  }

  constructor(
    protected breakpointService: BreakpointService,
    private matBottomSheet: MatBottomSheet,
    public contactsStore: ContactsStore,
    private matDialog: MatDialog,
    activatedRoute: ActivatedRoute,
    private typeDialogService: TypeDialogService
  ) {
    super();
    this.subscription.add(
      activatedRoute.queryParams.subscribe(async (params: Params) => {
        if (params.openDetailsId) {
          const contact = await this.contactsStore.get(params.openDetailsId);
          this.onOpenContactDetails(contact);
        }
      })
    );
  }

  public ngOnInit(): void {
    this.cdr.markForCheck();
    this.contactsStore.setPageSize(100);
    if (this.contactsStore.state === StoreState.INITIAL) {
      void this.contactsStore.load();
    }
  }

  public onOpenContactDetails(data: ContactDto): void {
    if (this.breakpointService.isMobile()) this.matBottomSheet.open(ContactDetailDialogComponent, { data });
    else
      this.matDialog.open(ContactDetailDialogComponent, {
        data: { model: data } as DetailDialogData,
        minWidth: "420px"
      });
  }

  public openContactEditor(contact: ContactDto): Promise<void> {
    return this.typeDialogService.openEditor(TYPE_CONTACTS, { model: contact });
  }
}
