import { CdkScrollable } from "@angular/cdk/scrolling";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from "@angular/material/dialog";
import { WidgetDto } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { WidgetRendererOptions, WidgetTreeComponent } from "@smallstack/widget-core";

export interface ComponentDialogData {
  cmsComponent: WidgetDto;
  showCloseBtn?: boolean;
  widgetRendererOptions?: WidgetRendererOptions;
}

@Component({
  selector: "smallstack-component-dialog",
  templateUrl: "./component-dialog.component.html",
  styleUrls: ["./component-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CdkScrollable, MatDialogContent, WidgetTreeComponent, MatDialogActions, I18nComponent]
})
export class ComponentDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ComponentDialogData,
    public dialogRef: MatDialogRef<ComponentDialogComponent>
  ) {
    if (!data.widgetRendererOptions) data.widgetRendererOptions = {};
    data.widgetRendererOptions.dialogRef = dialogRef;
  }

  public close(): void {
    if (this.data?.widgetRendererOptions?.editMode === true) this.dialogRef.close(this.data.cmsComponent);
    else this.dialogRef.close();
  }
}
