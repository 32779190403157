import { ChangeDetectionStrategy, Component, Inject, OnInit, computed, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatOption } from "@angular/material/core";
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatTooltip } from "@angular/material/tooltip";
import { TypeDto } from "@smallstack/axios-api-client";
import {
  LoadingElementDirective,
  StoreRegistry,
  TRANSLATION_HELPER,
  TranslationHelper
} from "@smallstack/common-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { getRelativePath } from "@smallstack/legacy-utils";
import { PageableStore } from "@smallstack/store";
import { IconComponent } from "@smallstack/theme-components";
import { SearchByField, SearchByFieldMatcher } from "@smallstack/typesystem";
import { FormFieldTitleComponent, SchemaFormBaseWidget, TypeDialogService } from "@smallstack/widget-core";
import { computedAsync } from "ngxtension/computed-async";

export interface AutocompleteTypeSearchInputData {
  label: string | InlineTranslation;
  model: any;
}

@Component({
  selector: "smallstack-autocomplete-type-search-input",
  templateUrl: "./autocomplete-type-search-input.component.html",
  styleUrls: ["./autocomplete-type-search-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormField,
    MatLabel,
    FormFieldTitleComponent,
    MatInput,
    FormsModule,
    MatAutocompleteTrigger,
    MatAutocomplete,
    MatOption,
    I18nComponent,
    MatHint,
    MatError,
    MatSuffix,
    IconComponent,
    MatTooltip,
    LoadingElementDirective
  ]
})
export class AutocompleteTypeSearchInputWidgetComponent extends SchemaFormBaseWidget implements OnInit {
  protected model = computedAsync<AutocompleteTypeSearchInputData>(async () => {
    const modelId = this.value();
    const type = this.#type();
    if (!modelId || modelId === "" || !type) return undefined;

    if (modelId === "") return undefined;
    const store = this.#store();
    if (!store) return undefined;
    const model = await store.get(modelId);
    if (model) {
      return {
        model,
        label: this.typeService.getRepresentation(type, model)
      };
    } else return undefined;
  });

  protected hasEditorComponent: boolean = false;

  #searchInput = signal<string>(undefined);

  protected filteredOptions = computedAsync<AutocompleteTypeSearchInputData[]>(async () => {
    const searchInput = this.#searchInput();

    const store = this.#store();
    if (!store) return undefined;

    const type = this.#type();
    if (!type) return undefined;

    if (searchInput && typeof searchInput === "string") {
      const models = await store.query({
        fieldSearches: searchInput
          .trim()
          .split(" ")
          .map((split) => {
            return this.#searchProperties()?.map((sp) => {
              return {
                fieldname: sp,
                value: split,
                caseSensitive: false,
                matcher: SearchByFieldMatcher.INCLUDES
              } as SearchByField;
            });
          })
          .flat(),
        logicalOperator: "or"
      });
      return models.map((model) => ({
        model,
        label: this.typeService.getRepresentation(type, model)
      }));
    } else {
      return store.load().then(() => {
        return store.value.map((model) => ({
          model,
          label: this.typeService.getRepresentation(type, model)
        }));
      });
    }
  });

  #typePath = computed(() => {
    const schema = this.schema();
    let typePath = schema?.["x-schema-form"]?.type;
    if (!typePath) {
      if (!schema?.["x-schema-form"]?.typeProperty) return undefined;
      const relativePath = getRelativePath(this.path(), schema["x-schema-form"].typeProperty);
      typePath = this.formService.getValueByPath(relativePath);
    }
    return typePath;
  });

  #type = computed<TypeDto>(() => {
    if (!this.#typePath()) return undefined;
    return this.typeService.getByPath(this.#typePath());
  });

  #store = computed<PageableStore>(() => {
    if (!this.#typePath()) return undefined;
    return this.storeRegistry.getStore(this.#typePath());
  });
  #searchProperties = computed<string[]>(() => {
    return this.#type()?.searchableFields?.map((sf) => sf.name);
  });

  constructor(
    private typeDialogService: TypeDialogService,
    private storeRegistry: StoreRegistry,
    @Inject(TRANSLATION_HELPER) private translationHelper: TranslationHelper
  ) {
    super();
  }

  protected search(input: string): void {
    this.#searchInput.set(input);
  }

  protected optionSelected(option: MatAutocompleteSelectedEvent): void {
    this.setValue(option.option.value?.model?.id);
  }

  protected displayFn(model: AutocompleteTypeSearchInputData): string | InlineTranslation {
    if (model) return this.translationHelper.translate(model.label);
  }

  protected openEditor() {
    return async (): Promise<void> => {
      await this.typeDialogService.openEditor({ typePath: this.#typePath() }).then((createdOrUpdated) => {
        this.setValue(createdOrUpdated?.id);
      });
    };
  }
}
