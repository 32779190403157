import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { I18nComponent } from "@smallstack/i18n-components";
import { FormInputContainerComponent, SchemaFormBaseWidget } from "@smallstack/widget-core";

@Component({
  selector: "smallstack-slider-form-input",
  templateUrl: "./slider-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [I18nComponent, FormInputContainerComponent, ReactiveFormsModule, FormsModule, NgClass]
})
export class SliderFormInputComponent extends SchemaFormBaseWidget {
  protected possibleValues = computed(() => {
    const schema = this.schema();
    if (!schema) return [];
    const possibleValues: Array<{ value: number; label: string }> = schema["x-schema-form"]?.values;
    if (possibleValues) return possibleValues;
    return [
      { value: 0, label: "0" },
      { value: 1, label: "1" },
      { value: 2, label: "2" },
      { value: 3, label: "3" },
      { value: 4, label: "4" },
      { value: 5, label: "5" },
      { value: 6, label: "6" },
      { value: 7, label: "7" },
      { value: 8, label: "8" },
      { value: 9, label: "9" },
      { value: 10, label: "10" }
    ];
  });

  protected max = computed(() => {
    return this.possibleValues().length;
  });

  protected currentValue = computed(() => {
    const value = this.value();
    const possibleValues = this.possibleValues();
    const index = possibleValues.findIndex((possibleValue) => possibleValue.value === value);
    return index + 1;
  });

  protected updateValue(value: number): void {
    const possibleValues = this.possibleValues();
    this.setValue(possibleValues[value - 1]?.value);
  }
}
