import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FileExplorerInputComponent, FileStore } from "@smallstack/file-components";
import { ReactiveFormBasedInputWidgetComponent } from "@smallstack/widget-core";

export const FORM_INPUT_FILE_URL = "fileurl";

@Component({
  selector: "smallstack-file-url-form-input",
  templateUrl: "./file-url-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FileExplorerInputComponent, FormsModule, AsyncPipe]
})
export class FileUrlFormInputComponent extends ReactiveFormBasedInputWidgetComponent {
  constructor(public fileStore: FileStore) {
    super();
  }
}
