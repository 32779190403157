import { NavigationEntryDto, NavigationEntryDtoTypeEnum } from "@smallstack/axios-api-client";
import {
  F_APPLICATION,
  F_CMS,
  F_EMAIL,
  F_FILE,
  F_FORM,
  F_I18N,
  F_MAILING,
  F_OPTIN,
  F_PDF_TEMPLATES,
  F_TASK,
  F_WORKFLOW,
  ServicePermissions,
  T_CONTACT,
  T_USERS
} from "@smallstack/typesystem";

import { BackofficeIcons } from "./backoffice.icons";

export const ContactsNavigationNode: NavigationEntryDto = {
  icon: BackofficeIcons.contact,
  path: T_CONTACT,
  label: [
    { locale: "de_de", value: "Kontakte" },
    { locale: "en_us", value: "Contacts" }
  ],
  type: NavigationEntryDtoTypeEnum.Item,
  requiredPermission: ServicePermissions.CONTACTS_READ
};

export const CheckinFeatures: NavigationEntryDto[] = [
  {
    label: [
      { locale: "de_de", value: "Besucher" },
      { locale: "en_us", value: "Visitors" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    path: "checkin/visitors",
    icon: BackofficeIcons.visitors
  },
  {
    label: [
      { locale: "de_de", value: "Events" },
      { locale: "en_us", value: "Events" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    path: "checkin/events",
    icon: BackofficeIcons.event
  },
  {
    label: [
      { locale: "de_de", value: "Check-In Apps" },
      { locale: "en_us", value: "Check-In Apps" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    path: "checkin/apps",
    icon: BackofficeIcons.appsymbol
  },
  {
    label: [
      { locale: "de_de", value: "Drucker" },
      { locale: "en_us", value: "Printer" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    path: "checkin/printerconfigurations",
    icon: BackofficeIcons.printerconfig
  },
  {
    icon: "form",
    path: F_FORM,
    label: [
      { locale: "de_de", value: "Formulare" },
      { locale: "en_us", value: "Forms" }
    ],
    type: NavigationEntryDtoTypeEnum.Item
  }
];

export const ContentFeatures: NavigationEntryDto[] = [
  {
    label: [
      { locale: "de_de", value: "Kalender" },
      { locale: "en_us", value: "Calendars" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    path: "calendars", // NOT F_CALENDAR !!!
    icon: BackofficeIcons.calendar
  },
  {
    icon: "application",
    path: F_APPLICATION,
    label: [
      { locale: "de_de", value: "Applikationen" },
      { locale: "en_us", value: "Applications" }
    ],
    type: NavigationEntryDtoTypeEnum.Item
  },
  {
    label: [
      { locale: "de_de", value: "CMS Seiten" },
      { locale: "en_us", value: "CMS Pages" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    path: F_CMS,
    icon: "cms",
    requiredPermission: ServicePermissions.CMS_READ
  },
  {
    icon: "optin",
    path: F_OPTIN,
    label: [
      { locale: "de_de", value: "Einverständniserklärungen" },
      { locale: "en_us", value: "Opt-Ins" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    requiredPermission: ServicePermissions.OPT_IN_WRITE
  },
  {
    label: [
      { locale: "de_de", value: "PDF Vorlagen" },
      { locale: "en_us", value: "PDF Templates" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    icon: "pdf",
    path: F_PDF_TEMPLATES,
    requiredPermission: ServicePermissions.PDF_TEMPLATE_READ
  },
  {
    label: [
      { locale: "de_de", value: "Dateien" },
      { locale: "en_us", value: "Files" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    icon: "files",
    path: F_FILE
  },
  {
    icon: "i18n",
    path: F_I18N,
    label: [
      { locale: "de_de", value: "Internationalisierung" },
      { locale: "en_us", value: "Internationalization" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    requiredPermission: ServicePermissions.INTERNATIONALIZATION_READ
  }
];

export const UserFeatures: NavigationEntryDto[] = [
  {
    label: [
      { locale: "de_de", value: "Kunden" },
      { locale: "en_us", value: "Customers" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    path: T_USERS,
    icon: BackofficeIcons.users
  },
  {
    icon: "email",
    path: F_EMAIL,
    label: [
      { locale: "de_de", value: "E-mail" },
      { locale: "en_us", value: "Email" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    requiredPermission: ServicePermissions.EMAILS_READ
  },
  {
    icon: "commercial",
    path: F_MAILING,
    label: [
      { locale: "de_de", value: "Marketing" },
      { locale: "en_us", value: "Marketing" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    requiredPermission: ServicePermissions.MAILING_LIST_READ
  }
];

export const PimFeatures: NavigationEntryDto[] = [
  {
    icon: "products",
    path: "pim/products",
    label: [
      { locale: "de_de", value: "Produkte" },
      { locale: "en_us", value: "Products" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    requiredPermission: ServicePermissions.PRODUCTS_READ
  },
  {
    label: [
      { locale: "de_de", value: "Bestellungen" },
      { locale: "en_us", value: "Orders" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    path: "pim/orders",
    icon: BackofficeIcons.orders,
    requiredPermission: ServicePermissions.ORDERS_READ
  },
  {
    label: [
      { locale: "de_de", value: "Preiszone" },
      { locale: "en_us", value: "Price Zone" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    path: "pim/pricezones",
    icon: BackofficeIcons.pricezone,
    requiredPermission: ServicePermissions.PRODUCTS_READ
  },
  {
    label: [
      { locale: "de_de", value: "Zulieferer" },
      { locale: "en_us", value: "Supplier" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    path: "pim/supplier",
    icon: BackofficeIcons.suppliers,
    requiredPermission: ServicePermissions.PRODUCTS_WRITE
  }
];

export const CrmFeatures: NavigationEntryDto[] = [ContactsNavigationNode];

export const WorkflowFeatures: NavigationEntryDto[] = [
  {
    icon: "workflow",
    path: F_WORKFLOW,
    label: [
      { locale: "de_de", value: "Workflows" },
      { locale: "en_us", value: "Workflows" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    requiredPermission: ServicePermissions.WORKFLOW_READ
  },
  {
    icon: "export",
    path: "export",
    label: [
      { locale: "en_us", value: "Data Export" },
      { locale: "de_de", value: "Daten Export" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    requiredPermission: ServicePermissions.EXPORTERS_EXECUTE
  },
  {
    label: [
      { locale: "de_de", value: "Warteschlange" },
      { locale: "en_us", value: "Task Queue" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    path: F_TASK,
    icon: BackofficeIcons.taskqueue,
    requiredPermission: ServicePermissions.TASKS_READ
  }
];

export const OtherFeatures: NavigationEntryDto[] = [
  {
    icon: "audit",
    path: "audit-log",
    label: [
      { locale: "de_de", value: "Auditprotokoll" },
      { locale: "en_us", value: "Audit Log" }
    ],
    type: NavigationEntryDtoTypeEnum.Item,
    requiredPermission: ServicePermissions.AUDIT_READ
  },
  {
    icon: "key",
    path: "service-token",
    label: [{ value: "Servicetokens" }],
    type: NavigationEntryDtoTypeEnum.Item,
    requiredPermission: ServicePermissions.TENANT_ADMIN
  },
  {
    icon: "devices",
    path: "devices",
    label: [
      { locale: "en_us", value: "External Devices" },
      { locale: "de_de", value: "Externe Geräte" }
    ],
    type: NavigationEntryDtoTypeEnum.Item
  }
];

export const ProjectNavigationFeatures: NavigationEntryDto[] = [
  ...CheckinFeatures,
  ...ContentFeatures,
  ...UserFeatures,
  ...PimFeatures,
  ...CrmFeatures,
  ...WorkflowFeatures,
  ...OtherFeatures
];

export const DefaultProjectNavigation: NavigationEntryDto[] = [
  { type: NavigationEntryDtoTypeEnum.Divider, label: [{ value: "Check-In App" }] },
  ...CheckinFeatures,
  {
    type: NavigationEntryDtoTypeEnum.Divider,
    label: [
      { locale: "de_de", value: "Inhalt" },
      { locale: "en_us", value: "Content" }
    ]
  },
  ...ContentFeatures,
  ...CrmFeatures,
  {
    type: NavigationEntryDtoTypeEnum.Divider,
    label: [
      { locale: "de_de", value: "E-Commerce" },
      { locale: "en_us", value: "E-Commerce" }
    ]
  },
  ...PimFeatures,
  ...UserFeatures,
  {
    type: NavigationEntryDtoTypeEnum.Divider,
    label: [
      { locale: "de_de", value: "Aufgaben" },
      { locale: "en_us", value: "Tasks" }
    ]
  },
  ...WorkflowFeatures,
  {
    type: NavigationEntryDtoTypeEnum.Divider,
    label: [
      { locale: "de_de", value: "Weitere Module" },
      { locale: "en_us", value: "Other Modules" }
    ]
  },
  ...OtherFeatures
];
