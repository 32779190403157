@if (options$ | async; as options) {
  <div class="form-control w-full max-w-xs" attr.data-test="form-input-widget-{{ path() }}">
    @if (options?.showLabel !== false) {
      <label class="label">
        <span class="label-text"> <smallstack-form-field-title [path]="path()"></smallstack-form-field-title></span>
      </label>
    }
    @if (store) {
      <smallstack-store-select
        [store]="store"
        [property]="propertyName"
        [multiple]="multiple"
        [showData]="true"
        (selectedIdsChange)="setValue($event)"
        (selectedIdChange)="setValue($event)"
        [selectedIds]="value$ | async"
        [selectedId]="value$ | async"
        [showCreationButton]="showCreationButton"
        class="h-full w-full min-h-full min-w-full"
        [uniqueId]="path()"
      ></smallstack-store-select>
    } @else {
      Store '{{ storeName }}' not registered!
    }
    @if (schema$ | async; as schema) {
      @if (schema.description && options?.showHint !== false && options?.useInfoBox !== true) {
        <mat-hint>{{ schema.description }}</mat-hint>
      }
      <mat-error data-test="validation-error-text" class="justify-end">{{ validationErrorText() }} </mat-error>
      @if (schema.description && options?.showHint !== false && options?.useInfoBox === true) {
        <div matSuffix>
          <smallstack-icon
            name="question-mark"
            theme="ios-glyphs"
            color="AAAAAA"
            size="16"
            [matTooltip]="schema.description"
            style="cursor: help; padding-bottom: 4px"
          ></smallstack-icon>
        </div>
      }
    }
  </div>
}
