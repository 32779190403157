import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FlattenPipe } from "@smallstack/common-components";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "GlobalsDebugger",
  templateName: "Globaler Widget Baum Speicher Debugger",
  templateDescription:
    "Dieses Widget richtet sich an Entwickler und zeigt den globalen Speicher des aktuellen Widget Baums an.",
  icon: "bug",
  tags: AllWidgetTags
})
@Component({
  templateUrl: "./globals-debugger-widget.component.html",
  styleUrls: ["./globals-debugger-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FlattenPipe]
})
export class GlobalsDebuggerWidgetComponent extends BaseWidgetComponent {}
