<div class="border-red-300">
  @if (schema()?.properties) {
    <smallstack-form-table [path]="path()" [compactMode]="true" [showHint]="true"></smallstack-form-table>
  } @else if (schema()?.additionalProperties) {
    @for (key of mapKeys(); track key) {
      <div class="font-bold text-lg">{{ key }}</div>
      <smallstack-form-table
        [path]="path() + '.' + key"
        [compactMode]="true"
        [showHint]="true"
        [schema]="mapSchema()"
      ></smallstack-form-table>
    }
  } @else {
    <span class="text-muted"
      >Fehler: Es wurden keine definierte Eigenschaft gefunden, welche als Eingabefeld angezeigt werden könnte.</span
    >
  }
  @if (path()) {
    <smallstack-form-duplicates-search [path]="path()" (valueChange)="setValue($event)">
    </smallstack-form-duplicates-search>
  }
</div>
