<h1 mat-dialog-title>
  <smallstack-i18n data="@@components.microsoftfiles.dialogtitle"></smallstack-i18n>
</h1>
<div mat-dialog-content>
  <smallstack-loader *ngIf="isLoading; else content"></smallstack-loader>
  <ng-template #content>
    <div class="flex flex-col md:flex-row gap-1 md:gap-2 w-full">
      <div class="mat-outline flex flex-row gap-1 w-full">
        <smallstack-icon alias="folder-invoices" size="24"></smallstack-icon>
        <a [href]="currentItem?.webUrl" target="_blank">{{ currentItem?.webUrl | decodeUriComponent }}</a>
      </div>
      <div class="mat-outline flex flex-row gap-1 w-auto">
        <smallstack-icon alias="search" size="24"></smallstack-icon>
        <input
          ngModel
          (ngModelChange)="search($event)"
          placeholder="Im aktuellen Ordner suchen..."
          #searchField
          style="min-width: 250px"
        />
        <smallstack-icon
          *ngIf="searchField.value"
          alias="delete"
          size="24"
          (click)="searchField.value = ''; search(undefined)"
        ></smallstack-icon>
      </div>
    </div>
    <ng-container *ngIf="isSearching; else content">
      <smallstack-loader loadingText="Verzeichnisse werden durchsucht..."></smallstack-loader>
    </ng-container>
    <ng-template #content>
      <ng-container *ngIf="searchResults; else dataTable">
        <h2>Suchergebnisse</h2>
        <table
          *ngIf="searchResults.total > 0; else noSearchResults"
          class="search-result-table h-full w-full min-h-full min-w-full"
        >
          <thead>
            <tr> <th>Name</th><th>Pfad</th><th>Aktionen</th> </tr>
          </thead>
          <tbody>
            <tr *ngFor="let searchResult of searchResults.results">
              <td>{{ searchResult.resource.name }}</td>
              <td>{{ searchResult.resource.webUrl }}</td>
              <td>
                <button [loadingFn]="sendItem(searchResult.resource.id)" class="btn btn-outline btn-secondary">
                  <smallstack-i18n data="@@actions.select"></smallstack-i18n> </button
              ></td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="searchResults.hasMore" class="text-muted"
          >Gezeigt werden 25 von {{ searchResults.total }} Suchergebnissen.</div
        >
        <ng-template #noSearchResults>Keine Ordner gefunden...</ng-template>
      </ng-container>
    </ng-template>
  </ng-template>
  <ng-template #dataTable>
    <ng-container [ngSwitch]="view">
      <ng-container *ngSwitchCase="'groups'">
        <div class="smallstack-grid">
          <div
            class="smallstack-grid-card cursor-pointer"
            *ngFor="let group of groups"
            [loadingFn]="selectGroup(group.id)"
          >
            <div class="content center-center">
              <smallstack-icon name="conference" size="48"></smallstack-icon>
              {{ group.displayName }}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'files'">
        <div class="smallstack-grid">
          <div class="smallstack-grid-card" [loadingFn]="openParentItem()">
            <div class="content center-center">
              <smallstack-icon name="left-up2" size="48"></smallstack-icon>
            </div>
            <div class="actions">
              <button class="btn">
                <smallstack-i18n data="@@components.microsoftFiles.directoryup"></smallstack-i18n>
              </button>
            </div>
          </div>
          <div class="smallstack-grid-card" *ngFor="let file of files">
            <div class="content center-center" [loadingFn]="selectItem(file)">
              <smallstack-icon name="file" *ngIf="!file.folder" size="48"></smallstack-icon>
              <smallstack-icon name="folder-invoices" *ngIf="file.folder" size="48"></smallstack-icon>
              {{ file.name }}
            </div>
            <div class="actions">
              <button *ngIf="file.folder" [loadingFn]="sendItem(file.id)" class="btn">
                <smallstack-i18n data="@@actions.select"></smallstack-i18n>
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
<div mat-dialog-actions align="end">
  <button mat-dialog-close class="btn">
    <smallstack-i18n data="@@actions.cancel"></smallstack-i18n>
  </button>
</div>
