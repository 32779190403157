import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { ContextService, LoadingElementDirective } from "@smallstack/common-components";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { NotificationService } from "@smallstack/i18n-components";
import { BaseWidgetComponent, FormComponent, TabbedSchemaFormTableComponent } from "@smallstack/widget-core";
import axios from "axios";

@Component({
  selector: "smallstack-cms-form-table-widget,smallstack-form-table-widget",
  templateUrl: "./form-table-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormComponent, TabbedSchemaFormTableComponent, LoadingElementDirective]
})
export class FormTableWidgetComponent extends BaseWidgetComponent {
  public formData: any = {};

  @ViewChild(FormComponent)
  public form: FormComponent;

  public static getConfiguration(): SchemaFormSchema {
    return {
      type: "object",
      properties: {
        schema: {
          title: "Formular Schema",
          type: "object",
          "x-schema-form": {
            widget: "monaco",
            monacoLanguage: "json"
          }
        },
        infoBox: {
          title: "Info Box für Beschreibungen verwenden",
          type: "boolean"
        },
        url: { type: "string", title: "Url an die das Formular geschickt werden soll" },
        usePersonalToken: { type: "boolean", title: "Persönlichen Authentifizierungstoken verwenden?", default: false },
        buttonText: { type: "string", title: "Label des Versenden Buttons" },
        successMessage: { type: "string", title: "Meldung nach erfolgreichem Versenden" }
      },
      required: ["schema"]
    };
  }

  constructor(
    private contextService: ContextService,
    private notificationService: NotificationService
  ) {
    super();
  }

  public sendForm() {
    return async (): Promise<void> => {
      await this.form.formService.validateAllFields();
      if (!this.form.formService.isValid()) return;

      const headers: any = {};
      if (this.data().usePersonalToken) {
        headers.Authorization = "Bearer " + this.contextService.getEvaluatedContext().token;
      }

      if (this.data().url) {
        const data = { formData: this.formData };
        await axios({
          url: this.data().url,
          data,
          headers,
          method: "post"
        })
          .then((result) => {
            if (result)
              this.notificationService.notification.success(
                "@@notifications.success.title",
                this.data().successMessage
              );
          })
          .catch((e) => {
            this.notificationService.notification.success("@@notifications.error.title", e);
          });
      }
    };
  }
}
