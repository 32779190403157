.mailing-opt-box {
  padding: 10px;
  border: 2px dashed gray;
  border-radius: 4px;
  margin: 5px 15px;
  color: #000;
}

.mailing-opt-box.success {
  border-color: rgb(32, 189, 32);
  background-color: rgb(128, 199, 128);
  color: #fff;
}

.mailing-opt-box.error {
  border-color: rgb(218, 65, 54);
  background-color: #d06c60;
  color: #fff;
}
