import { AsyncPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatLabel } from "@angular/material/form-field";
import { MatTooltip } from "@angular/material/tooltip";
import { LoadingElementDirective, TranslatePipe } from "@smallstack/common-components";
import {
  FileExplorerDialogComponent,
  FileExplorerDialogData,
  FileExplorerDialogResult,
  FilePreviewComponent,
  FileStore
} from "@smallstack/file-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { StoreModelComponent } from "@smallstack/store-components";
import { IconComponent } from "@smallstack/theme-components";
import { FormFieldTitleComponent, SchemaFormBaseWidget } from "@smallstack/widget-core";

export const FILE_FORM_INPUT = "fileIds";

@Component({
  selector: "smallstack-file-form-input",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./file-form-input.component.html",
  styleUrls: ["./file-form-input.component.scss"],
  imports: [
    NgClass,
    MatLabel,
    FormFieldTitleComponent,
    IconComponent,
    MatTooltip,
    StoreModelComponent,
    FilePreviewComponent,
    I18nComponent,
    LoadingElementDirective,
    AsyncPipe,
    TranslatePipe
  ]
})
export class FileFormInputComponent extends SchemaFormBaseWidget {
  constructor(
    private matDialog: MatDialog,
    public fileStore: FileStore
  ) {
    super();
  }

  public addFile() {
    return async (): Promise<void> => {
      let value = this.getValue();
      void this.matDialog
        .open(FileExplorerDialogComponent, {
          data: { fileStore: this.fileStore } as FileExplorerDialogData,
          maxHeight: "80vh"
        })
        .afterClosed()
        .subscribe((result: FileExplorerDialogResult) => {
          if (result) {
            if (!(value instanceof Array)) value = [];
            value.push(result.id);
            this.setValue(value);
            this.cdr.markForCheck();
          }
        });
    };
  }

  public removeFile(fileId: string): void {
    let value = this.getValue();
    if (value instanceof Array) {
      value = value.filter((id) => id !== fileId);
      this.setValue(value);
      this.cdr.markForCheck();
    }
  }
}
