import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RouterUtilService } from "@smallstack/common-components";
import { BUTTON_WIDGET_CONFIGURATION_SCHEMA_PROPERTIES } from "@smallstack/core-common";
import { replaceVariables } from "@smallstack/legacy-utils";
import { BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { ButtonComponent } from "../../button/button.component";

@Widget({
  name: "LinkButton",
  icon: "link",
  templateName: "Link Button",
  templateDescription: "Zeigt einen Button, mit welchem andere Seiten verlinkt werden können.",
  dataSchema: {
    type: "object",
    properties: {
      link: {
        title: "Link Adresse",
        description:
          "Definieren Sie hier die Adresse, zu welcher der Benutzer geleitet werden soll. Die Adresse kann entweder relativ oder absolut sein.",
        type: "string"
      },
      ...BUTTON_WIDGET_CONFIGURATION_SCHEMA_PROPERTIES
    }
  }
})
@Component({
  selector: "smallstack-link-button-widget",
  imports: [ButtonComponent],
  templateUrl: "./link-button-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkButtonWidgetComponent extends BaseWidgetComponent {
  constructor(private routerUtilService: RouterUtilService) {
    super();
  }

  protected followLink() {
    return async (): Promise<void> => {
      if (this.data().link)
        await this.routerUtilService.navigateFullUrl(replaceVariables(this.data().link, this.context()));
    };
  }
}
