import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatTooltip } from "@angular/material/tooltip";
import { filterNullish } from "@smallstack/legacy-utils";
import { IconComponent } from "@smallstack/theme-components";
import { FormFieldTitleComponent, SchemaFormBaseWidget } from "@smallstack/widget-core";

@Component({
  selector: "comma-separated-string-array-form-input",
  templateUrl: "./comma-separated-string-array-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormField,
    MatLabel,
    FormFieldTitleComponent,
    MatInput,
    FormsModule,
    MatHint,
    MatError,
    MatSuffix,
    IconComponent,
    MatTooltip,
    AsyncPipe
  ]
})
export class CommaSeparatedStringArrayFormInputComponent extends SchemaFormBaseWidget implements OnInit {
  public commaSeparatedString: string;

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.schema$.pipe(filterNullish()).subscribe((schema) => {
        if (schema.minLength) console.error(new Error("This form input does not support min length validation!"));
        if (schema.maxLength) console.error(new Error("This form input does not support max length validation!"));
      })
    );
    this.subscription.add(
      this.value$.subscribe((value: string[]) => {
        if (value !== undefined) {
          this.commaSeparatedString = value.join(", ");
          this.cdr.markForCheck();
        }
      })
    );
  }

  public async convertStringToArray(event: any): Promise<void> {
    let array = event;
    if (array !== "")
      array = array
        .split(",")
        .map((entry: string) => {
          return entry.trimStart().trimEnd();
        })
        .filter((entry: string) => {
          return entry !== "";
        });
    else array = undefined;
    this.setValue(array);
    await this.validateOnChange();
  }
}
