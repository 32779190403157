import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatTooltip } from "@angular/material/tooltip";
import { IconComponent } from "@smallstack/theme-components";
import { FormFieldTitleComponent, ReactiveFormBasedInputWidgetComponent } from "@smallstack/widget-core";

@Component({
  selector: "integer-form-input",
  templateUrl: "./integer-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormField,
    MatLabel,
    FormFieldTitleComponent,
    MatInput,
    FormsModule,
    MatHint,
    MatError,
    MatSuffix,
    IconComponent,
    MatTooltip,
    AsyncPipe
  ]
})
export class IntegerFormInputComponent extends ReactiveFormBasedInputWidgetComponent {}
