import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { I18nComponent } from "@smallstack/i18n-components";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "smallstack-imageupload-form-input",
  templateUrl: "./imageupload-form-input.component.html",
  styleUrls: ["./imageupload-form-input.component.scss", "../scss/popup-widget-container.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, I18nComponent]
})
export class ImageUploadFormInputComponent extends SchemaFormBaseWidget implements OnInit {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public error: any;
  public fileName: string;
  public file: File;
  public fileSrc: any;

  public onFileSelect(event: any): void {
    this.isLoading$.next(true);
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.fileName = this.file.name;
      this.isLoading$.next(false);
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.evaluateReaderResult(reader);
      };
      reader.onerror = (error) => {
        this.error = "Error: " + error;
        this.isLoading$.next(false);
        this.cdr.detectChanges();
        throw error;
      };
    }
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.value$.subscribe((val) => {
        this.fileSrc = val;
        this.cdr.markForCheck();
      })
    );
  }

  private evaluateReaderResult(reader: FileReader) {
    this.fileSrc = reader.result;
    this.setValue(reader.result);
    this.cdr.detectChanges();
  }
}
