import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatError, MatHint, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatTooltip } from "@angular/material/tooltip";
import { filterNullish } from "@smallstack/legacy-utils";
import { IconComponent } from "@smallstack/theme-components";
import { FormFieldTitleComponent, SchemaFormBase } from "@smallstack/widget-core";
import { TimestampEditorComponent } from "../../timestamp-editor/timestamp-editor.component";

@Component({
  selector: "smallstack-datetime-form-input",
  templateUrl: "./datetime-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatLabel,
    FormFieldTitleComponent,
    TimestampEditorComponent,
    MatHint,
    MatError,
    MatSuffix,
    IconComponent,
    MatTooltip,
    AsyncPipe
  ]
})
export class DatetimeFormInputComponent extends SchemaFormBase implements OnInit {
  public defaultsToNow: boolean;
  public onlyDateInFuture: boolean;
  public onlyDateInPast: boolean;
  public fixedTime: "startOfDay" | "endOfDay";

  public ngOnInit(): void {
    this.subscription.add(
      this.schema$.pipe(filterNullish()).subscribe((schema) => {
        this.defaultsToNow = schema["x-schema-form"].defaultsToNow;
        this.onlyDateInFuture = schema["x-schema-form"].onlyDateInFuture;
        this.onlyDateInPast = schema["x-schema-form"].onlyDateInPast;
        this.fixedTime = schema["x-schema-form"].fixedTime;
        this.formService.formGroup.addControl(this.path(), new UntypedFormControl());
        this.cdr.markForCheck();
      })
    );
  }
}
