import { AsyncPipe, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { ShoppingCartDto } from "@smallstack/axios-api-client";
import { BasePathService } from "@smallstack/common-components";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { IconComponent } from "@smallstack/theme-components";
import { BaseWidgetComponent } from "@smallstack/widget-core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ShoppingCartStore } from "../../../stores/shopping-cart.store";

@Component({
  selector: "smallstack-cart-icon-widget",
  templateUrl: "./cart-icon-widget.component.html",
  styleUrls: ["./cart-icon-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, NgStyle, AsyncPipe]
})
export class CartIconWidgetComponent extends BaseWidgetComponent {
  public badge: Observable<number> = this.shoppingCartStore.value$.pipe(
    map((shoppingCart: ShoppingCartDto) => {
      if (
        shoppingCart === undefined ||
        !(shoppingCart.orderPositions instanceof Array) ||
        shoppingCart.orderPositions.length === 0
      )
        return 0;
      return shoppingCart.orderPositions
        .map((shoppingCartPosition) => shoppingCartPosition.amount)
        .reduce((prev: number, current: number) => (prev += current));
    })
  );

  public static getConfiguration(): SchemaFormSchema {
    return {
      type: "object",
      properties: {
        icon: {
          type: "string",
          default: "shopping-cart",
          "x-schema-form": {
            widget: "icon"
          }
        },
        color: {
          type: "string",
          default: "FFFFFF",
          "x-schema-form": {
            widget: "color"
          }
        },
        theme: {
          title: "Theme",
          description: "Das Icon Theme, z.B. 'fluent' oder 'ios'",
          type: "string",
          default: "fluent",
          enum: ["fluent", "ios", "color", "material-outlined", "dusk", "dotted", "plasticine"]
        },
        size: {
          title: "Größe",
          description: "Die Größe des Icons",
          type: "string",
          default: "24",
          enum: ["10", "12", "16", "20", "24", "32", "48", "64", "72", "96"]
        },
        link: {
          type: "string",
          description: "Setzen Sie hier eine Route, welche beim Klicken des Icons aufgerufen wird."
        }
      }
    };
  }

  constructor(
    private basePathService: BasePathService,
    private router: Router,
    public shoppingCartStore: ShoppingCartStore
  ) {
    super();
  }

  public route(): Promise<boolean> {
    if (this.data()?.link) return this.router.navigateByUrl(this.basePathService.addBasePath(this.data()?.link));
  }
}
