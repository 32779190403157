@if (showAsModal) {
  @if (options()) {
    <div style="padding-right: 20px" class="flex flex-row space-x-3 items-center h-full w-full min-h-full min-w-full">
      @if (options()?.showLabel !== false) {
        <mat-label>
          <smallstack-i18n [data]="label"></smallstack-i18n>
        </mat-label>
      }
      <div class="inline">
        <pre>{{ monacoData() }}</pre>
        <div class="excerpt" (click)="openAsModal()"></div>
      </div>
      <smallstack-icon name="source-code" (click)="openAsModal()" size="24" style="cursor: pointer"></smallstack-icon>
    </div>
  }
} @else {
  <ng-container [ngTemplateOutlet]="editor"></ng-container>
}

<ng-template #editor>
  <smallstack-monaco-editor
    [ngClass]="{ 'modal-container': openedModal, inline: !openedModal }"
    [value]="monacoData()"
    [language]="monacoLanguage()"
    [editorHeight]="monacoEditorHeight()"
    attr.data-test="schema-form-widget-monaco-{{ path() }}"
    (valueChange)="monacoDataChanged($event)"
  ></smallstack-monaco-editor>

  @if (error) {
    <mat-error>{{ error }}</mat-error>
  }
  @if (openedModal) {
    <div matDialogActions class="justify-end">
      <button (click)="closeModal()" class="btn btn-secondary">
        <smallstack-i18n data="@@actions.close"></smallstack-i18n>
      </button>
    </div>
  }
</ng-template>
