/* eslint-disable max-lines-per-function */
import { inject, provideAppInitializer } from "@angular/core";
import { StoreRegistry } from "@smallstack/common-components";
import { TYPE_COUNTERS } from "@smallstack/typesystem";
import { AllWidgetTags, WidgetRegistry, WidgetTag } from "@smallstack/widget-core";
import {
  CompareValueContainerWidgetComponent,
  CompoundWidgetWidgetComponent,
  ContactsListWidgetComponent,
  ContainerWidgetComponent,
  CountWidgetComponent,
  CreateDataWidgetComponent,
  CurrentUserWidgetComponent,
  CustomerProfileWidgetComponent,
  DashboardGridWidgetComponent,
  DataProducerWidgetComponent,
  DataWidgetComponent,
  DefinedContainerWidgetComponent,
  DialogActionButtonWidgetComponent,
  DoubleOptConfirmationWidgetComponent,
  EventsWidgetComponent,
  ExternalCountWidgetComponent,
  FlexboxContainerWidgetComponent,
  FloatingActionButtonWidgetComponent,
  FormTableWidgetComponent,
  GenericListWidgetComponent,
  GridListViewWidgetComponent,
  GridListViewWidgetV2Component,
  GridListViewWidgetV3Component,
  GridViewWidgetComponent,
  HorizontalContainerWidgetComponent,
  HtmlWidgetComponent,
  IconTabsWidgetComponent,
  KeyValueTableWidgetComponent,
  LastNewUserRegistrationsWidgetComponent,
  LatestOrdersWidgetComponent,
  LinkWidgetComponent,
  LoginStatusWidgetComponent,
  MarkdownWidgetComponent,
  MyCalendarEventsComponent,
  NavigationButtonWidgetComponent,
  PageTitleWidgetComponent,
  PromotionListComponent,
  RandomChuckNorrisJokeWidgetComponent,
  RedirectWidgetComponent,
  RouterOutletWidgetComponent,
  ShrinkingHeaderWidgetComponent,
  SliderWidgetComponent,
  StorePropertyWidgetComponent,
  StoreSearchWidgetComponent,
  TextInputWidgetComponent,
  TextSearchWidgetComponent,
  TimelineWidgetComponent,
  VerticalContainerWidgetComponent,
  VideoPlayerWidgetComponent,
  WebComponentWidgetComponent
} from "./components";
import { DeleteEntityBtnWidgetComponent } from "./components/widgets/delete-entity-btn-widget/delete-entity-btn-widget.component";
import { DndBoardWidget } from "./components/widgets/dnd-board-widget";
import { PeriodOfTimeInputWidget } from "./components/widgets/duration-input-widget";
import { FilterBarWidget } from "./components/widgets/filter-bar-widget";
import { GlobalStoreSaveButtonWidgetComponent } from "./components/widgets/global-store-save-button-widget/global-store-save-button-widget.component";
import { GlobalsDebuggerWidgetComponent } from "./components/widgets/globals-debugger-widget/globals-debugger-widget.component";
import { GoogleMapsListWidget } from "./components/widgets/google-maps-list-widget";
import { IFrameWidgetComponent } from "./components/widgets/iframe-widget/iframe-widget.component";
import { LinkButtonWidgetComponent } from "./components/widgets/link-button-widget/link-button-widget.component";
import { MaxWidthContainerWidgetComponent } from "./components/widgets/max-width-container-widget/max-width-container-widget.component";
import { Microsoft365FilesWidget } from "./components/widgets/microsoft365-files-widget";
import { PdfExporterWidget } from "./components/widgets/pdf-exporter-widget";
import { PercentageBarChartWidgetComponent } from "./components/widgets/percentage-bar-chart-widget/percentage-bar-chart-widget.component";
import { PrintButtonWidget } from "./components/widgets/print-button-widget";
import { ResourcePlanningUserConfigurationComponent } from "./components/widgets/resource-planning-user-configuration/resource-planning-user-configuration.component";
import { ResourcePlanningUserStatsComponent } from "./components/widgets/resource-planning-user-stats/resource-planning-user-stats.component";
import { ResourcePlanningUserTableWidget } from "./components/widgets/resource-planning-user-table";
import { StatsWidgetComponent } from "./components/widgets/stats-widget/stats-widget.component";
import { TextWidget } from "./components/widgets/text-widget";
import { CounterStore } from "./store/counter.store";

export const CMS_MODULE_INIT = provideAppInitializer(() => {
  // add some stores
  inject(StoreRegistry).registerStore(TYPE_COUNTERS, inject(CounterStore));

  const widgetRegistry = inject(WidgetRegistry);

  // add widgets
  widgetRegistry.registerWidget(MyCalendarEventsComponent);
  widgetRegistry.registerWidget(IFrameWidgetComponent);
  widgetRegistry.registerWidget(PageTitleWidgetComponent);
  widgetRegistry.registerWidget(ContainerWidgetComponent);
  widgetRegistry.registerWidget(GridListViewWidgetComponent);
  widgetRegistry.registerWidget(GridListViewWidgetV2Component);
  widgetRegistry.registerWidget(CurrentUserWidgetComponent);
  widgetRegistry.registerWidget(DashboardGridWidgetComponent);
  widgetRegistry.registerWidget(LinkWidgetComponent);
  widgetRegistry.registerWidget(DialogActionButtonWidgetComponent);
  widgetRegistry.registerWidget(RandomChuckNorrisJokeWidgetComponent);
  widgetRegistry.registerWidget(TimelineWidgetComponent);
  widgetRegistry.registerWidget(TextSearchWidgetComponent);
  widgetRegistry.registerWidget(FloatingActionButtonWidgetComponent);
  widgetRegistry.registerWidget(CompoundWidgetWidgetComponent);
  widgetRegistry.registerWidget(IconTabsWidgetComponent);
  widgetRegistry.registerWidget(DataWidgetComponent);
  widgetRegistry.registerWidget(GridListViewWidgetV3Component);
  widgetRegistry.registerWidget(DataProducerWidgetComponent);
  widgetRegistry.registerWidget(GridViewWidgetComponent);
  widgetRegistry.registerWidget(CompareValueContainerWidgetComponent);
  widgetRegistry.registerWidget(KeyValueTableWidgetComponent);
  widgetRegistry.registerWidget(NavigationButtonWidgetComponent);
  widgetRegistry.registerWidget(ShrinkingHeaderWidgetComponent);
  widgetRegistry.registerWidget(DeleteEntityBtnWidgetComponent);
  widgetRegistry.registerWidget(StatsWidgetComponent);
  widgetRegistry.registerWidget(LinkButtonWidgetComponent);
  widgetRegistry.registerWidget(GlobalsDebuggerWidgetComponent);
  widgetRegistry.registerWidget(GlobalStoreSaveButtonWidgetComponent);
  widgetRegistry.registerWidget(ResourcePlanningUserStatsComponent);
  widgetRegistry.registerWidget(ResourcePlanningUserConfigurationComponent);
  widgetRegistry.registerWidget(PercentageBarChartWidgetComponent);
  widgetRegistry.registerWidget(RedirectWidgetComponent);
  widgetRegistry.registerWidget(MaxWidthContainerWidgetComponent);
  widgetRegistry.registerWidget(RouterOutletWidgetComponent);
  widgetRegistry.addWidget(PdfExporterWidget);
  widgetRegistry.addWidget(TextWidget);
  widgetRegistry.addWidget(ResourcePlanningUserTableWidget);
  widgetRegistry.addWidget(PeriodOfTimeInputWidget);
  widgetRegistry.addWidget(PrintButtonWidget);
  widgetRegistry.addWidget(DndBoardWidget);
  widgetRegistry.addWidget(FilterBarWidget);
  widgetRegistry.addWidget(Microsoft365FilesWidget);
  widgetRegistry.addWidget(GoogleMapsListWidget);

  widgetRegistry.addDeprecatedWidget({
    name: "ExternalCount",
    templateName: "Externe Zahlenstatistik",
    icon: "area-chart",
    templateDescription: "Zeigt Zahlen von externen Statistiken an.",
    data: { widgetIcon: "area-chart", widgetTitle: [{ value: "Statistik" }] },
    component: ExternalCountWidgetComponent,
    configuration: ExternalCountWidgetComponent.configuration,
    tags: AllWidgetTags
  });

  widgetRegistry.addDeprecatedWidget({
    name: "Count",
    templateName: "Zahlenstatistik",
    icon: "statistics",
    templateDescription: "Zeigt Zahlenstatistiken an, beispielsweise die Anzahl der Kunden oder offenen Bestellungen.",
    data: { widgetIcon: "statistics", widgetTitle: [{ value: "Statistik" }] },
    component: CountWidgetComponent,
    configuration: CountWidgetComponent.getConfiguration(),
    tags: AllWidgetTags
  });

  widgetRegistry.addDeprecatedWidget({
    name: "Events",
    templateName: "Termine eines Kalenders",
    icon: "event",
    templateDescription: "Zeigt die heutigen Termine eines beliebigen Kalenders an.",
    data: { widgetIcon: "event", widgetTitle: [{ value: "Kalender-Termine" }] },
    component: EventsWidgetComponent,
    configuration: EventsWidgetComponent.getConfiguration(),
    tags: [WidgetTag.CMS, WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.DASHBOARD]
  });

  widgetRegistry.addDeprecatedWidget({ name: "LegacyMarkdown", component: MarkdownWidgetComponent });

  widgetRegistry.addDeprecatedWidget({
    name: "Markdown",
    templateName: "Markdown Text",
    icon: "markdown",
    templateDescription: "Schreiben Sie Texte im plattform-unabhängigen Markdown Stil.",
    data: [],
    component: MarkdownWidgetComponent,
    tags: [WidgetTag.CMS, WidgetTag.CBO, WidgetTag.APPLICATION]
  });

  widgetRegistry.addDeprecatedWidget({
    name: "LastNewUserRegistrations",
    templateName: "Neueste Registrierungen",
    icon: "user-group-man-man",
    templateDescription: "Zeigt die letzten Neuregistrierungen der Benutzer an.",
    data: { widgetIcon: "user", widgetTitle: [{ value: "Neueste Registrierungen" }] },
    component: LastNewUserRegistrationsWidgetComponent,
    configuration: LastNewUserRegistrationsWidgetComponent.getConfiguration(),
    tags: [WidgetTag.DASHBOARD]
  });

  widgetRegistry.addDeprecatedWidget({
    name: "FlexboxContainer",
    templateName: "Flexbox Container",
    icon: "select-row",
    templateDescription: "Ein Flexbox basierter Container für weitere Widgets.",
    component: FlexboxContainerWidgetComponent,
    configuration: FlexboxContainerWidgetComponent.getConfiguration(),
    tags: [...AllWidgetTags, WidgetTag.CONTAINER]
  });

  widgetRegistry.addDeprecatedWidget({
    name: "HorizontalContainer",
    templateName: "Horizontaler Container",
    icon: "row",
    templateDescription: "Ein Container für weitere Widgets, welche horizontal angeordnet werden.",
    component: HorizontalContainerWidgetComponent,
    configuration: HorizontalContainerWidgetComponent.getConfiguration(),
    tags: [...AllWidgetTags, WidgetTag.CONTAINER]
  });

  widgetRegistry.addDeprecatedWidget({
    name: "VerticalContainer",
    templateName: "Vertikaler Container",
    icon: "column",
    templateDescription: "Ein Container für weitere Widgets, welche vertikal angeordnet werden.",
    component: VerticalContainerWidgetComponent,
    configuration: VerticalContainerWidgetComponent.getConfiguration(),
    tags: [...AllWidgetTags, WidgetTag.CONTAINER]
  });

  widgetRegistry.addDeprecatedWidget({
    name: "WebComponent",
    templateName: "Web Component",
    icon: "web-design",
    templateDescription: "Zeigt eine Web Component an.",
    component: WebComponentWidgetComponent,
    configuration: WebComponentWidgetComponent.getConfiguration(),
    tags: AllWidgetTags
  });

  widgetRegistry.addDeprecatedWidget({
    name: "StoreSearch",
    templateName: "Store Search",
    icon: "search",
    templateDescription: "Zeigt eine Store Search Component an.",
    component: StoreSearchWidgetComponent,
    configuration: StoreSearchWidgetComponent.getConfiguration(),
    tags: [WidgetTag.CMS, WidgetTag.APPLICATION, WidgetTag.CBO],
    sockets: StoreSearchWidgetComponent.sockets
  });

  widgetRegistry.addDeprecatedWidget({
    name: "Html",
    templateName: "HTML Komponente",
    icon: "html-5--v1",
    templateDescription:
      "Eine Komponente, die HTML rendert. Kann zusammen mit einem Store Model dynamisch gestaltet werden.",
    component: HtmlWidgetComponent,
    configuration: HtmlWidgetComponent.getConfiguration(),
    tags: AllWidgetTags
  });

  widgetRegistry.addDeprecatedWidget({
    name: "LoginStatus",
    templateName: "Login Status",
    icon: "login-rounded-down",
    templateDescription:
      "Zeigt den Anmeldestatus an und bietet Benutzern die Möglichkeit, sich anzumelden oder einen neuen Account zu registrieren.",
    component: LoginStatusWidgetComponent,
    configuration: LoginStatusWidgetComponent.getConfiguration(),
    tags: [WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.CMS, WidgetTag.APPLICATION, WidgetTag.CMS]
  });

  widgetRegistry.addDeprecatedWidget({
    name: "Slider",
    templateName: "Slider",
    icon: "image-gallery",
    templateDescription: "Zeigt Bilder in vorgegebenen Intervallen nacheinander an.",
    component: SliderWidgetComponent,
    configuration: SliderWidgetComponent.getConfiguration(),
    tags: AllWidgetTags
  });

  widgetRegistry.addDeprecatedWidget({
    name: "PromotionList",
    templateName: "Promotion Liste",
    icon: "commercial",
    templateDescription: "Preisen Sie Produkte oder Features in einer Liste an.",
    component: PromotionListComponent,
    configuration: PromotionListComponent.getConfiguration(),
    tags: AllWidgetTags
  });

  widgetRegistry.addDeprecatedWidget({
    name: "GenericList",
    templateName: "Generische Liste",
    icon: "list",
    templateDescription: "Eine konfigurierbare Liste",
    component: GenericListWidgetComponent,
    configuration: GenericListWidgetComponent.getConfiguration(),
    tags: AllWidgetTags
  });
  widgetRegistry.addDeprecatedWidget({
    name: "FormTable",
    templateName: "Formular als Tabelle",
    icon: "text-input-form",
    templateDescription: "Formular wird tabellarisch angezeigt",
    component: FormTableWidgetComponent,
    configuration: FormTableWidgetComponent.getConfiguration(),
    tags: AllWidgetTags
  });
  widgetRegistry.addDeprecatedWidget({
    name: "VideoPlayer",
    templateName: "Video Player",
    icon: "video",
    templateDescription: "Zeige ein Video an",
    component: VideoPlayerWidgetComponent,
    configuration: VideoPlayerWidgetComponent.getConfiguration(),
    tags: AllWidgetTags
  });
  widgetRegistry.addDeprecatedWidget({
    name: "CustomerProfile",
    templateName: "Kunden-Profilseite",
    icon: "client-company",
    templateDescription: "Zeigt eine Kunden-Profilseite an, bei welcher die Daten editiert werden können.",
    component: CustomerProfileWidgetComponent,
    tags: [WidgetTag.APPLICATION, WidgetTag.CMS]
  });
  widgetRegistry.addDeprecatedWidget({
    name: "LatestOrders",
    templateName: "Letzte Bestellungen",
    icon: "purchase-order",
    templateDescription: "Zeigt die letzten Bestellungen in einer Liste an.",
    component: LatestOrdersWidgetComponent,
    configuration: LatestOrdersWidgetComponent.getConfiguration(),
    tags: [WidgetTag.DASHBOARD]
  });
  widgetRegistry.addDeprecatedWidget({
    name: "StoreProperty",
    templateName: "Store Property",
    icon: "web-design",
    templateDescription: "Zeigt eine Store Property an.",
    component: StorePropertyWidgetComponent,
    configuration: StorePropertyWidgetComponent.getConfiguration(),
    tags: AllWidgetTags
  });
  widgetRegistry.addDeprecatedWidget({
    name: "CreateData",
    templateName: "Daten erstellen",
    icon: "add",
    templateDescription: "Erstelle Daten für Datensätze",
    component: CreateDataWidgetComponent,
    configuration: CreateDataWidgetComponent.getConfiguration(),
    tags: [WidgetTag.APPLICATION, WidgetTag.CMS, WidgetTag.CBO]
  });
  widgetRegistry.addDeprecatedWidget({
    name: "ContactsList",
    templateName: "Liste aller Kontakte",
    icon: "contacts",
    templateDescription: "Zeigt eine durchsuchbare Liste der Kontakte an.",
    component: ContactsListWidgetComponent,
    configuration: ContactsListWidgetComponent.getConfiguration(),
    tags: AllWidgetTags
  });
  widgetRegistry.addDeprecatedWidget({
    name: "DoubleOptConfirmation",
    templateName: "Opt-in / Opt-out KundenBestätigung",
    icon: "checked-checkbox",
    templateDescription: "Ein unsichtbares Widget, welches ggf. Opt-In bzw. Opt-Out Tokens von Kunden bestätigen kann",
    component: DoubleOptConfirmationWidgetComponent,
    configuration: DoubleOptConfirmationWidgetComponent.getConfiguration(),
    tags: AllWidgetTags
  });
  widgetRegistry.addDeprecatedWidget({
    name: "TextInput",
    templateName: "Text Eingabe",
    icon: "rename",
    templateDescription: "Ein Text Feld, welches den Inhalt an andere Widgets senden kann",
    component: TextInputWidgetComponent,
    configuration: TextInputWidgetComponent.getConfiguration(),
    tags: AllWidgetTags,

    sockets: TextInputWidgetComponent.sockets
  });

  widgetRegistry.addDeprecatedWidget({
    name: "DefinedContainer",
    templateName: "Objekt existiert ja/nein Container",
    icon: "question-mark",
    templateDescription:
      "Ein Widget, welches zwei unterschiedliche Container anzeigt, je nachdem ob das angegebene Objekt definiert ist oder nicht.",
    component: DefinedContainerWidgetComponent,
    configuration: DefinedContainerWidgetComponent.getConfiguration(),
    tags: AllWidgetTags
  });
});
