import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { ReplaceVariablesContext, replaceVariablesInObject } from "@smallstack/legacy-utils";
import { MarkdownEditorComponent, MarkdownViewerComponent } from "@smallstack/text-components";
import { BaseWidgetComponent } from "@smallstack/widget-core";

@Component({
  selector: "smallstack-markdown-widget",
  templateUrl: "./markdown-widget.component.html",
  styleUrls: ["./markdown-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MarkdownEditorComponent, MarkdownViewerComponent]
})
export class MarkdownWidgetComponent extends BaseWidgetComponent {
  public replacedData = computed<string>(() =>
    replaceVariablesInObject(this.data(), this.context() as ReplaceVariablesContext, {
      removeUnmappedContent: true
    })
  );

  public translationChange(data: unknown): void {
    this.dataChange.emit(data);
  }
}
