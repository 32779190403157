import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PdfViewerComponent } from "@smallstack/file-components";
import { I18nComponent } from "@smallstack/i18n-components";

@Component({
  selector: "link-view-dialog",
  templateUrl: "./link-view-dialog.component.html",
  styleUrls: ["./link-view-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [I18nComponent, PdfViewerComponent]
})
export class LinkViewDialogComponent implements OnInit {
  public title: string;
  public error: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<LinkViewDialogComponent>
  ) {
    this.title = data.title;
  }

  public accept() {
    this.dialog.close({ accepted: true });
  }

  public reject() {
    this.dialog.close({ accepted: false });
  }

  public ngOnInit(): void {
    this.dialog.backdropClick().subscribe(() => this.dialog.close({ accepted: false }));
  }

  public handleError(error: any) {
    this.error = error;
  }
}
