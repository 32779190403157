import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { WIDGET_FORM_INPUT_HTML_SIZING } from "@smallstack/typesystem";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import { replaceVariables } from "@smallstack/legacy-utils";
import { BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { combineLatest, map } from "rxjs";

@Widget({
  name: "PercentageBarChart",
  templateName: "Prozent Anzeige",
  templateDescription: "Zeigt einen Balken mit einer oder mehreren Prozentangaben an.",
  icon: "bar-chart",
  dataSchema: {
    type: "object",
    properties: {
      content: {
        title: "Angezeigter Text",
        type: "string",
        description: "Dieser Text wird auf dem Balken angezeigt"
      },
      width: {
        title: "Breite",
        type: "string",
        description: "Breite des Balkens",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
        }
      },
      borderColor: {
        title: "Rahmenfarbe",
        type: "string",
        description: "Farbe des Balken Rahmens",
        "x-schema-form": {
          widget: "color"
        }
      },
      values: {
        title: "Werte",
        type: "array",
        items: {
          type: "object",
          properties: {
            label: {
              title: "Beschriftung",
              type: "string"
            },
            value: {
              title: "Wert",
              type: "string"
            },
            color: {
              title: "Farbe",
              type: "string",
              "x-schema-form": {
                widget: "color"
              }
            } as SchemaFormPropertyOptions
          }
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-percentage-bar-chart-widget",
  imports: [CommonModule],
  templateUrl: "./percentage-bar-chart-widget.component.html",
  styleUrls: ["./percentage-bar-chart-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PercentageBarChartWidgetComponent extends BaseWidgetComponent {
  protected values$ = combineLatest([this.context$, this.data$]).pipe(
    map(([context, data]) => {
      const values = data.values;
      if (!values) return [];
      const extractedValues: Array<{ label: string; color: string; value: number }> = values
        .map((entry: { label: string; color: string; value: string }) => {
          const label = replaceVariables(entry.label, context, true);
          const value = replaceVariables(entry.value, context, true);
          const color = replaceVariables(entry.color, context, true) || "#CCCCCC";
          if (!label || !value) return undefined;
          return {
            label,
            value: value ? parseFloat(value) : 0,
            color
          };
        })
        .filter((entry: any) => !!entry);
      const biggestValue = extractedValues.map((val) => val.value).reduce((a, b) => Math.max(a, b), 0);
      let index = 0;
      return extractedValues
        .sort((a, b) => b.value - a.value)
        .map((entry) => {
          return {
            ...entry,
            index: index++,
            odd: index % 2 === 1,
            percentage: (entry.value / biggestValue) * 100
          };
        });
    })
  );
  protected content$ = combineLatest([this.context$, this.data$]).pipe(
    map(([context, data]) => {
      return replaceVariables(data.content, context, true);
    })
  );
}
