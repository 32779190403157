import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { SocketDto, SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { StoreRegistry } from "@smallstack/common-components";
import { SchemaFormPropertyOptions } from "@smallstack/form-shared";
import { BooleanEquationOperator } from "@smallstack/legacy-utils";
import { PageableStore } from "@smallstack/store";
import {
  FilterDataType,
  FilterName,
  FilterValues,
  StoreSearchComponent,
  isNonEmptyStoreSearch
} from "@smallstack/store-components";
import { SearchQuery, TYPE_CMS_PAGES } from "@smallstack/typesystem";
import { BaseWidgetComponent, SocketAwareWidget, WidgetConfigurationSchema } from "@smallstack/widget-core";

const enum SocketNames {
  SEARCH_QUERY = "searchQuery",
  ADVANCED_QUERY_IN = "advancedQuery_in"
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "store-search-widget",
  templateUrl: "./store-search-widget.component.html",
  imports: [StoreSearchComponent]
})
export class StoreSearchWidgetComponent extends BaseWidgetComponent implements SocketAwareWidget {
  public static sockets: SocketDto[] = [
    { direction: SocketDtoDirectionEnum.In, name: SocketNames.ADVANCED_QUERY_IN, type: SocketDtoTypeEnum.Object },
    { direction: SocketDtoDirectionEnum.Out, name: SocketNames.SEARCH_QUERY, type: SocketDtoTypeEnum.Object }
  ];

  public store: PageableStore;
  public filterNames: FilterName[];
  public filterValues: FilterValues;

  @ViewChild(StoreSearchComponent)
  private storeSearchComponent: StoreSearchComponent;

  // eslint-disable-next-line max-lines-per-function
  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        operationMode: {
          type: "string",
          enum: ["store", "sendQuery"],
          default: "store",
          title: "Modus",
          description:
            "Entweder kann dieses Widget eine Suche an einen Store weitergeben oder die Suche an ein anderes Widget senden"
        },
        initialMode: {
          type: "string",
          enum: ["simple", "advanced"],
          default: "simple",
          title: "Suchart",
          description: "Welche Art von Suche soll initial angezeigt werden."
        },
        advancedMode: {
          type: "boolean",
          title: "Suchart veränderbar",
          default: true
        },
        isAllowedToLoadOnInput: {
          type: "boolean",
          title: "Suche direkt nach Eingabe auslösen",
          description:
            "Soll die Suche direkt nach Eingabe ausgelöst werden oder erst, nachdem man auf das Lupen Symbol geklickt hat?",
          default: false
        },
        fixedAdvancedSearchLogicalOperator: {
          type: "string",
          enum: ["none", "and", "or"],
          default: "none",
          title: "Fixer logischer Operator",
          description: "Welcher logischer Operator soll bei der Advanced Search zwingend verwendet werden."
        },
        showLogicalOperatorSwitcher: {
          type: "boolean",
          default: true,
          title: "Logische Operatoren im Advanced Mode anzeigen",
          description: "Falls kein fixer Operator ausgewählt wurde, kann der Benutzer von 'or' auf 'and' umschalten."
        },
        filterNames: {
          type: "array",
          title: "Filter Konfiguration",
          items: {
            type: "object",
            properties: {
              value: {
                title: "Eigenschaft",
                type: "string",
                default: "value"
              },
              label: {
                title: "Name des Filters",
                type: "string",
                default: "Label"
              },
              dataType: {
                type: "string",
                title: "Datentyp der Filterwerte",
                enum: Object.values(FilterDataType)
              },
              storeName: {
                type: "string",
                title: "Store für Werte",
                "x-schema-form": {
                  widget: "types",
                  rules: [
                    {
                      action: "hide",
                      if: {
                        dataPath: "../dataType",
                        operator: BooleanEquationOperator.NOT_EQUALS,
                        value: "foreignId"
                      }
                    }
                  ]
                }
              } as SchemaFormPropertyOptions,
              storeDisplayProperty: {
                type: "string",
                title: "Store Display Property",
                "x-schema-form": {
                  rules: [
                    {
                      action: "hide",
                      if: {
                        dataPath: "../dataType",
                        operator: BooleanEquationOperator.NOT_EQUALS,
                        value: "foreignId"
                      }
                    }
                  ]
                }
              } as SchemaFormPropertyOptions
            }
          }
        },
        store: {
          type: "string",
          "x-schema-form": {
            widget: "types",
            rules: [
              {
                action: "hide",
                if: { dataPath: "../operationMode", value: "store", operator: BooleanEquationOperator.NOT_EQUALS }
              }
            ]
          },
          title: "Store",
          default: TYPE_CMS_PAGES
        } as SchemaFormPropertyOptions
      }
    };
  }

  constructor(private storeRegistry: StoreRegistry) {
    super();
    this.subscription.add(this.data$.subscribe((data) => this.setData(data)));
  }

  public setData(data: { filterNames: any; operationMode: "store" | "sendQuery" }): void {
    // evaluate filternames
    if (data?.filterNames) {
      this.filterNames = data.filterNames.map((filterName: { value: string; label: string }) => ({
        value: filterName.value,
        label: filterName.label
      }));
      this.filterValues = {};
      data.filterNames.forEach(
        (filterValue: {
          value: string;
          allowCustomValues: boolean;
          dataType: string;
          storeName: string;
          storeDisplayProperty: string;
        }) => {
          this.filterValues[filterValue.value] = {
            config: {
              allowCustomValues: filterValue.allowCustomValues,
              dataType:
                filterValue.dataType === FilterDataType.FOREIGN_ID
                  ? FilterDataType.ID
                  : (filterValue.dataType as FilterDataType)
            }
          };
          if (filterValue.dataType === FilterDataType.FOREIGN_ID)
            this.filterValues[filterValue.value].storeValues = {
              storeName: filterValue.storeName,
              storeDisplayProperty: filterValue.storeDisplayProperty
            };
        }
      );
    }

    // evaluate mode
    if (!data.operationMode || data.operationMode === "store")
      if (this.data()?.store) this.store = this.storeRegistry.getStore(this.data()?.store);
  }

  public sendSearchQuery(searchQuery: SearchQuery): void {
    void this.sendSocketData(SocketNames.SEARCH_QUERY, searchQuery);
  }

  public async handleSocketData(socketName: string, data?: any): Promise<void> {
    switch (socketName) {
      case SocketNames.ADVANCED_QUERY_IN: {
        if (isNonEmptyStoreSearch(data)) {
          this.storeSearchComponent.searchQuery = data;
          this.storeSearchComponent.mode = "advanced";
          await this.storeSearchComponent.load();
        } else throw new Error("Unusable search query:" + JSON.stringify(data));
        break;
      }
      default:
        throw new Error("Unknown input socket: " + socketName);
    }
  }
}
