import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from "@angular/core";
import { DEFAULT_GERMAN_DATE_STRING_FORMAT, DEFAULT_HTML5_DATE_TIME_FORMAT } from "@smallstack/legacy-utils";
import { format, parseISO } from "date-fns";
import { Subscription } from "rxjs";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "smallstack-timestamp-editor",
  templateUrl: "./timestamp-editor.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule]
})
export class TimestampEditorComponent implements OnChanges, OnDestroy {
  @Input()
  public showSaveButton: boolean = true;

  /** the real stored value */
  @Input()
  public timestamp: number;

  @Output()
  public readonly timestampChange = new EventEmitter<number>();

  @Input()
  public showTimezone = false;

  @Input()
  public defaultsToNow: boolean;

  @Input()
  public onlyDateInFuture: boolean;

  @Input()
  public onlyDateInPast: boolean;

  @Input()
  public fixedTime: "startOfDay" | "endOfDay";

  public showTimezoneChanger = false;

  /** the date being used for the input field */
  public inputDate: string;

  public timezones: number[] = Array.from({ length: 25 }, (_, i) => i - 12);

  public maxDate: string;
  public minDate: string;

  private subscription: Subscription = new Subscription();

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnChanges(): void {
    this.setup();
  }

  public save(): void {
    this.timestampChange.emit(this.timestamp);
  }

  public dateChanged(changed: Event): void {
    this.timestamp = parseISO((<HTMLInputElement>changed.target).value).valueOf();
    if (this.showSaveButton === false) this.save();
  }

  public deleteDate(): void {
    this.inputDate = undefined;
    if (this.showSaveButton === false) this.save();
  }

  private setup(): void {
    // defaults to now
    if (this.defaultsToNow && !this.timestamp) {
      this.timestamp = Date.now();
      if (this.showSaveButton === false) this.save();
    }

    // fixed time
    if (this.timestamp) {
      if (this.fixedTime !== undefined) this.inputDate = format(this.timestamp, DEFAULT_GERMAN_DATE_STRING_FORMAT);
      else this.inputDate = format(this.timestamp, DEFAULT_HTML5_DATE_TIME_FORMAT);
    }

    // only date in future
    if (this.onlyDateInFuture) {
      if (this.fixedTime !== undefined) this.minDate = format(Date.now(), DEFAULT_GERMAN_DATE_STRING_FORMAT);
      else this.minDate = format(Date.now(), DEFAULT_HTML5_DATE_TIME_FORMAT);
    } else this.maxDate = undefined;

    // only date in past
    if (this.onlyDateInPast) {
      if (this.fixedTime !== undefined) this.maxDate = format(Date.now(), DEFAULT_GERMAN_DATE_STRING_FORMAT);
      else this.maxDate = format(Date.now(), DEFAULT_HTML5_DATE_TIME_FORMAT);
    } else this.maxDate = undefined;
  }
}
