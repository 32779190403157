import { LazyElementsModule } from "@angular-extensions/elements";
import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from "@angular/core";
import { distinctUntilChangedObj } from "@smallstack/legacy-utils";
import { BaseWidgetComponent, WidgetConfigurationSchema } from "@smallstack/widget-core";

@Component({
  selector: "web-component-widget",
  templateUrl: "./web-component-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LazyElementsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WebComponentWidgetComponent extends BaseWidgetComponent implements OnInit {
  public ready = true;

  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        webComponentUrl: {
          type: "string"
        },
        htmlTag: {
          type: "string"
        }
      }
    };
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.context$.pipe(distinctUntilChangedObj()).subscribe((data) => {
        // reload webcomponent
        this.ready = false;
        this.cdr.detectChanges();
        this.ready = true;
        this.cdr.detectChanges();
      })
    );
  }
}
