import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostBinding, computed } from "@angular/core";
import { LoadingElementDirective } from "@smallstack/common-components";
import { injectStore } from "@smallstack/typesystem-client";
import { BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "GlobalStoreSaveButton",
  templateName: "Speichern Button",
  templateDescription:
    "Dieses Widget zeigt einen Speichern Button an, der Teile oder den gesamten globalen Widget Baum Speicher speichert.",
  icon: "database",
  dataSchema: {
    type: "object",
    properties: {
      btnText: {
        type: "string",
        default: "Speichern",
        description: "Text des Speichern Buttons"
      },
      btnBlock: {
        type: "boolean",
        title: "Zeige Button als Block"
      },
      globalsKey: {
        type: "string",
        description: "Der Name der globalen Variable, welche gespeichert werden soll.",
        title: "Variable"
      },
      dataType: {
        type: "string",
        title: "Datentyp",
        description: "Der Datentyp der gespeichert werden soll.",
        "x-schema-form": {
          widget: "types"
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-global-store-save-button-widget",
  imports: [CommonModule, LoadingElementDirective],
  templateUrl: "./global-store-save-button-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalStoreSaveButtonWidgetComponent extends BaseWidgetComponent {
  @HostBinding("style.display")
  public display: string = "contents";

  private store = injectStore({ typePath: computed(() => this.data().dataType) });

  public save() {
    return async (): Promise<void> => {
      const model = this.getGlobalByDataKey("globalsKey");
      if (model.id) await this.store.update(model);
      else await this.store.create(model);
    };
  }
}
