import { NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { BaseWidgetComponent, Widget, WidgetTag } from "@smallstack/widget-core";

@Widget({
  name: "RouterOutlet",
  templateName: "Router Outlet",
  templateDescription: "Zeigt den Unterinhalt der aktuellen Seite an.",
  icon: "web",
  tags: [WidgetTag.APPLICATION]
})
@Component({
  selector: "smallstack-router-outlet-widget",
  templateUrl: "./router-outlet-widget.component.html",
  styleUrls: ["./router-outlet-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgStyle, RouterOutlet]
})
export class RouterOutletWidgetComponent extends BaseWidgetComponent {}
