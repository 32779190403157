import { Route } from "@angular/router";

const appPath = ":resellerId/tenants/:tenantId/apps/:appId";

export const appRoutes: Route[] = [
  {
    path: "",
    loadComponent: () => import("./pages/home/home.component").then((m) => m.HomeComponent)
  },
  {
    path: "error",
    loadComponent: () => import("./pages/simple-dialog/simple-dialog.component").then((m) => m.SimpleDialogComponent)
  },
  {
    path: appPath,
    loadComponent: () => import("./pages/visitor-app/visitor-app.component").then((m) => m.VisitorAppComponent),
    canActivate: []
  },
  {
    path: `${appPath}/manage`,
    loadChildren: () =>
      import("./modules/management/management.module").then(
        (managementModule) => managementModule.SmallstackManagementModule
      )
  },
  {
    path: `${appPath}/success`,
    loadComponent: () => import("./pages/simple-dialog/simple-dialog.component").then((m) => m.SimpleDialogComponent),
    canActivate: []
  },
  {
    path: `${appPath}/error`,
    loadComponent: () => import("./pages/simple-dialog/simple-dialog.component").then((m) => m.SimpleDialogComponent),
    canActivate: []
  },
  {
    path: `${appPath}/forms/:formId`,
    loadComponent: () =>
      import("./pages/visitor-form-page/visitor-form-page.component").then((m) => m.VisitorFormPageComponent),
    canActivate: []
  },
  {
    path: `${appPath}/visitor`,
    loadComponent: () => import("./pages/visitor-scan/visitor-login.component").then((m) => m.VisitorLoginComponent),
    canActivate: []
  },
  {
    path: `${appPath}/visitor/:visitorId`,
    loadComponent: () => import("./pages/visitor/visitor.component").then((m) => m.VisitorComponent),
    canActivate: []
  }
];
