import { NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostBinding, computed } from "@angular/core";
import { RouterUtilService, UrlResolverService } from "@smallstack/common-components";
import { replaceVariables } from "@smallstack/legacy-utils";
import { BaseWidgetComponent, Widget, WidgetChildComponent, WidgetTag } from "@smallstack/widget-core";

@Widget({
  name: "Link",
  templateName: "Verlinkung",
  icon: "link",
  templateDescription:
    "Eine Komponente, mit deren Hilfe innere Elemente als Verlinkung zu anderen Seiten etc. dargestellt werden können.",
  dataSchema: {
    type: "object",
    properties: {
      linkedWidget: {
        title: "Inhalt",
        type: "object",
        description: "Das zu verlinkende Element",
        "x-schema-form": {
          widget: "cms"
        }
      },
      link: {
        type: "string",
        title: "Verlinkung",
        description: "Falls gesetzt, dann leitet ein Mausklick auf die angegebene Seite weiter."
      },
      underlineLinks: {
        type: "boolean",
        title: "Texte unterstreichen",
        description: "So sehen Benutzer, was angeklickt werden kann."
      },
      showAsButton: {
        type: "boolean",
        title: "Als Button anzeigen"
      },
      activeClass: {
        type: "string",
        title: "Aktive Klasse",
        description: "CSS-Klasse, die gesetzt wird, wenn der Link aktiv ist."
      },
      inactiveClass: {
        type: "string",
        title: "Inaktive Klasse",
        description: "CSS-Klasse, die gesetzt wird, wenn der Link nicht aktiv ist."
      }
    }
  },
  tags: [WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.DASHBOARD, WidgetTag.CMS]
})
@Component({
  selector: "smallstack-link-widget",
  templateUrl: "./link-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgStyle, WidgetChildComponent]
})
export class LinkWidgetComponent extends BaseWidgetComponent {
  @HostBinding("class")
  public get hostClass(): string {
    return this.activatedClass();
  }

  protected activatedClass = computed(() => {
    const data = this.data();
    if (!data.inactiveClass) return undefined;
    let link = data?.link;
    if (!link) return data.activeClass || "opacity-100";
    if (link.startsWith("./")) link = link.substring(2);
    if (link === this.routerUtilService.activatedRoutePath()) return data.activeClass || "opacity-100";
    return data.inactiveClass;
  });

  constructor(
    private routerUtilService: RouterUtilService,
    private urlResolverService: UrlResolverService
  ) {
    super();
  }

  public async openLink(): Promise<void> {
    if (this.data()?.link && !this.widgetTreeService.editMode()) {
      void this.routerUtilService.navigateFullUrl(
        replaceVariables(this.data()?.link, { ...(await this.urlResolverService.getMap()), ...this.context() })
      );
    }
  }
}
