import { ChangeDetectionStrategy, Component, OnInit, computed, effect } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FormInputContainerComponent, SchemaFormBaseWidget } from "@smallstack/widget-core";
import { format } from "date-fns";

@Component({
  selector: "smallstack-time-form-input",
  templateUrl: "./time-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormInputContainerComponent, FormsModule]
})
export class TimeFormInputComponent extends SchemaFormBaseWidget implements OnInit {
  public defaultsToNow = computed<boolean>(() => this.schema()?.["x-schema-form"]?.defaultsToNow);

  constructor() {
    super();
    effect(() => {
      if (this.value() === undefined && this.defaultsToNow()) this.setValue(format(Date.now(), "HH:mm"));
    });
  }
}
