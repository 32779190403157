import { AsyncPipe, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { RouterUtilService, StoreRegistry } from "@smallstack/common-components";
import { ReplaceVariablesContext, replaceVariablesInObject } from "@smallstack/legacy-utils";
import { PageableStore } from "@smallstack/store";
import { ListContainerComponent } from "@smallstack/store-components";
import { BaseWidgetComponent, Widget, WidgetConfigurationSchema, WidgetTag } from "@smallstack/widget-core";

@Widget({
  name: "GridListView",
  templateName: "Grid List View",
  icon: "grid",
  templateDescription:
    "Eine Layout Komponente, welche Daten aus einer Datenquelle in einer Gitteransicht (responsive) darstellt.",
  dataSchema: async () => GridListViewWidgetComponent.getConfiguration(),
  tags: [WidgetTag.APPLICATION, WidgetTag.CBO, WidgetTag.DASHBOARD, WidgetTag.CMS],
  deprecated: true,
  deprecatedMessage: [{ value: 'Bitte verwenden Sie ab sofort das "Raster Ansicht" Widget.' }]
})
@Component({
  selector: "smallstack-grid-list-view-widget",
  templateUrl: "./grid-list-view-widget.component.html",
  styleUrls: ["./grid-list-view-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ListContainerComponent, NgStyle, AsyncPipe]
})
export class GridListViewWidgetComponent extends BaseWidgetComponent implements OnInit {
  public store: PageableStore;

  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        store: {
          type: "string",
          title: "Store",
          "x-schema-form": {
            widget: "stores"
          }
        },
        template: {
          type: "string",
          title: "HTML Template",
          description:
            "Sie können bspw. '${title}' verwenden, um auf die Eigenschaft 'title' des aktuellen Elements zuzugreifen.",
          "x-schema-form": {
            widget: "monaco",
            monacoLanguage: "html"
          }
        },
        link: {
          type: "string",
          title: "Verlinkung",
          description:
            "Falls gesetzt, dann leitet ein Mausklick auf die angegebene Seite weiter. Verwenden Sie den Platzhalter ${id} für die ID des aktuellen Elementes."
        }
      }
    };
  }

  constructor(
    private storeRegistry: StoreRegistry,
    private sanitizer: DomSanitizer,
    private routerUtilService: RouterUtilService
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.data().store) {
      this.store = this.storeRegistry.getStore(this.data().store);
      if (this.store) void this.store.load();
    }
  }

  public getProcessedTemplate(elem: unknown): SafeHtml {
    if (!this.data()?.template) return "No template defined.";
    let template = this.data().template;
    template = replaceVariablesInObject(template, elem as ReplaceVariablesContext, { removeUnmappedContent: true });
    return this.sanitizer.bypassSecurityTrustHtml(template);
  }

  public getLink(id: string): void {
    if (this.data().link) {
      const realUrl: string = this.data().link.replace("${id}", id);
      void this.routerUtilService.navigateFullUrl(realUrl);
      // const urlTree: UrlTree = this.router.parseUrl(realUrl);
      // const params: Params = urlTree?.queryParams;
      // const urlWithoutParams: string = realUrl.split("?")[0];
      // if (realUrl.includes("?"))
      //   void this.router.navigate([urlWithoutParams], {
      //     queryParams: params,
      //     relativeTo: this.activatedRoute,
      //     queryParamsHandling: "merge"
      //   });
      // else void this.router.navigate([urlWithoutParams]);
    }
  }
}
