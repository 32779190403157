import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatOption } from "@angular/material/core";
import { MatError, MatFormField, MatHint, MatLabel, MatPrefix, MatSuffix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatTooltip } from "@angular/material/tooltip";
import { filterNullish, isEmptyString } from "@smallstack/legacy-utils";
import { IconComponent } from "@smallstack/theme-components";
import { FormFieldTitleComponent, SchemaFormBaseWidget } from "@smallstack/widget-core";
import { BehaviorSubject, Observable, map, startWith } from "rxjs";

@Component({
  selector: "smallstack-autocomplete-input",
  templateUrl: "./auto-complete-form-input.component.html",
  styleUrls: ["./auto-complete-form-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormField,
    MatLabel,
    FormFieldTitleComponent,
    IconComponent,
    MatPrefix,
    MatInput,
    MatAutocompleteTrigger,
    FormsModule,
    MatAutocomplete,
    MatOption,
    MatHint,
    MatError,
    MatSuffix,
    MatTooltip,
    AsyncPipe
  ]
})
export class AutoCompleteFormInputComponent extends SchemaFormBaseWidget implements OnInit {
  public customValuesAllowed: boolean = true;
  public values$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  @Input()
  public set values(values: string[]) {
    if (Array.isArray(values)) this.values$.next(values);
  }

  public filteredOptions: Observable<string[]>;

  @ViewChild(MatAutocompleteTrigger)
  public matAutocompleteTrigger: MatAutocompleteTrigger;

  public prefixIcon: string;

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.schema$.pipe(filterNullish()).subscribe((schema) => {
        this.customValuesAllowed = schema["x-schema-form"]?.customValuesAllowed === true;
        if (this.values$.value === undefined && schema.enum !== undefined) this.values$.next(schema.enum as string[]);
        this.cdr.markForCheck();
      })
    );

    this.subscription.add(
      this.values$.subscribe((values) => {
        this.filteredOptions = this.value$.pipe(
          startWith(""),
          map((value: string) => this.filterOptions(value, values))
        );
      })
    );
  }

  private filterOptions(value: string, values: string[]): string[] {
    if (!value || isEmptyString(value)) return values;
    const filterValue = value?.toLowerCase();
    return values?.filter((option: string) => option.toLowerCase().includes(filterValue))?.sort();
  }
}
