import { AsyncPipe, NgClass, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { ScrollTopService } from "@smallstack/common-components";
import { replaceVariables } from "@smallstack/legacy-utils";
import { IconComponent } from "@smallstack/theme-components";
import { AllWidgetTags, BaseWidgetComponent, SocketAwareWidget, Widget } from "@smallstack/widget-core";
import { BehaviorSubject, combineLatest, map, pairwise, tap } from "rxjs";

@Widget({
  name: "ShrinkingHeader",
  templateName: "Shrinking Header",
  templateDescription: "Eine Komponente, die einen Text und ein Icon anzeigt und beim Scrollen kleiner wird",
  tags: AllWidgetTags,
  icon: "document-header",
  dataSchema: {
    type: "object",
    properties: {
      text: {
        type: "string",
        title: "Text"
      },
      icon: {
        type: "string",
        title: "Icon",
        "x-schema-form": { widget: "icon" }
      }
    }
  },
  sockets: [
    {
      direction: SocketDtoDirectionEnum.In,
      name: "shrink",
      type: SocketDtoTypeEnum.Undefined
    }
  ]
})
@Component({
  selector: "smallstack-shrinking-header-widget",
  templateUrl: "./shrinking-header-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgStyle, NgClass, IconComponent, AsyncPipe]
})
export class ShrinkingHeaderWidgetComponent extends BaseWidgetComponent implements SocketAwareWidget {
  protected manualShrunk$ = new BehaviorSubject<boolean>(false);
  protected height$ = combineLatest([this.scrollTopService.currentScrollPosition$, this.manualShrunk$]).pipe(
    map(([scrollTop, manualShrunk]) => {
      if (manualShrunk) return 58;
      if (scrollTop < 144 - 58) return 144 - scrollTop;
      return 58;
    })
  );
  protected iconTop$ = combineLatest([this.scrollTopService.currentScrollPosition$, this.manualShrunk$]).pipe(
    map(([scrollTop, manualShrunk]) => {
      if (manualShrunk) return 5000;
      if (scrollTop) return scrollTop / 2;
      return 0;
    })
  );
  protected text$ = combineLatest([this.data$, this.context$]).pipe(
    map(([data, context]) => {
      if (context) return replaceVariables(data.text, context);
      else return data.text;
    })
  );

  constructor(private scrollTopService: ScrollTopService) {
    super();

    // the manual shrunk reset
    this.subscription.add(
      this.scrollTopService.currentScrollPosition$
        .pipe(
          pairwise(),
          tap(([last, current]) => {
            if (last !== 0 && current === 0) this.manualShrunk$.next(false);
          })
        )
        .subscribe()
    );
  }

  public async handleSocketData(socketName: string, data?: unknown): Promise<void> {
    switch (socketName) {
      case "shrink":
        this.manualShrunk$.next(true);
        break;
    }
  }
}
