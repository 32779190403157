import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatTooltip } from "@angular/material/tooltip";
import { IconComponent } from "@smallstack/theme-components";
import { FormFieldTitleComponent, ReactiveFormBasedInputWidgetComponent } from "@smallstack/widget-core";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { map, Observable } from "rxjs";

@Component({
  selector: "smallstack-price-form-input.component",
  templateUrl: "./price-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormField,
    MatLabel,
    FormFieldTitleComponent,
    MatInput,
    FormsModule,
    CurrencyMaskModule,
    MatHint,
    MatError,
    MatSuffix,
    IconComponent,
    MatTooltip,
    AsyncPipe
  ]
})
export class PriceFormInputComponent extends ReactiveFormBasedInputWidgetComponent implements OnInit {
  protected displayValue$: Observable<string>;

  public override ngOnInit(): void {
    super.ngOnInit();
    this.displayValue$ = this.value$.pipe(map((val) => "" + val / 100));
  }

  protected updateValue(val: number): void {
    if (val) {
      let num: string = val.toFixed(2);
      num = num.replace(",", "").replace(".", "");
      this.setValue(parseInt(num));
    } else this.setValue(undefined);
    void this.validateOnChange();
  }
}
