import { inject, provideAppInitializer } from "@angular/core";
import { StoreRegistry } from "@smallstack/common-components";
import { TYPE_CONTACTS, TYPE_CORPORATIONS } from "@smallstack/typesystem";
import { SchemaFormInputsRegistry, WidgetRegistry } from "@smallstack/widget-core";
import { ContactEditorWidgetComponent } from "./components/widgets/contact-editor-widget/contact-editor-widget.component";
import { ContactPersonFormInputComponent } from "./components/widgets/contact-person-form-input/contact-person-form-input.component";
import { CustomContactFieldsInputWidgetComponent } from "./components/widgets/custom-contact-fields-input-widget/custom-contact-fields-input-widget.component";
import { ContactsStore } from "./stores/contacts.store";
import { CorporationStore } from "./stores/corporations.store";

export const CRM_INIT_PROVIDER = provideAppInitializer(() => {
  // register stores
  const storeRegistry = inject(StoreRegistry);
  storeRegistry.registerStore(TYPE_CONTACTS, inject(ContactsStore));
  storeRegistry.registerStore(TYPE_CORPORATIONS, inject(CorporationStore));

  // register input widgets
  const schemaFormInputsRegistry = inject(SchemaFormInputsRegistry);
  schemaFormInputsRegistry.addWidget("CustomContactFields", CustomContactFieldsInputWidgetComponent);
  schemaFormInputsRegistry.addWidget("contactperson", ContactPersonFormInputComponent);

  // register widgets
  inject(WidgetRegistry).registerWidget(ContactEditorWidgetComponent);
});
