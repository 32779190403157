<smallstack-datestring-editor
  [timestamp]="value()"
  (timestampChange)="setValue($event)"
  [defaultsToNow]="defaultsToNow"
  [onlyDateInFuture]="onlyDateInFuture"
  [onlyDateInPast]="onlyDateInPast"
  layout="row wrap"
  label="{{ options()?.showLabel !== false ? label : undefined }}"
  attr.data-test="schema-form-widget-datestring-{{ path() }}"
></smallstack-datestring-editor>
<smallstack-form-control-dummy [control]="formControl"></smallstack-form-control-dummy>
<smallstack-form-control-error [control]="formControl"></smallstack-form-control-error>
