import "zone.js";

import { enableProdMode } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import { browserTracingIntegration, captureException, init } from "@sentry/angular-ivy";
import { AppComponent } from "./app/app.component";
import { providers } from "./app/app.providers";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
  init({
    dsn: environment.sentryDns,
    integrations: [browserTracingIntegration({ shouldCreateSpanForRequest: (url) => url.includes("smallstack.com") })],
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1
  });
}

bootstrapApplication(AppComponent, { providers }).catch((err) => {
  captureException(err);
  console.error(err);
});
