<smallstack-form-input-container
  [options]="options()"
  [schema]="schema()"
  [path]="path()"
  [validationErrorText]="validationErrorText()"
>
  <input
    type="range"
    min="1"
    [max]="max()"
    class="range range-primary range-sm"
    step="1"
    [required]="required"
    [ngModel]="currentValue()"
    (ngModelChange)="updateValue($event); validateOnChange()"
    (blur)="validateOnBlur()"
    [placeholder]="placeholder"
    data-test="schema-form-widget-input"
    (keyup.enter)="formService.submit()"
    [ngClass]="{ 'input-error': validationErrorText() !== undefined }"
  />
  <div class="w-full flex justify-between text-xs px-2">
    @for (value of possibleValues(); track value) {
      <smallstack-i18n [data]="value.label"></smallstack-i18n>
    }
  </div>
</smallstack-form-input-container>
