@if (options$ | async; as options) {
  @if (schema$ | async; as schema) {
    <mat-form-field
      [appearance]="options?.appearance"
      attr.data-test="schema-form-widget-stringselect-{{ path() }}"
      class="h-full w-full min-h-full min-w-full"
    >
      @if (options?.showLabel !== false) {
        <mat-label>
          <smallstack-form-field-title [path]="path()"></smallstack-form-field-title>
        </mat-label>
      }
      <mat-select
        [formControl]="formControl"
        attr.data-test="schema-form-widget-stringselect-{{ path() }}-select"
        (selectionChange)="validateOnBlur()"
      >
        @for (option of schema.enum; track option) {
          <mat-option
            [value]="option"
            attr.data-test="schema-form-widget-stringselect-{{ path() }}-option-{{ option | urlconform }}"
          >
            @if (i18nPrefix) {
              <smallstack-i18n data="{{ i18nPrefix }}{{ option }}"></smallstack-i18n>
            } @else {
              <smallstack-i18n [data]="$any(option)"></smallstack-i18n>
            }
          </mat-option>
        }
      </mat-select>
      @if (schema.description && options?.showHint !== false && options?.useInfoBox !== true) {
        <mat-hint>{{ schema.description }}</mat-hint>
      }
      @if (schema.description && options?.showHint !== false && options?.useInfoBox === true) {
        <div matSuffix>
          <smallstack-icon
            name="question-mark"
            theme="ios-glyphs"
            size="16"
            [matTooltip]="schema.description"
            style="cursor: help; padding-bottom: 4px"
          ></smallstack-icon>
        </div>
      }
    </mat-form-field>
  }
}
