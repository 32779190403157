<div class="signature-container flex flex-col space-y-3 items-stretch">
  @if (options()?.showLabel !== false) {
    <mat-label [ngClass]="{ error: hasValidationErrors() }">
      <smallstack-i18n [data]="label"></smallstack-i18n>
      @if (required) {
        <span>*</span>
      }
    </mat-label>
  }
  <div class="drawing-pad" [ngClass]="{ error: hasValidationErrors() }">
    <canvas class="drawing-pad-canvas" width="50" height="200" #drawingCanvas></canvas>
    <div class="brushes">
      @for (color of brushColors; track color) {
        <button mat-icon-button (click)="setPenColor(color); $event.preventDefault()">
          <smallstack-icon alias="brush" [color]="color"></smallstack-icon>
        </button>
      }
    </div>
  </div>
  <div class="flex flex-row justify-between">
    <div>
      <mat-error>
        {{ validationErrorText() }}
      </mat-error>
    </div>
    <button (click)="clear(); $event.preventDefault()" class="btn btn-secondary">Reset</button>
  </div>
</div>
