<div class="container flex flex-col h-full w-full min-h-full min-w-full">
  @for (filter of filters; track filter; let i = $index) {
    <div class="filter-row flex flex-row space-x-3 justify-between items-center">
      <!-- Property Name-->
      <mat-form-field>
        <mat-label>Eigenschaft</mat-label>
        <input matInput [ngModel]="filter.property" (ngModelChange)="changeField(filter, 'property', $event)" />
        <mat-hint>Model Property</mat-hint>
      </mat-form-field>
      <!-- Property Name-->
      <mat-form-field>
        <mat-label>Überschrift</mat-label>
        <input matInput [ngModel]="filter.label" (ngModelChange)="changeField(filter, 'label', $event)" />
        <mat-hint>Form Field Label</mat-hint>
      </mat-form-field>
      <!-- Type -->
      <mat-form-field>
        <mat-label>Typ</mat-label>
        <mat-select [ngModel]="filter.type" (ngModelChange)="changeField(filter, 'type', $event)">
          @for (filterType of filterTypes; track filterType) {
            <mat-option [value]="filterType">
              {{ filterType }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <!-- Actions -->
      <button (click)="removeField(i)" class="basis-1/100 btn btn-warning">
        <smallstack-icon alias="delete" size="24"></smallstack-icon>
      </button>
    </div>
  }

  <div class="flex flex-row justify-end">
    <button (click)="addNewField()" class="btn btn-secondary">
      <smallstack-icon name="add" size="24"></smallstack-icon> Filter hinzufügen
    </button>
  </div>
</div>
