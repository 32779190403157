import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { WidgetDto } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { WidgetRendererOptions, WidgetTreeComponent } from "@smallstack/widget-core";

export interface DialogActionButtonDialogComponentData {
  cmsComponent: WidgetDto;
  widgetRendererOptions: WidgetRendererOptions;
  context: any;
  globals: any;
  title?: string;
  showCloseBtn?: boolean;
}

@Component({
  selector: "smallstack-dialog-action-button-dialog",
  templateUrl: "./dialog-action-button-dialog.component.html",
  styleUrls: ["./dialog-action-button-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [WidgetTreeComponent, I18nComponent]
})
export class DialogActionButtonDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogActionButtonDialogComponentData,
    public dialogRef: MatDialogRef<DialogActionButtonDialogComponent>
  ) {
    data.widgetRendererOptions.dialogRef = dialogRef;
  }

  public close(): void {
    // TODO: return the globals object as well, it could have been changed
    if (this.data.widgetRendererOptions?.editMode === true) this.dialogRef.close(this.data.cmsComponent);
    else this.dialogRef.close();
  }
}
