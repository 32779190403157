import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AxiosApiClient, UsersApi } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import { NotificationService } from "@smallstack/i18n-components";
import { BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import axios from "axios";
import { map } from "rxjs";
import { AvatarComponent } from "../../components/avatar/avatar.component";
import { UserService } from "../../services/user.service";

@Widget({
  name: "AvatarEditor",
  templateName: "Profil Bild Editor",
  templateDescription: "Mit diesem Widget können Kunden ihr Profilbild aktualisieren.",
  icon: "user-female-circle"
})
@Component({
  selector: "smallstack-avatar-editor-widget",
  imports: [CommonModule, AvatarComponent, LoadingElementDirective],
  templateUrl: "./avatar-editor-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarEditorWidgetComponent extends BaseWidgetComponent {
  protected avatarUrl$ = this.userService.currentUser$.pipe(
    map((currentUser) => {
      return currentUser?.user?.profile?.avatarUrl;
    })
  );
  protected isUploading = false;

  constructor(
    private userService: UserService,
    private axiosApiClient: AxiosApiClient,
    private notificationService: NotificationService
  ) {
    super();
  }

  protected async uploadAvatar($event: any): Promise<void> {
    const signedUrl = await this.axiosApiClient
      .get(UsersApi)
      .uploadAvatar()
      .then((res) => res.data);
    await axios.put(signedUrl, $event.target.files[0]);
    await this.userService.reloadUser();
    this.isUploading = false;
    this.cdr.markForCheck();
  }

  protected removeAvatar() {
    return async (): Promise<void> => {
      await this.notificationService.handlePromise(this.axiosApiClient.get(UsersApi).removeAvatar());
      await this.userService.reloadUser();
      this.cdr.markForCheck();
    };
  }
}
