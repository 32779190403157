import { AsyncPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { NoteDtoVisibilityEnum } from "@smallstack/axios-api-client";
import { DateComponent, I18nComponent } from "@smallstack/i18n-components";
import { InlineTranslation } from "@smallstack/i18n-shared";
import {
  filterNullish,
  getJsonByPath,
  isNonEmptyString,
  replaceVariables,
  replaceVariablesInObject
} from "@smallstack/legacy-utils";
import { LoaderComponent, isNonEmptyStoreSearch } from "@smallstack/store-components";
import { IconComponent } from "@smallstack/theme-components";
import {
  NoteType,
  SQBuilder,
  SearchByFieldMatcher,
  SearchQuery,
  TypeSchema,
  convertSearchQueryToSelector
} from "@smallstack/typesystem";
import { StoreService, TypeService } from "@smallstack/typesystem-client";
import { NoteStore, ProjectUserStore } from "@smallstack/user-components";
import { AllWidgetTags, BaseWidgetComponent, Widget, WidgetChildComponent } from "@smallstack/widget-core";
import { Observable, combineLatest, filter, map, switchMap } from "rxjs";

export interface TimelineElement {
  icon: string;
  title: InlineTranslation;
  text: InlineTranslation;
  timestamp: number;
  badges?: string[];
}

@Widget({
  name: "Timeline",
  templateName: "Timeline",
  templateDescription: "Eine Zeitleiste, welche konfigurierbare Elemente chronologisch geordnet anzeigt",
  icon: "timeline",
  dataSchema: {
    type: "object",
    properties: {
      contextVariable: {
        type: "string",
        title: "Kontext Variable",
        description: "In welcher Kontext Variable befindet sich die Entität?",
        "x-schema-form": { widget: "ContextVariable" }
      },
      sortDirection: {
        type: "string",
        title: "Sortierrichtung",
        enum: ["asc", "desc"],
        default: "desc"
      },
      showNotes: {
        type: "boolean",
        title: "Notizen anzeigen",
        default: true
      },
      highlightSender: {
        type: "boolean",
        title: "Absender hervorheben",
        default: true
      },
      additionals: {
        type: "array",
        title: "Zusätzliche Daten",
        description: "Wählen Sie hier aus, welche andere Datentypen ebenfalls in der Timeline angezeigt werden sollen",
        items: {
          title: "Weiterer Datentyp",
          type: "object",
          properties: {
            type: {
              title: "Datentype",
              type: "string",
              "x-schema-form": { widget: "types" }
            },
            query: {
              title: "Filter",
              type: "object",
              "x-schema-form": { widget: "searchquery" }
            },
            title: {
              title: "Titel des Timeline Eintrags",
              description: "Falls leer, dann wird die Standard Repräsentation des Datentyps verwendet.",
              type: "string"
            },
            description: {
              title: "Beschreibung des Timeline Eintrags",
              description: "Falls leer, dann wird die Standard Beschreibung des Datentyps verwendet.",
              type: "string"
            }
          }
        } as TypeSchema
      }
    }
  },
  tags: AllWidgetTags
})
@Component({
  selector: "smallstack-timeline-widget",
  templateUrl: "./timeline-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./timeline-widget.component.scss"],
  imports: [IconComponent, WidgetChildComponent, NgClass, I18nComponent, DateComponent, LoaderComponent, AsyncPipe]
})
export class TimelineWidgetComponent extends BaseWidgetComponent {
  private storeService = inject(StoreService);

  protected elements$: Observable<TimelineElement[]> = combineLatest([this.context$, this.data$]).pipe(
    filterNullish({ filterEmptyObjects: true }),
    map(([context, data]) => {
      // get entity
      return [
        context,
        data,
        isNonEmptyString(data.contextVariable) ? getJsonByPath(context, data.contextVariable) : context
      ];
    }),
    filter(([context, data, entity]) => {
      if (!entity) {
        console.error(
          "No entity found in context variable: " + data.contextVariable + ", context is: " + JSON.stringify(context)
        );
        return false;
      }
      const id = entity.id;
      if (!id) {
        console.error("No id found in context variable: " + data.contextVariable + ".id");
        return false;
      }
      return true;
    }),
    // add created and last updated
    map(([context, data, entity]) => {
      const elements: TimelineElement[] = [];
      if (entity.createdAt)
        elements.push({
          icon: "plus-2-math",
          text: [{ value: "Objekt wurde erstellt" }],
          timestamp: entity.createdAt,
          title: [{ value: "Erstellung" }]
        });
      if (entity.lastUpdatedAt)
        elements.push({
          icon: "available-updates",
          text: [{ value: "Hier wurde das Objekt zuletzt aktualisiert." }],
          timestamp: entity.lastUpdatedAt,
          title: [{ value: "Letzte Aktualisierung" }]
        });
      return [context, data, entity, elements];
    }),
    // add notes subscription to redo store search if new notes were added by user
    switchMap(([context, data, entity, elements]) =>
      this.noteStore.value$.pipe(map(() => [context, data, entity, elements]))
    ),
    // add notes to elements
    switchMap(async ([context, data, entity, elements]) => {
      const notesElements = [];
      if (data.showNotes) {
        const notes = await this.noteStore
          .getMany({
            size: 1000,
            search: SQBuilder.asString([
              { fieldname: "target.id", value: entity.id, matcher: SearchByFieldMatcher.EQUALS }
            ])
          })
          .then((page) => page.elements);
        if (notes instanceof Array && notes.length > 0)
          for (const note of notes) {
            const user = await this.projectUserStore.get(note.ownerId);
            notesElements.push({
              icon: NoteType.icon,
              title: [{ value: user?.displayName }],
              text: [{ value: note.content }],
              timestamp: note.createdAt,
              badges: note.visibility === NoteDtoVisibilityEnum.Private ? ["private"] : []
            });
          }
      }
      if (data.additionals instanceof Array) {
        for (const additional of data.additionals as Array<{
          type: string;
          query: SearchQuery;
          title?: string;
          description?: string;
        }>) {
          const dataType = await this.typeService.getTypeByPath(additional.type);
          const searchQuery = isNonEmptyStoreSearch(additional.query)
            ? SQBuilder.toBase64String(replaceVariablesInObject(additional.query, context))
            : undefined;
          const data: any[] = await this.storeService
            .getStore({ typePath: additional.type })
            .find$({ selector: convertSearchQueryToSelector(SQBuilder.fromBase64String(searchQuery)) });
          if (data instanceof Array && data.length > 0) {
            for (const dataModel of data) {
              const title = additional.title
                ? replaceVariables(additional.title, dataModel)
                : this.typeService.getRepresentation(dataType, dataModel);
              const text = additional.description
                ? replaceVariables(additional.description, dataModel)
                : this.typeService.getLongRepresentation(dataType, dataModel);
              elements.push({
                icon: dataType.icon,
                title,
                text,
                timestamp: dataModel.lastUpdatedAt,
                badges: []
              });
            }
          }
        }
      }
      return [context, data, entity, [...elements, ...notesElements]];
    }),
    // sort elements
    map(([context, data, entity, elements]) => {
      if (data.sortDirection === "desc")
        elements = elements.sort((a: TimelineElement, b: TimelineElement) => b.timestamp - a.timestamp);
      else elements = elements.sort((a: TimelineElement, b: TimelineElement) => a.timestamp - b.timestamp);
      return elements;
    })
  );

  protected showPrivates = false;

  constructor(
    private noteStore: NoteStore,
    private projectUserStore: ProjectUserStore,
    private typeService: TypeService
  ) {
    super();
  }
}
