<div style="width: 100%" class="flex flex-col space-y-3 items-start">
  @if (webUrl) {
    <div class="mat-outline flex flex-row space-x-1 items-center flex-auto">
      <smallstack-icon alias="folder-invoices" size="24"></smallstack-icon>
      <a [href]="webUrl" target="_blank">{{ webUrl | decodeUriComponent }}</a>
    </div>
  }

  @if (sharepointItemNotFound !== true) {
    <div class="flex flex-col md:flex-row flex-wrap space-x-3 w-full">
      <button (click)="openDialog()" class="btn btn-sm btn-secondary">
        <smallstack-i18n
          data="@@components.microsoftFiles.{{ webUrl ? 'changeFolder' : 'setFolder' }}"
        ></smallstack-i18n>
      </button>
      @if (webUrl) {
        <button (click)="removeFolder()" class="btn btn-sm btn-secondary">
          <smallstack-i18n data="@@components.microsoftFiles.removeFolder"></smallstack-i18n>
        </button>
      }
    </div>
  } @else {
    <div class="flex flex-row justify-start place-items-center gap-1">
      <div class="text-error">Der derzeit verlinkte Ordner wurde nicht gefunden.</div>
      <button class="btn btn-secondary btn-sm" (click)="removeFolder()">Verlinkung entfernen</button>
    </div>
  }
</div>
