import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { getGoogleMapsNavigationUrl } from "@smallstack/legacy-utils";
import { IconComponent } from "@smallstack/theme-components";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "NavigationButton",
  templateName: "Navigation Button",
  templateDescription: "Öffnet Google Maps und navigiert zur angegebenen Adresse",
  icon: "gps-device",
  tags: AllWidgetTags,
  dataSchema: {
    type: "object",
    properties: {
      contextVariable: {
        title: "Kontext Variable",
        description: "... in welcher die Adresse steckt",
        type: "string"
      },
      showIcon: {
        type: "boolean",
        title: "Zeige Icon auf Button?"
      },
      text: {
        title: "Text",
        type: "string",
        description: "Text auf dem Button"
      }
    }
  }
})
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./navigation-button-widget.component.html",
  selector: "smallstack-navigation-button-widget",
  imports: [IconComponent]
})
export class NavigationButtonWidgetComponent extends BaseWidgetComponent {
  protected address = computed(() => this.getContextReplacedData("contextVariable"));

  public async navigate(): Promise<void> {
    window.open(getGoogleMapsNavigationUrl(this.address()), "_blank");
  }
}
