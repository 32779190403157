import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatSelect } from "@angular/material/select";
import { ICON_THEMES } from "@smallstack/core-common";
import { IconComponent } from "@smallstack/theme-components";
import { ReactiveFormBasedInputWidgetComponent } from "@smallstack/widget-core";

export interface IconTheme {
  name: string;
  size: number;
  color: string;
  theme: string;
}

@Component({
  selector: "smallstack-themed-icon-form-input",
  templateUrl: "./themed-icon-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatFormField, MatLabel, MatInput, FormsModule, MatSelect, MatOption, IconComponent, AsyncPipe]
})
export class ThemedIconFormInputComponent extends ReactiveFormBasedInputWidgetComponent implements OnInit {
  public iconName: string;
  public size: number;
  public color: string;
  public theme: string;
  public themes: string[] = ICON_THEMES;

  public iconTheme: IconTheme;

  public override ngOnInit(): void {
    super.ngOnInit();

    this.iconTheme = this.formControl.value;

    this.iconName = this.iconTheme?.name;
    this.size = this.iconTheme?.size;
    this.color = this.iconTheme?.color;
    this.theme = this.iconTheme?.theme;
  }

  public saveFormControl(): void {
    if (this.iconTheme === null || this.iconTheme === undefined)
      this.iconTheme = { name: "", size: 24, color: "", theme: "" };

    this.iconTheme.name = this.iconName;
    this.iconTheme.size = this.size;
    this.iconTheme.color = this.color;
    this.iconTheme.theme = this.theme;

    this.formControl.setValue(this.iconTheme);
  }
}
