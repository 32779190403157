import { AsyncPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FormInputContainerComponent, SchemaFormBaseWidget } from "@smallstack/widget-core";
import Decimal from "decimal.js";
import { Observable, map } from "rxjs";

@Component({
  selector: "smallstack-percentage-form-input.component",
  templateUrl: "./percentage-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormInputContainerComponent, FormsModule, NgClass, AsyncPipe]
})
export class PercentageFormInputComponent extends SchemaFormBaseWidget implements OnInit {
  protected displayValue$: Observable<number>;

  public override ngOnInit(): void {
    super.ngOnInit();
    this.displayValue$ = this.value$.pipe(map((val) => new Decimal(val).mul(100).toNumber()));
  }

  protected updateValue(val: number): void {
    if (val) {
      this.setValue(
        new Decimal(val)
          .div(100) // Cents to EUR
          .toNumber()
      );
    } else this.setValue(undefined);
    void this.validateOnChange();
  }
}
