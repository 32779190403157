import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { UserDto } from "@smallstack/axios-api-client";
import { TYPE_USERS } from "@smallstack/typesystem";
import { injectStore } from "@smallstack/typesystem-client";
import { BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { AvatarComponent } from "../../components/avatar/avatar.component";

@Widget({
  name: "Avatar",
  templateName: "Profilbild",
  templateDescription: "Zeigt das Profilbild eines oder mehrerer Benutzer an.",
  icon: "user-female-circle",
  dataSchema: {
    type: "object",
    properties: {
      contextUserId: {
        type: "string",
        title: "Benutzer ID Kontext Variable"
      },
      size: {
        type: "string",
        enum: ["xs", "sm", "md", "lg", "xl"],
        title: "Größe"
      }
    }
  }
})
@Component({
  selector: "smallstack-avatar-widget",
  imports: [CommonModule, AvatarComponent],
  templateUrl: "./avatar-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarWidgetComponent extends BaseWidgetComponent {
  protected userStore = injectStore<UserDto>({ typePath: TYPE_USERS });

  protected avatarUrls = computed<string[]>(() => {
    const currentUserIdOrIds = this.getContextReplacedData("contextUserId");
    if (typeof currentUserIdOrIds === "string") {
      const user = this.userStore.getById(currentUserIdOrIds);
      return [user?.profile?.avatarUrl];
    } else {
      return currentUserIdOrIds.map(async (currentUserId: string) => {
        const user = this.userStore.getById(currentUserId);
        return user?.profile?.avatarUrl;
      });
    }
  });
  protected size = computed(() => {
    return this.getData("size") || "md";
  });
}
