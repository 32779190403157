import { NgClass, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit, computed } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MonacoEditorComponent } from "@smallstack/common-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { AbstractModalFormInput } from "../abstract-modal-form-input";

@Component({
  selector: "smallstack-monaco-form",
  templateUrl: "./monaco-form.component.html",
  styleUrls: ["./monaco-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [I18nComponent, MonacoEditorComponent, FormsModule, MatInputModule, IconComponent, NgTemplateOutlet, NgClass]
})
export class MonacoFormComponent extends AbstractModalFormInput implements OnInit {
  public monacoLanguage = computed<string>(() => {
    const schema = this.schema();
    if (!schema) return "json";
    return schema["x-schema-form"]?.monacoLanguage ? schema["x-schema-form"].monacoLanguage : "json";
  });
  public monacoEditorHeight = computed(() => {
    const schema = this.schema();
    if (!schema) return "500px";
    return schema["x-schema-form"]?.monacoEditorHeight ? schema["x-schema-form"].monacoEditorHeight : "500px";
  });
  public monacoData = computed<string>(() => {
    if (this.monacoLanguage() === "json") return JSON.stringify(this.value(), undefined, 2);
    else return this.value();
  });
  public error: string;

  public monacoDataChanged(str: string): void {
    if (this.monacoLanguage() === "json")
      try {
        this.setValue(JSON.parse(str));
        this.error = undefined;
      } catch (e: unknown) {
        this.error = "Ungültiges JSON";
        // can happen, no emit then
      }
    else this.setValue(str);
  }
}
