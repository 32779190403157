import { ChangeDetectionStrategy, Component } from "@angular/core";
import { WidgetDto } from "@smallstack/axios-api-client";
import { BaseWidgetComponent } from "../../widgets/base-widget.component";
import { WidgetRendererComponent } from "../widget-renderer/widget-renderer.component";

import { WidgetEditorRendererComponent } from "../widget-editor-renderer/widget-editor-renderer.component";

export class WidgetTemplateComponentFactory {
  public static withTemplate(customFieldsCompoundWidgetDefinition: WidgetDto): any {
    @Component({
      selector: "smallstack-widget-template",
      imports: [WidgetRendererComponent, WidgetEditorRendererComponent],
      templateUrl: "./widget-template.component.html",
      changeDetection: ChangeDetectionStrategy.OnPush
    })
    class WidgetTemplateComponent extends BaseWidgetComponent {
      protected widgetTemplate: WidgetDto = customFieldsCompoundWidgetDefinition;

      protected copyTemplate() {
        this.widgetTreeService.replaceWidget(this.id, this.widgetTemplate);
      }
    }
    return WidgetTemplateComponent;
  }
}
