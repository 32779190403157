import { NgFor, NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { DecodeUriComponentPipe, LoadingElementDirective } from "@smallstack/common-components";
import { I18nComponent, NotificationService } from "@smallstack/i18n-components";
import { filterNullish, isEmptyString } from "@smallstack/legacy-utils";
import { LoaderComponent } from "@smallstack/store-components";
import { IconComponent } from "@smallstack/theme-components";
import { BehaviorSubject, debounce, interval, Subscription, tap } from "rxjs";
import { MicrosoftService } from "../../../../services/microsoft.service";
import { Microsoft365Folder } from "../microsoft365-folder-input.component";

@Component({
  selector: "smallstack-microsoft365-folder-dialog",
  templateUrl: "./microsoft365-folder-dialog.component.html",
  styleUrls: ["./microsoft365-folder-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    I18nComponent,
    IconComponent,
    LoaderComponent,
    DecodeUriComponentPipe,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    LoadingElementDirective,
    MatDialogModule
  ]
})
export class Microsoft365FolderDialogComponent implements OnDestroy {
  public isLoading = true;
  public view: "groups" | "files";
  public groups: any[];
  public files: any[];
  public currentDrivePath: string;
  public currentItem: any;
  public searchResults: {
    results: Array<{ rank: number; resource: { webUrl: string; id: string; name: string } }>;
    hasMore: boolean;
    total: number;
  };
  public isSearching = false;

  private subscription: Subscription = new Subscription();
  private searchText$: BehaviorSubject<string> = new BehaviorSubject(undefined);

  constructor(
    @Inject(MAT_DIALOG_DATA) data: Microsoft365Folder,
    private microsoftService: MicrosoftService,
    private cdr: ChangeDetectorRef,
    private matDialogRef: MatDialogRef<Microsoft365FolderDialogComponent>,
    private notificationService: NotificationService
  ) {
    void this.init(data);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async init(data: Microsoft365Folder) {
    if (data?.drivePath) {
      this.currentDrivePath = data.drivePath;
      this.currentItem = await this.loadItem(data.subItemId);
      await this.openParentItem()();
    } else await this.loadGroups();

    // setup search
    this.subscription.add(
      this.searchText$
        .pipe(
          filterNullish(),
          debounce(() => interval(800)),
          tap(async (searchString) => {
            this.isSearching = true;
            this.cdr.markForCheck();
            this.searchResults = await this.microsoftService.searchInSharepointFolder(
              this.currentItem.webUrl,
              searchString
            );
            this.isSearching = false;
            this.cdr.markForCheck();
          })
        )
        .subscribe()
    );
  }

  public selectGroup(groupId: string) {
    return async (): Promise<void> => {
      this.currentDrivePath = `/groups/${groupId}/drive`;
      this.currentItem = undefined;
      await this.loadCurrentFiles();
    };
  }

  public selectItem(item: any) {
    return async (): Promise<void> => {
      this.currentItem = item;
      await this.loadCurrentFiles();
    };
  }

  public openParentItem() {
    return async (): Promise<void> => {
      if (this.currentItem === undefined) await this.loadGroups();
      else {
        try {
          if (this.currentItem?.parentReference?.id) {
            this.currentItem = await this.loadItem(this.currentItem.parentReference.id);
            await this.loadCurrentFiles();
          } else {
            this.currentDrivePath = undefined;
            this.currentItem = undefined;
            await this.loadGroups();
          }
        } catch (e) {
          await this.loadGroups();
        }
      }
    };
  }

  public sendItem(itemId: string) {
    return async (): Promise<void> => {
      this.matDialogRef.close({
        drivePath: this.currentDrivePath,
        subItemId: itemId
      } as Microsoft365Folder);
    };
  }

  public search(text: string): void {
    if (!text || isEmptyString(text)) this.searchResults = undefined;
    else this.searchText$.next(text);
  }

  private async loadGroups(): Promise<void> {
    try {
      this.isLoading = true;
      this.cdr.markForCheck();
      this.groups = await this.microsoftService.getGroups();
      this.view = "groups";
    } catch (e) {
      this.notificationService.showStandardErrorPopup(e);
    }
    this.isLoading = false;
    this.cdr.markForCheck();
  }

  private async loadCurrentFiles(): Promise<void> {
    try {
      this.isLoading = true;
      this.cdr.markForCheck();
      let path = this.currentDrivePath;
      if (this.currentItem) path += `/items/${this.currentItem.id}`;
      else path += `/root`;
      this.files = await this.microsoftService.getSharepointItemChildren(path);
      this.view = "files";
    } catch (e) {
      await this.loadGroups();
    } finally {
      this.isLoading = false;
      this.cdr.markForCheck();
    }
  }

  private async loadItem(itemId: string): Promise<any> {
    return this.microsoftService.getSharepointItem(this.currentDrivePath + "/items/" + itemId);
  }
}
