import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MarkdownEditorComponent } from "@smallstack/text-components";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";

@Component({
  selector: "smallstack-markdown-form-input",
  templateUrl: "./markdown-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MarkdownEditorComponent]
})
export class MarkdownFormInputComponent extends SchemaFormBaseWidget {}
