/* eslint-disable @typescript-eslint/member-ordering */
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { SchedulingRuleV2Dto, UserDto } from "@smallstack/axios-api-client";
import { DataStore } from "@smallstack/common-components";
import { SessionStorageStore } from "@smallstack/store";
import { IconComponent } from "@smallstack/theme-components";
import { generateSchedulingRuleV2Events } from "@smallstack/todo-shared";
import { F_CUSTOM } from "@smallstack/typesystem";
import { ONE_DAY, ONE_HOUR, ONE_WEEK, filterNullish } from "@smallstack/legacy-utils";
import { BaseWidgetComponent, TypeDialogService, Widget } from "@smallstack/widget-core";
import { endOfDay, endOfWeek, format, startOfWeek } from "date-fns";
import { de } from "date-fns/locale";
import { BehaviorSubject, Observable, combineLatest, firstValueFrom, map } from "rxjs";

export interface TaskInfo {
  title: string;
  userIds: string[];
  startPercentage: number;
  endPercentage: number;
  startedYesterday: boolean;
  continuesTomorrow: boolean;
}

export interface DayInfo {
  dayName: string;
  dayStart: number;
  tasks: TaskInfo[];
}

export interface WeekInfo {
  weekDescription: string;
  days: DayInfo[];
  percentage: number;
  totalDuration: number;
  workingHours: number;
}

export const WORKING_DAY_STARTING_HOUR = 0;
export const WORKING_DAY_ENDING_HOUR = 24;
export const WORKING_DAY_DURATION = WORKING_DAY_ENDING_HOUR - WORKING_DAY_STARTING_HOUR;

const userStatsShownStore$$ = new SessionStorageStore<string>({
  key: "resource-planning-user-stats-shown",
  parser: "string"
});

interface Task {
  scheduling: SchedulingRuleV2Dto;
  start: number;
  durationInHours: number;
  title: string;
  userIds: string[];
}

@Widget({
  name: "ResourcePlanningUserStats",
  templateName: "Ressourcen Planung Benutzer Statistiken",
  templateDescription: "Dieses Widget zeigt die momentane Auslastung eines Mitarbeiters an.",
  icon: "statistics"
})
@Component({
  selector: "smallstack-resource-planning-user-stats",
  imports: [CommonModule, IconComponent],
  templateUrl: "./resource-planning-user-stats.component.html",
  styleUrls: ["./resource-planning-user-stats.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcePlanningUserStatsComponent extends BaseWidgetComponent {
  private tasksStore = this.dataStore.for(F_CUSTOM, "tasks");

  protected zoom$ = new BehaviorSubject(3);
  protected user$ = toObservable(computed(() => this.getContextReplacedData("")));
  protected allTasks$: Observable<Task[]> = combineLatest([this.user$, this.tasksStore.value$]).pipe(
    filterNullish(),
    map(([user, tasks]) => {
      return tasks?.filter((task) => task.userIds.includes(user.id));
    })
  );
  protected weeks$: Observable<WeekInfo[]> = combineLatest([this.allTasks$, this.user$, this.zoom$]).pipe(
    map(([tasks, user, zoom]: [Task[], UserDto, number]) => {
      const startOfCurrentWeek = startOfWeek(new Date(), { weekStartsOn: 1 }).valueOf();
      const weekTasks: WeekInfo[] = [];
      const totalHours = user?.configuration?.find((c) => c.key === "workingHoursPerWeek")?.value || 40;

      // create all dates for all tasks
      let allTasksWithDates: Task[] = tasks
        .map((task) => {
          const dates = generateSchedulingRuleV2Events(task.scheduling, {
            timeFrameStart: startOfCurrentWeek,
            timeFrameEnd: endOfWeek(startOfCurrentWeek + 3 * ONE_WEEK).valueOf()
          });
          return dates?.map((date) => {
            return {
              ...task,
              start: date,
              durationInHours: task.scheduling.duration / ONE_HOUR
            };
          });
        })
        .flat();
      // filter undefined
      allTasksWithDates = allTasksWithDates.filter((task) => task);

      for (let i = 0; i < 3; i++) {
        const weekStart = startOfCurrentWeek + i * ONE_WEEK;
        const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 }).valueOf();
        const weekDescription = `KW ${format(weekStart, "w")} (${format(weekStart, "dd.MM.yyyy")} - ${format(
          weekEnd,
          "dd.MM.yyyy"
        )})`;
        const dayInfos: DayInfo[] = [];
        const tasksForCurrentWeek = allTasksWithDates.filter(
          (task) =>
            task &&
            task.start >= weekStart &&
            task.start < weekEnd &&
            task.start + task.durationInHours * ONE_HOUR <= weekEnd
        );
        for (let day = 0; day < 7; day++) {
          const dayStart = weekStart + day * ONE_DAY;
          const dayEnd = endOfDay(dayStart).valueOf();
          const dayTasks = allTasksWithDates.filter(
            (task) =>
              (task.start >= dayStart && task.start < dayEnd) ||
              (task.start + task.durationInHours * ONE_HOUR > dayStart &&
                task.start + task.durationInHours * ONE_HOUR <= dayEnd)
          );

          dayInfos.push({
            dayName: format(dayStart, "EEEEEE", { locale: de }),
            dayStart,
            tasks: dayTasks.map((task) => {
              let taskStart = task.start - dayStart;
              if (taskStart < 0) taskStart = 0;
              let relativeTaskEnd = task.start - dayStart + task.durationInHours * ONE_HOUR;
              if (relativeTaskEnd > ONE_DAY) relativeTaskEnd = ONE_DAY;
              return {
                ...task,
                startPercentage: Math.floor(
                  ((taskStart - WORKING_DAY_STARTING_HOUR * ONE_HOUR) / (WORKING_DAY_DURATION * ONE_HOUR)) * 100
                ),
                endPercentage: Math.floor((relativeTaskEnd / (WORKING_DAY_DURATION * ONE_HOUR)) * 100),
                startedYesterday: task.start - dayStart < 0,
                continuesTomorrow: task.start + task.durationInHours * ONE_HOUR > dayEnd
              };
            })
          });
        }
        const duration = tasksForCurrentWeek.reduce((acc, task) => acc + task.durationInHours, 0);
        weekTasks.push({
          weekDescription,
          days: dayInfos,
          percentage: Math.floor((duration / totalHours) * 100),
          totalDuration: duration,
          workingHours: totalHours
        });
      }
      return weekTasks;
    })
  );

  protected userStatsShownStore$$ = userStatsShownStore$$;

  constructor(
    private dataStore: DataStore,
    private typeDialogService: TypeDialogService
  ) {
    super();
    void this.tasksStore.preload();
  }

  protected showTimelineFor(userId: string, weekDescription: string): void {
    if (this.userStatsShownStore$$.value === `${userId}-${weekDescription}`) void this.userStatsShownStore$$.reset();
    else this.userStatsShownStore$$.setValue(`${userId}-${weekDescription}`);
  }

  protected zoomIn(): void {
    if (this.zoom$.value < 8) this.zoom$.next(this.zoom$.value + 1);
  }

  protected zoomOut(): void {
    if (this.zoom$.value > 1) this.zoom$.next(this.zoom$.value - 1);
  }

  protected showTaskDetails(task: TaskInfo): void {
    void this.typeDialogService.openEditor("custom/tasks", { model: task }).then(() => {
      void this.tasksStore.preload();
    });
  }

  protected async createNewTask(day: DayInfo): Promise<void> {
    const user = await firstValueFrom(this.user$);
    void this.typeDialogService
      .openEditor("custom/tasks", {
        title: "Neue Aufgabe",
        model: {
          start: day.dayStart + 8 * ONE_HOUR,
          durationInHours: 2,
          userIds: [user.id]
        }
      })
      .then(() => {
        void this.tasksStore.preload();
      });
  }
}
