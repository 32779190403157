import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { UserType } from "@smallstack/typesystem";
import { UserService } from "@smallstack/user-components";
import { AllWidgetTags, BaseWidgetComponent, Widget, WidgetChildComponent } from "@smallstack/widget-core";

@Widget({
  name: "CurrentUser",
  icon: UserType.icon,
  templateName: "Aktueller Benutzer",
  templateDescription: "Stellt den aktuellen Benutzer als Kontext zur Verfügung",
  tags: AllWidgetTags,
  dataSchema: {
    type: "object",
    properties: {
      loggedInComponent: {
        title: "Angemeldet",
        type: "object",
        description:
          "Diese Komponente wird angezeigt, wenn ein Benutzer angemeldet ist. Im Kontext ist der Benutzer dann als ${currentUser} verfügbar",
        "x-schema-form": {
          widget: "cms"
        }
      },
      loggedOutComponent: {
        title: "Abgemeldet",
        type: "object",
        description: "Falls kein Benutzer angemeldet ist, wird diese Komponente angezeigt",
        "x-schema-form": {
          widget: "cms"
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-current-user-widget",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./current-user-widget.component.html",
  imports: [WidgetChildComponent]
})
export class CurrentUserWidgetComponent extends BaseWidgetComponent {
  protected userContext = computed(() => {
    const currentUser = this.userService.currentUser();
    if (currentUser?.user?.displayName) return { ...this.context(), currentUser: currentUser.user };
    else return { ...this.context(), currentUser: undefined };
  });

  constructor(private userService: UserService) {
    super();
  }
}
