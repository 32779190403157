import { BreakpointObserver } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { WIDGET_FORM_INPUT_HTML_SIZING, WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { BaseWidgetComponent, Widget, WidgetChildComponent } from "@smallstack/widget-core";
import { map, of, switchMap } from "rxjs";

@Widget({
  name: "MaxWidthContainer",
  templateName: "Maximale Breite Container",
  templateDescription:
    "Dieses Widget stellt einen Container dar, der die verfügbare Breite auf einen Wert reduziert, ideal als unterste Ebene einer Webseite.",
  icon: "web",
  deprecated: true,
  dataSchema: {
    type: "object",
    properties: {
      maxWidth: {
        title: "Maximale Breite",
        type: "string",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
        }
      },
      align: {
        title: "Ausrichtung",
        type: "string",
        default: "center",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_SELECT,
          values: [
            { value: "left", label: "Links" },
            { value: "center", label: "Zentriert" },
            { value: "right", label: "Rechts" }
          ]
        }
      },
      padding: {
        title: "Außenabstand",
        type: "string",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_HTML_SIZING
        }
      },
      child: {
        title: "Inhalt",
        type: "object",
        "x-schema-form": {
          widget: "widget"
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-max-width-container-widget",
  imports: [CommonModule, WidgetChildComponent],
  templateUrl: "./max-width-container-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaxWidthContainerWidgetComponent extends BaseWidgetComponent {
  protected width$ = this.data$.pipe(
    switchMap((data) => {
      if (data?.maxWidth) {
        if (data.padding === undefined) data.padding = "0px";
        return this.breakpointObserver
          .observe(`(min-width: ${data.maxWidth})`)
          .pipe(
            map((result) => `calc(${result.matches ? data.maxWidth : "100vw"} - ${data.padding} - ${data.padding}))`)
          );
      }
      return of(undefined);
    })
  );

  constructor(private breakpointObserver: BreakpointObserver) {
    super();
  }
}
