import { ChangeDetectionStrategy, Component, HostBinding, ViewChild, computed, inject, signal } from "@angular/core";
import { MatTooltip } from "@angular/material/tooltip";
import { CompoundWidgetDto, SocketConnectionDto, WidgetDto } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import { DialogPopupButtons, NotificationService } from "@smallstack/i18n-components";
import { objectsEqual } from "@smallstack/legacy-utils";
import { IconComponent } from "@smallstack/theme-components";
import { TYPE_COMPOUND_WIDGETS, WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { injectStore } from "@smallstack/typesystem-client";
import {
  AllWidgetTags,
  BaseWidgetComponent,
  SocketAwareWidget,
  Widget,
  WidgetRendererOptions,
  WidgetTreeComponent
} from "@smallstack/widget-core";

@Widget({
  name: "CompoundWidget",
  icon: "widget",
  templateName: "Eigenes Widget",
  templateDescription:
    "Erstellen und verwenden Sie eigene Widgets, welche Sie aus anderen Widgets zusammengestellt haben.",
  tags: AllWidgetTags,
  dataSchema: {
    type: "object",
    properties: {
      compoundWidgetId: {
        type: "string",
        title: "Eigenes Widget",
        description: "Neue eigene Widgets können unter Erweiterungen -> Eigene Widgets erstellt werden",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_SELECT,
          type: TYPE_COMPOUND_WIDGETS,
          property: "name",
          showCreationButton: true
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-compound-widget-widget",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./compound-widget-widget.component.html",
  styleUrls: ["./compound-widget-widget.component.scss"],
  imports: [IconComponent, MatTooltip, LoadingElementDirective, WidgetTreeComponent]
})
export class CompoundWidgetWidgetComponent extends BaseWidgetComponent implements SocketAwareWidget {
  @HostBinding("style.display")
  public display: string = "contents";

  private store = injectStore<CompoundWidgetDto>({ typePath: TYPE_COMPOUND_WIDGETS });

  protected compoundWidget = computed<CompoundWidgetDto>(() => {
    if (this.data()?.compoundWidgetId) {
      return this.store.getById(this.data().compoundWidgetId);
    }
  });
  protected compoundWidgetEditMode = signal<boolean>(false);
  protected widgetRendererOptions = computed<WidgetRendererOptions>(() => {
    return {
      ...this.widgetTreeService.toWidgetRendererOptions(),
      editMode: this.compoundWidgetEditMode()
    };
  });

  protected widgetTreeComponent: WidgetTreeComponent;
  @ViewChild("widgetTree")
  protected set widgetTree(widgetTreeComponent: WidgetTreeComponent) {
    this.widgetTreeComponent = widgetTreeComponent;
  }
  protected notificationService = inject(NotificationService);

  public async handleSocketData(socketName: string, data?: unknown): Promise<void> {
    await this.widgetTreeComponent.widgetTreeService.sendSocketData("ROOT", socketName, data);
  }

  protected async updateCompoundWidget(widget: WidgetDto): Promise<void> {
    if (!this.compoundWidget()) return;
    const compoundWidget = this.compoundWidget();
    if (!objectsEqual(compoundWidget.widget, widget)) {
      compoundWidget.widget = widget;
      await this.store.update(compoundWidget);
    }
  }

  protected async updateConnections(connections: SocketConnectionDto[]): Promise<void> {
    if (!this.compoundWidget()) return;
    const compoundWidget = this.compoundWidget();
    compoundWidget.socketConnections = connections;
    await this.store.update(compoundWidget);
  }

  protected toggleEditMode() {
    return async (): Promise<void> => {
      if (this.compoundWidgetEditMode() === false) {
        const answer = await this.notificationService.popup.confirmation(
          "Eigenes Widget bearbeiten",
          "Wenn Sie dieses Widget bearbeiten, dann bearbeiten Sie alle Instanzen dieses Widgets in Ihrem Projekt. Wollen Sie fortfahren?",
          DialogPopupButtons.yesNo
        );
        if (answer === true) this.compoundWidgetEditMode.set(true);
      } else this.compoundWidgetEditMode.set(false);
    };
  }
}
