import { AsyncPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FormInputContainerComponent, SchemaFormBaseWidget } from "@smallstack/widget-core";

@Component({
  selector: "smallstack-theme-color-form-input",
  templateUrl: "./theme-color-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormInputContainerComponent, FormsModule, NgClass, AsyncPipe]
})
export class ThemeColorFormInputComponent extends SchemaFormBaseWidget {}
