import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuModule, MatMenuTrigger } from "@angular/material/menu";
import { RouterLink } from "@angular/router";
import { CurrentUserDto, UserDto } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { TypeSchema } from "@smallstack/typesystem";
import { AvatarComponent, UserService } from "@smallstack/user-components";
import { BaseWidgetComponent } from "@smallstack/widget-core";
import { ComponentDialogComponent, ComponentDialogData } from "../../component-dialog/component-dialog.component";

@Component({
  selector: "smallstack-login-status-widget",
  templateUrl: "./login-status-widget.component.html",
  styleUrls: ["./login-status-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AvatarComponent, IconComponent, MatMenuModule, RouterLink, I18nComponent]
})
export class LoginStatusWidgetComponent extends BaseWidgetComponent implements OnInit {
  public currentUser: UserDto;

  @ViewChild(MatMenuTrigger)
  private menuTrigger: MatMenuTrigger;

  public static getConfiguration(): TypeSchema {
    return {
      type: "object",
      properties: {
        backgroundColor: {
          title: "Hintergrundfarbe",
          "x-schema-form": {
            widget: "color"
          },
          default: "#FFFFFF"
        },
        fontColor: {
          title: "Schriftfarbe",
          "x-schema-form": {
            widget: "color"
          },
          default: "#000000"
        },
        viewType: {
          title: "Ansichts-Typ",
          type: "string",
          enum: ["normal", "icon"],
          default: "normal"
        },
        dialog: {
          title: "Dialog Inhalt",
          type: "object",
          description: "Bestimmen Sie hier, was für Inhalte beim Klick auf den Login Status angezeigt werden",
          "x-schema-form": {
            widget: "cms"
          }
        } as TypeSchema,
        menuButtons: {
          title: "Zusätzliche Menüpunkte",
          type: "array",
          items: {
            type: "object",
            properties: {
              label: {
                title: "Text",
                type: "string"
              },
              link: {
                title: "Link",
                type: "string"
              }
            }
          }
        }
      }
    };
  }

  constructor(
    private matDialog: MatDialog,
    private userService: UserService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.userService.currentUser$.subscribe((currentUser: CurrentUserDto) => {
        this.currentUser = currentUser?.user;
        this.cdr.detectChanges();
      })
    );
  }

  public showLogin(): void {
    if (this.data().dialog)
      this.matDialog.open(ComponentDialogComponent, {
        data: {
          cmsComponent: this.data().dialog,
          showCloseBtn: false,
          widgetRendererOptions: this.widgetTreeService.toWidgetRendererOptions()
        } as ComponentDialogData,
        maxWidth: "500px",
        maxHeight: "100vh"
      });
  }

  public logout(): Promise<void> {
    return this.userService.logout();
  }

  public openMenu(): void {
    this.menuTrigger.openMenu();
  }
}
