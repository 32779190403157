import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { filterNullish, replaceVariablesInObject } from "@smallstack/legacy-utils";
import { BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { combineLatest, map } from "rxjs";

@Widget({
  name: "Stats",
  templateName: "Statistik",
  templateDescription: "Zeigt Zahlen als große Statistiken an.",
  icon: "123--v1",
  dataSchema: {
    type: "object",
    properties: {
      showAsVertical: {
        type: "boolean",
        title: "Vertikal anzeigen"
      },
      statistics: {
        type: "array",
        title: "Statistiken",
        items: {
          type: "object",
          properties: {
            value: {
              type: "string",
              title: "Wert vom Kontext"
            },
            title: { type: "string", title: "Title" },
            description: { type: "string", title: "Beschreibung" }
          }
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-stats-widget",
  imports: [CommonModule],
  templateUrl: "./stats-widget.component.html",
  styleUrls: ["./stats-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatsWidgetComponent extends BaseWidgetComponent {
  protected statistics$ = combineLatest([this.data$, this.context$]).pipe(
    filterNullish({ filterEmptyObjects: true }),
    map(([data, context]) => {
      const statistics = data.statistics;
      return replaceVariablesInObject(statistics, context);
    })
  );
}
