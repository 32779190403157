import { registerLocaleData } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import { ErrorHandler, LOCALE_ID, inject, provideAppInitializer } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { Router, provideRouter, withRouterConfig } from "@angular/router";
import { TraceService, createErrorHandler } from "@sentry/angular-ivy";
import { addAllWidgets } from "@smallstack/all-widgets";
import { AxiosApiClient, ResellersApi } from "@smallstack/axios-api-client";
import { BackofficeIcons } from "@smallstack/backoffice-shared";
import { CMS_MODULE_INIT } from "@smallstack/cms-components";
import { DataStore, EnvironmentService } from "@smallstack/common-components";
import { ContentStageBridge } from "@smallstack/content-stage-shared";
import { CRM_INIT_PROVIDER } from "@smallstack/crm-components";
import { addDefaultFormInputComponents } from "@smallstack/form-components";
import { addDefaultPdfFormInputComponents } from "@smallstack/form-components/pdf";
import { LocaleService, TranslationLoader, TranslationStore } from "@smallstack/i18n-components";
import { loadCheckin } from "@smallstack/language-packs";
import { LINK_MODULE_INIT } from "@smallstack/link-components";
import { PRODUCT_MODULE_INIT } from "@smallstack/product-components";
import { IconThemeService } from "@smallstack/theme-components";
import { TODO_MODULE_INIT } from "@smallstack/todo-components";
import { AjvFactory } from "@smallstack/typesystem";
import { TypeService, typesystemClientProviders } from "@smallstack/typesystem-client";
import { USER_MODULE_INIT } from "@smallstack/user-components";
import {
  EXTENSION_SLOT_COMPONENT_PROVIDER,
  INPUT_WIDGETS_TRANSLATION_STORE,
  SchemaFormInputsRegistry,
  WidgetRegistry
} from "@smallstack/widget-core";
import { environment } from "../environments/environment";
import { appRoutes } from "./app.routes";
import { currentReseller$, currentTenantId$ } from "./app.subscriptions";
import { CheckinService } from "./shared/checkin.service";

registerLocaleData(localeDe);

export const providers = [
  provideAnimations(),
  provideRouter(appRoutes, withRouterConfig({ paramsInheritanceStrategy: "always" })),
  ...typesystemClientProviders,
  EXTENSION_SLOT_COMPONENT_PROVIDER,
  USER_MODULE_INIT,
  PRODUCT_MODULE_INIT,
  LINK_MODULE_INIT,
  CMS_MODULE_INIT,
  CRM_INIT_PROVIDER,
  ...TODO_MODULE_INIT,
  {
    provide: LOCALE_ID,
    useValue: "de-DE"
  },
  {
    provide: ErrorHandler,
    useValue: createErrorHandler({
      showDialog: false,
      logErrors: true
    })
  },
  {
    provide: AxiosApiClient,
    useFactory: () => {
      const pathSplits = window.location.pathname.split("/");
      let tenantId: string;
      let resellerId: string;
      if (pathSplits.length >= 6) {
        resellerId = pathSplits[1];
        tenantId = pathSplits[3];
      }
      // fix url for reseller detection
      if (resellerId === "morgenstern" && window.location.hostname === "checkin.smallstack.com") {
        // redirect
        window.location.hostname = "checkin.morgenstern.de";
        return;
      }
      // add default reseller
      if (resellerId === undefined) resellerId = "smallstack";
      currentTenantId$.next(tenantId);
      return new AxiosApiClient({
        apiUrl: environment.apiUrl,
        tenantId: () => currentTenantId$.value,
        resellerId: () => resellerId
      });
    }
  },
  provideAppInitializer(() => {
    const initializerFn = (
      (localeService: LocaleService, translationStore: TranslationStore, translationLoader: TranslationLoader) =>
      async () => {
        currentReseller$.next(
          (await new AxiosApiClient({ apiUrl: environment.apiUrl }).get(ResellersApi).getReseller()).data
        );
        if (currentTenantId$.value !== undefined) {
          translationLoader.register([loadCheckin]);
          await localeService.getAllLocales();
          await localeService.detectLocale();
        } else await localeService.setCurrentLocale("de_de");
        translationStore.addValue({ key: "common.dismiss", values: { de_de: "Ok" } });
        translationStore.addValue({ key: "actions.close", values: { de_de: "Schließen" } });
        translationStore.addValue({ key: "actions.dialogdismiss", values: { de_de: "Schließen" } });
        translationStore.addValue({
          key: "errors.validations.required",
          values: {
            de_de: "Pflichtfeld, bitte ausfüllen",
            en_us: "Required field"
          }
        });
      }
    )(inject(LocaleService), inject(TranslationStore), inject(TranslationLoader));
    return initializerFn();
  }),
  provideAppInitializer(() => {
    inject(EnvironmentService).set(environment as any);
    addDefaultFormInputComponents(inject(SchemaFormInputsRegistry));
    addDefaultPdfFormInputComponents(inject(SchemaFormInputsRegistry));
    addAllWidgets(inject(WidgetRegistry));

    // disable autofill globally
    document.addEventListener("autocomplete", (e) => {
      e.preventDefault();
    });

    inject(IconThemeService).setAliases(BackofficeIcons);
  }),
  {
    provide: TraceService,
    useFactory: (router: Router) => new TraceService(router),
    deps: [Router]
  },
  {
    provide: ContentStageBridge,
    useFactory: () => new ContentStageBridge()
  },
  LocaleService,
  TranslationStore,
  CheckinService,
  DataStore,
  { provide: INPUT_WIDGETS_TRANSLATION_STORE, useExisting: TranslationStore },
  {
    provide: AjvFactory,
    useFactory: (typeService: TypeService) => {
      return new AjvFactory(typeService);
    },
    deps: [TypeService]
  },
  provideHttpClient(withInterceptorsFromDi())
];
