import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatError, MatHint, MatSuffix } from "@angular/material/form-field";
import { MatTooltip } from "@angular/material/tooltip";
import { StoreRegistry } from "@smallstack/common-components";
import { filterNullish } from "@smallstack/legacy-utils";
import { PageableCrudStore, PageableStore } from "@smallstack/store";
import { StoreSelectComponent } from "@smallstack/store-components";
import { IconComponent } from "@smallstack/theme-components";
import { FormFieldTitleComponent, SchemaFormBaseWidget } from "@smallstack/widget-core";

@Component({
  selector: "smallstack-store-select-form-input",
  templateUrl: "./store-select-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormFieldTitleComponent,
    StoreSelectComponent,
    MatHint,
    MatError,
    MatSuffix,
    IconComponent,
    MatTooltip,
    AsyncPipe
  ]
})
/** @deprecated Use Select Form Input Widget */
export class StoreSelectFormInputComponent extends SchemaFormBaseWidget implements OnInit {
  public store: PageableStore | PageableCrudStore;
  public storeName: string;
  public propertyName: string;
  public multiple: boolean = false;
  public showCreationButton: boolean = false;

  constructor(private storeRegistry: StoreRegistry) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.schema$.pipe(filterNullish()).subscribe((schema) => {
        this.storeName = schema["x-schema-form"].store;
        this.showCreationButton = schema["x-schema-form"].showCreationButton;
        if (!this.storeName) throw new Error("no schema.x-schema-form.store set, schema: " + JSON.stringify(schema));

        this.store = this.storeRegistry.getStore(this.storeName);

        if (!this.store) throw new Error("no store found by name: " + this.storeName);

        if (schema["x-schema-form"].property) this.propertyName = schema["x-schema-form"].property;
        if (schema["x-schema-form"].propertyName) {
          this.propertyName = schema["x-schema-form"].propertyName;
          console.error("deprecated warning: use x-schema-form.property instead of x-schema-form.propertyName");
        }
        if (schema["x-schema-form"].multiple) this.multiple = schema["x-schema-form"].multiple;
        this.cdr.markForCheck();
      })
    );
  }
}
