import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatFormField, MatHint, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { MatTooltip } from "@angular/material/tooltip";
import { StoreRegistry, UrlConformPipe } from "@smallstack/common-components";

import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { FormFieldTitleComponent } from "@smallstack/widget-core";
import { StringSelectFormInputComponent } from "../stringselect-form-input/stringselect-form-input.component";

@Component({
  selector: "smallstack-stores-form-input",
  templateUrl: "./stores-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormField,
    MatLabel,
    FormFieldTitleComponent,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    I18nComponent,
    MatHint,
    MatSuffix,
    IconComponent,
    MatTooltip,
    AsyncPipe,
    UrlConformPipe
  ]
})
export class StoresFormInputComponent extends StringSelectFormInputComponent implements OnInit {
  protected storeNames: string[];

  constructor(private storeRegistry: StoreRegistry) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.schema$.subscribe((schema) => {
        this.storeNames = Object.keys(this.storeRegistry.getAllStores());
        this.cdr.markForCheck();
      })
    );
  }
}
