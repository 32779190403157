import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DecodeUriComponentPipe } from "@smallstack/common-components";
import { I18nComponent, NotificationService } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";
import { MicrosoftService } from "../../../services/microsoft.service";
import { Microsoft365FolderDialogComponent } from "./microsoft365-folder-dialog/microsoft365-folder-dialog.component";

export interface Microsoft365Folder {
  /**
   * drivePath, e.g. /groups/ID/drive
   */
  drivePath: string;
  /**
   * an itemId or undefined, if root of drive should be used
   */
  subItemId: string;
}

@Component({
  selector: "smallstack-microsoft365-folder-input",
  templateUrl: "./microsoft365-folder-input.component.html",
  styleUrls: ["./microsoft365-folder-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, I18nComponent, DecodeUriComponentPipe]
})
export class Microsoft365FolderInputComponent extends SchemaFormBaseWidget implements OnInit {
  protected webUrl: string;
  protected sharepointItemNotFound: boolean;

  constructor(
    private matDialog: MatDialog,
    private microsoftService: MicrosoftService,
    private notificationService: NotificationService
  ) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.value$.subscribe(async (value) => {
        try {
          if (value)
            this.webUrl = await this.microsoftService.getSharepointItemWebUrl(value.drivePath, value.subItemId);
          else this.webUrl = undefined;
          this.cdr.markForCheck();
        } catch (e) {
          if (e.message.includes("404")) {
            this.sharepointItemNotFound = true;
            this.cdr.markForCheck();
          } else throw e;
        }
      })
    );
  }

  public openDialog(): void {
    void this.matDialog
      .open(Microsoft365FolderDialogComponent, { data: this.getValue(), width: "80vw" })
      .afterClosed()
      .subscribe((val: Microsoft365Folder) => {
        if (val) {
          this.setValue(val);
          this.cdr.markForCheck();
        }
      });
  }

  public openFolder() {
    return async (): Promise<void> => {
      const value = this.getValue();
      const webUrl = await this.microsoftService.getSharepointItemWebUrl(value.drivePath, value.subItemId);
      if (webUrl) window.open(webUrl, "_blank");
      else
        this.notificationService.popup.error(
          "Fehler",
          "Es konnte keine Web-Url für die/den angegebene(n) Sharepoint Datei/Ordner bereitgestellt werden."
        );
    };
  }

  public removeFolder(): void {
    this.setValue(undefined);
    this.sharepointItemNotFound = undefined;
  }
}
