import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatFormField, MatHint, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatSelect } from "@angular/material/select";
import { IconComponent } from "@smallstack/theme-components";
import { FilterDataType, StoreSearchFilter } from "@smallstack/typesystem";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";

export const FORM_INPUT_SEARCH_QUERY_FILTERS = "SearchQueryFilters";

@Component({
  selector: "smallstack-search-query-filters-input",
  styleUrls: ["./search-query-filters-input.component.scss"],
  templateUrl: "./search-query-filters-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatFormField, MatLabel, MatInput, FormsModule, MatHint, MatSelect, MatOption, IconComponent]
})
export class SearchQueryFiltersInputComponent extends SchemaFormBaseWidget implements OnInit {
  public filters: StoreSearchFilter[] = [];
  public filterTypes = Object.values(FilterDataType);

  public override ngOnInit(): void {
    super.ngOnInit();
    const value = this.getValue();
    if (value instanceof Array) this.filters = value;
  }

  public addNewField(): void {
    this.filters.push({
      label: "",
      property: ""
    });
  }

  public changeField<T extends keyof StoreSearchFilter>(
    field: StoreSearchFilter,
    property: T,
    value: StoreSearchFilter[T]
  ): void {
    field[property] = value;
    this.dataChanged();
  }

  public removeField(i: number): void {
    this.filters.splice(i, 1);
    this.dataChanged();
  }

  public dataChanged(): void {
    this.setValue(this.filters);
  }
}
