@if (options$ | async; as options) {
  @if (schema$ | async; as schema) {
    @if (options?.showLabel !== false) {
      <mat-label>
        <smallstack-form-field-title [path]="path()" [showRequired]="true"></smallstack-form-field-title>
      </mat-label>
    }
    <smallstack-timestamp-editor
      [timestamp]="value$ | async"
      (timestampChange)="setValue($event)"
      [showSaveButton]="false"
      [defaultsToNow]="defaultsToNow"
      [onlyDateInFuture]="onlyDateInFuture"
      [onlyDateInPast]="onlyDateInPast"
      [fixedTime]="fixedTime"
    ></smallstack-timestamp-editor>
    @if (schema.description && options?.showHint !== false && options?.useInfoBox !== true) {
      <mat-hint>{{ schema.description }}</mat-hint>
    }
    <mat-error data-test="validation-error-text" class="justify-end">{{ validationErrorText() }} </mat-error>
    @if (schema.description && options?.showHint !== false && options?.useInfoBox === true) {
      <div matSuffix>
        <smallstack-icon
          name="question-mark"
          theme="ios-glyphs"
          color="AAAAAA"
          size="16"
          [matTooltip]="schema.description"
          style="cursor: help; padding-bottom: 8px"
        ></smallstack-icon>
      </div>
    }
  }
}
