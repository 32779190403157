import { ChangeDetectionStrategy, Component, HostBinding, computed, inject } from "@angular/core";
import { StoreRegistry } from "@smallstack/common-components";
import { ReplaceVariablesContext, isNonEmptyString, replaceVariables } from "@smallstack/legacy-utils";
import { PageableStore } from "@smallstack/store";
import { StorePropertyComponent } from "@smallstack/store-components";
import { BaseWidgetComponent, WidgetConfigurationSchema } from "@smallstack/widget-core";

@Component({
  selector: "smallstack-store-property-widget",
  templateUrl: "./store-property-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [StorePropertyComponent]
})
export class StorePropertyWidgetComponent extends BaseWidgetComponent {
  private storeRegistry = inject(StoreRegistry);

  public store = computed<PageableStore>(() => {
    const data = this.data();
    if (data.store) return this.storeRegistry.getStore<PageableStore>(data.store);
  });
  public modelId = computed<string>(() => {
    const data = this.data();
    const context = this.context();
    if (isNonEmptyString(data.id)) return replaceVariables(data.id, context as ReplaceVariablesContext);
    else return context;
  });

  public property = computed<string>(() => {
    const data = this.data();
    const context = this.context();
    return replaceVariables(data.property, context as ReplaceVariablesContext);
  });

  @HostBinding("style.display")
  public display = "contents";

  public static getConfiguration(): WidgetConfigurationSchema {
    return {
      type: "object",
      properties: {
        store: {
          type: "string",
          title: "Store",
          "x-schema-form": { widget: "types" }
        },
        id: {
          type: "string",
          title: "ID"
        },
        property: {
          type: "string",
          title: "Property"
        },
        showNotFound: {
          type: "boolean",
          title: "Zeige nicht gefundene",
          default: false
        }
      }
    };
  }
}
