<input
  #fileInput
  type="file"
  (change)="onFileSelect($event)"
  style="display: none"
  accept="image/*"
  data-test="imageupload-file-input"
  [placeholder]="placeholder"
/>
<div
  class="popup-widget-container flex flex-col justify-center items-center"
  (click)="fileInput.click(); $event.preventDefault()"
  [ngClass]="{ error: error !== undefined }"
>
  @if (!fileSrc) {
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon" data-test="default-upload-icon">
      <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
    </svg>
  }
  @if (fileSrc) {
    <img [src]="fileSrc" class="image" />
  }
  <div
    ><span>
      <smallstack-i18n [data]="label"></smallstack-i18n>
    </span>
    @if (required) {
      <span>*</span>
    }
  </div>
</div>
fileSrc undefined?: {{ fileSrc === undefined }}
