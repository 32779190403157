import { NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ICON_THEMES } from "@smallstack/core-common";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { WIDGET_FORM_INPUT_I18N } from "@smallstack/typesystem";
import { BaseWidgetComponent } from "@smallstack/widget-core";

@Component({
  selector: "smallstack-promotion-list",
  templateUrl: "./promotion-list.component.html",
  styleUrls: ["./promotion-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, I18nComponent, NgStyle]
})
export class PromotionListComponent extends BaseWidgetComponent {
  public static getConfiguration(): SchemaFormSchema {
    return {
      type: "object",
      properties: {
        blocks: {
          type: "array",
          title: "Blöcke",
          items: {
            type: "object",
            properties: {
              icon: {
                title: "Icon",
                type: "string",
                "x-schema-form": {
                  widget: "icon"
                }
              } as SchemaFormSchema,
              header: {
                title: "Überschrift",
                type: "array",
                "x-schema-form": {
                  inputWidget: WIDGET_FORM_INPUT_I18N
                }
              } as SchemaFormSchema,
              text: {
                title: "Text",
                type: "array",
                "x-schema-form": {
                  inputWidget: WIDGET_FORM_INPUT_I18N
                }
              } as SchemaFormSchema
            }
          }
        },
        theme: {
          type: "string",
          title: "Icon Theme",
          enum: ICON_THEMES
        },
        iconSize: {
          type: "number",
          title: "Icon Size",
          minimum: 24
        },
        headerSize: {
          type: "number",
          title: "Überschrift Text Größe",
          minimum: 9
        },
        textSize: {
          type: "number",
          title: "Text Größe",
          minimum: 9
        }
      }
    };
  }
}
