import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import axios from "axios";

@Widget({
  name: "RandomChuckNorrisJoke",
  templateName: "Chuck Norris Witze",
  templateDescription: "Zeigt einen zufälligen Chuck Norris Witz an",
  icon: "joker",
  tags: AllWidgetTags
})
@Component({
  selector: "smallstack-random-chuck-norris-joke-widget",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./random-chuck-norris-joke-widget.component.html",
  standalone: true
})
export class RandomChuckNorrisJokeWidgetComponent extends BaseWidgetComponent implements OnInit {
  protected joke: string;

  public ngOnInit(): void {
    void axios.get("https://api.chucknorris.io/jokes/random").then((response) => {
      if (response.status === 200) this.joke = response.data.value;
      else this.joke = "Could not load joke: " + response.statusText;
      this.cdr.markForCheck();
    });
  }
}
