import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { SocketDto, SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { LoadingElementDirective } from "@smallstack/common-components";
import { SchemaFormSchema } from "@smallstack/form-shared";
import { IconComponent } from "@smallstack/theme-components";
import { BaseWidgetComponent } from "@smallstack/widget-core";

const enum SocketNames {
  TEXT = "text"
}

@Component({
  selector: "smallstack-text-input-widget",
  templateUrl: "./text-input-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./text-input-widget.component.scss"],
  imports: [MatInput, FormsModule, IconComponent, LoadingElementDirective]
})
export class TextInputWidgetComponent extends BaseWidgetComponent {
  public static sockets: SocketDto[] = [
    { direction: SocketDtoDirectionEnum.Out, name: SocketNames.TEXT, type: SocketDtoTypeEnum.Object }
  ];

  public text: string;

  public static getConfiguration(): SchemaFormSchema {
    return {
      type: "object",
      properties: {
        defaultText: {
          type: "string",
          title: "Vorausgefüllter Text"
        },
        btnIcon: {
          type: "string",
          title: "Button Icon",
          "x-schema-form": { widget: "icon" }
        }
      }
    };
  }

  constructor() {
    super();
    this.subscription.add(
      this.data$.subscribe((data) => {
        if (data?.defaultText) this.text = data.defaultText;
      })
    );
  }

  public sendData() {
    return async (): Promise<void> => {
      await this.sendSocketData(SocketNames.TEXT, this.text);
    };
  }
}
