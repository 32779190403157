import { NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  InjectionToken,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import { MatError, MatLabel } from "@angular/material/form-field";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";
import SignaturePad, { Options } from "signature_pad";
import { DrawingPadSegment } from "./drawing-pad-segment";

export const GlobalSignaturePadConfig = new InjectionToken<Options>("Global SignaturePad default config");

export const FORM_INPUT_DRAWING_PAD = "drawingpad";

@Component({
  selector: "smallstack-drawing-pad-form-input",
  templateUrl: "./drawing-pad-form-input.component.html",
  styleUrls: ["./drawing-pad-form-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLabel, NgClass, I18nComponent, IconComponent, MatError]
})
export class DrawingPadFormInputComponent extends SchemaFormBaseWidget implements OnInit, OnChanges, AfterViewInit {
  public disabled: boolean;
  public focus: boolean;
  public brushColors: string[] = []; // ["#f43d3d", "#f43ddc", "#4511e0", "#27c453", "#e2e52b"];

  @Input() public layoutNode: any;

  protected container: HTMLElement;

  @ViewChild("drawingCanvas", { static: true })
  protected canvasRef: ElementRef;
  protected canvas: HTMLCanvasElement;

  protected signaturePad: SignaturePad;

  protected drawingPadValue: any = [];

  protected config: Options = { penColor: "#222" };

  constructor(protected renderer: Renderer2) {
    super();
  }

  public showSize(): void {
    alert(this.canvas.width + "x" + this.canvas.height);
  }

  public setPenColor(color: string): void {
    this.config.penColor = color;
    this.updateConfig(this.config);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ("config" in changes) this.updateConfig(changes.config.currentValue);
    if ("data" in changes)
      if (changes.data.currentValue === undefined) this.clear();
      else this.setValue(changes.data.currentValue);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.initializeSignaturePad();
  }

  public ngAfterViewInit(): void {
    // listen to focus
    this.renderer.listen(this.canvas, "focus", ($event) => {
      this.handleFocus(true);
    });

    // listen to blur
    this.renderer.listen(this.canvas, "blur", ($event) => {
      this.handleFocus(false);
    });

    // handle resize
    setTimeout(() => {
      this.resizeSignaturePad();
    }, 0);
    this.renderer.listen("window", "resize", ($event) => {
      this.resizeSignaturePad();
    });

    this.signaturePad.addEventListener("endStroke", () => {
      this.handleInput(this.signaturePad.toData());
    });
  }

  public override setValue(signatureData: any): void {
    if (signatureData && signatureData.constructor === [].constructor) {
      this.drawingPadValue = [...signatureData];
    } else this.drawingPadValue = null;
    if (signatureData !== null)
      this.canvas.toBlob((blob) => {
        super.setValue(this.signaturePad.toDataURL());
      });
    else {
      super.setValue(undefined);
    }
    this.cdr.detectChanges();
  }

  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.renderViewDisabled();
  }

  public toDataUrl(): string {
    return this.signaturePad.toDataURL();
  }

  public clear(): void {
    this.signaturePad.clear();
    this.setValue(null);
  }

  public resizeSignaturePad(): void {
    if (this.canvas) {
      this.resizeCanvas();

      // When the width or height of a canvas gets modified,
      // it will be automatically cleared by the browser.
      // How ever the data of the signature are still stores in the model value => this.value.
      // Because of this we have to reassign the value from this.value to the signaturePad.
      this.renderViewValue(this.drawingPadValue);
    }
  }

  /*
   * Sets the internal focus state and renders it to the view
   */
  public handleFocus(isFocus: boolean): void {
    this.focus = isFocus;
    this.renderViewFocus();
  }

  // render functions ==================================================================

  public renderViewDisabled(): void {
    this.renderer.setProperty(this.canvas, "disabled", this.disabled);

    if (this.disabled) this.renderer.addClass(this.canvas, "disabled");
    else this.renderer.removeClass(this.canvas, "disabled");
  }

  public renderViewFocus(): void {
    this.renderer.setProperty(this.canvas, "focus", this.focus);

    if (this.focus) this.renderer.addClass(this.canvas, "focus");
    else this.renderer.removeClass(this.canvas, "focus");
  }

  public renderViewValue(signatureData: any): void {
    if (signatureData && signatureData.constructor === [].constructor) this.signaturePad.fromData([...signatureData]);
    else this.signaturePad.clear();
  }

  /*
   * Depending on the compositionMode and the composing state it
   */
  protected handleInput(signatureData: any): void {
    this.setValue(signatureData);
  }

  // helper ==================================================================

  protected updateConfig(config?: Options): void {
    if (!config || config.constructor !== {}.constructor || !this.signaturePad) return;

    if ("dotSize" in config && config.dotSize) this.signaturePad.dotSize = config.dotSize;

    if ("minWidth" in config && config.minWidth >= 0) this.signaturePad.minWidth = config.minWidth;

    if ("maxWidth" in config && config.maxWidth >= 0) this.signaturePad.maxWidth = config.maxWidth;

    if ("throttle" in config && config.throttle >= 0) this.signaturePad.throttle = config.throttle;

    if ("backgroundColor" in config && config.backgroundColor) {
      this.signaturePad.backgroundColor = config.backgroundColor;
      this.resizeSignaturePad();
    }

    if ("penColor" in config && config.penColor) this.signaturePad.penColor = config.penColor;

    if ("velocityFilterWeight" in config && config.velocityFilterWeight >= 0)
      this.signaturePad.velocityFilterWeight = config.velocityFilterWeight;

    // callbacks are registered when creating signaturePad instance in initializeSignaturePad
    // onBegin
    // onEnd
  }

  protected resizeCanvas(): void {
    interface ICssWidthHeight {
      top: number;
      bottom: number;
      left: number;
      right: number;
      fullHeight: number;
      fullWidth: number;
    }

    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    // So we will have at least 1 as ration.
    const ratio = 1; // @TODO fix offset on ratios > 1. => Math.max(window.devicePixelRatio || 1, 1);

    // information needed to calculate the available width and height
    const canvasStyles = window.getComputedStyle(this.canvas, null);
    const canvasBorder: ICssWidthHeight = {} as ICssWidthHeight;
    canvasBorder.top = parseInt(canvasStyles.borderTopWidth, null) || 0;
    canvasBorder.bottom = parseInt(canvasStyles.borderBottomWidth, null) || 0;
    canvasBorder.left = parseInt(canvasStyles.borderLeftWidth, null) || 0;
    canvasBorder.right = parseInt(canvasStyles.borderRightWidth, null) || 0;
    canvasBorder.fullHeight = canvasBorder.top + canvasBorder.bottom;
    canvasBorder.fullWidth = canvasBorder.left + canvasBorder.right;

    const containerStyles = window.getComputedStyle(this.container, null);
    const parentPadding: ICssWidthHeight = {} as ICssWidthHeight;
    parentPadding.top = parseInt(containerStyles.paddingTop, null) || 0;
    parentPadding.bottom = parseInt(containerStyles.paddingBottom, null) || 0;
    parentPadding.left = parseInt(containerStyles.paddingLeft, null) || 0;
    parentPadding.right = parseInt(containerStyles.paddingRight, null) || 0;
    parentPadding.fullHeight = parentPadding.top + parentPadding.bottom;
    parentPadding.fullWidth = parentPadding.left + parentPadding.right;

    const widthToSubtract = parentPadding.fullWidth + canvasBorder.fullWidth;
    const heightToSubtract = parentPadding.fullHeight + canvasBorder.fullHeight;

    // resize canvas

    // get most right point of signature
    let signatureWidth = 0;

    if (this.drawingPadValue && [].constructor === this.drawingPadValue.constructor) {
      signatureWidth = this.drawingPadValue
        .reduce((concated: string[], arr: string[]) => concated.concat(arr), [])
        .reduce((mR: number, segment: DrawingPadSegment) => (mR < segment.x ? segment.x : mR), 0);
    }

    // get most left point of signature
    let signatureHeight = 0;

    if (this.drawingPadValue && [].constructor === this.drawingPadValue.constructor) {
      signatureHeight = this.drawingPadValue
        .reduce((concated: string[], arr: string[]) => concated.concat(arr), [])
        .reduce((mL: number, segment: DrawingPadSegment) => (mL < segment.y ? segment.y : mL), 0);
    }

    // calc new width and height
    const newCanvasWidth = Math.max(this.container.clientWidth, signatureWidth);
    const newCanvasHeight = Math.max(this.container.clientHeight, signatureHeight);

    // adopt canvas scales
    this.canvas.width = (newCanvasWidth - widthToSubtract) * ratio;
    this.canvas.height = (newCanvasHeight - heightToSubtract - 6) * ratio; // @TODO find the 6px
    this.canvas.getContext("2d").scale(ratio, ratio);

    // adopt show/hide scroll of vertical canvas container
    // this.container.style.overflowX = "inherit";
    // if (this.canvas.width + widthToSubtract > this.container.clientWidth) this.container.style.overflowX = "scroll";

    // adopt show/hide scroll of horizontal canvas container
    // this.container.style.overflowY = "inherit";
    // if (this.canvas.height > this.container.clientHeight) this.container.style.overflowY = "scroll";

    // When the width or height of a canvas gets modified,
    // it will be automatically cleared by the browser.
    // So we have to call signaturePad.clear() to make sure
    // that signaturePad.isEmpty() returns correct value in this case.
    this.signaturePad.clear();
  }

  protected initializeSignaturePad(): void {
    this.canvas = this.canvasRef.nativeElement;
    this.container = this.canvas.parentElement;
    this.container.className = this.container.className + " signature-pad";

    this.signaturePad = new SignaturePad(this.canvas, this.config);
    this.updateConfig(this.config);
  }

  protected isObject(val: any): boolean {
    const objectConstructor = {}.constructor;
    return val && val.constructor === objectConstructor;
  }
}
