import { inject, provideAppInitializer } from "@angular/core";
import { StoreRegistry } from "@smallstack/common-components";
import { TYPE_ROLES, TYPE_USER_GROUPS } from "@smallstack/typesystem";
import { WidgetRegistry } from "@smallstack/widget-core";
import { RoleStore } from "./stores/role.store";
import { UserGroupsStore } from "./stores/user-groups.store";
import { AvatarEditorWidgetComponent } from "./widgets/avatar-editor-widget/avatar-editor-widget.component";
import { AvatarWidgetComponent } from "./widgets/avatar-widget/avatar-widget.component";
import { CreateUserDialogBtnWidgetComponent } from "./widgets/create-user-dialog-btn-widget/create-user-dialog-btn-widget.component";
import { EmailVerificationBannerComponent } from "./widgets/email-verification-banner/email-verification-banner.component";
import { UserConfigurationEditorWidgetComponent } from "./widgets/user-configuration-editor-widget/user-configuration-editor-widget.component";
import { UserGroupsListWidgetComponent } from "./widgets/user-groups-list-widget/user-groups-list-widget.component";
import { UserRolesTagsWidgetComponent } from "./widgets/user-roles-tags-widget/user-roles-tags-widget.component";
import { VisibleForRoleWidgetComponent } from "./widgets/visible-for-role-widget/visible-for-role-widget.component";

export const USER_MODULE_INIT = provideAppInitializer(() => {
  // register stores
  const storeRegistry = inject(StoreRegistry);
  storeRegistry.registerStore(TYPE_ROLES, inject(RoleStore));
  storeRegistry.registerStore(TYPE_USER_GROUPS, inject(UserGroupsStore));

  // register widgets
  const widgetRegistry = inject(WidgetRegistry);
  widgetRegistry.registerWidget(EmailVerificationBannerComponent);
  widgetRegistry.registerWidget(UserRolesTagsWidgetComponent);
  widgetRegistry.registerWidget(AvatarWidgetComponent);
  widgetRegistry.registerWidget(AvatarEditorWidgetComponent);
  widgetRegistry.registerWidget(VisibleForRoleWidgetComponent);
  widgetRegistry.registerWidget(CreateUserDialogBtnWidgetComponent);
  widgetRegistry.registerWidget(UserGroupsListWidgetComponent);
  widgetRegistry.registerWidget(UserConfigurationEditorWidgetComponent);
});
