import { moveItemInArray } from "@angular/cdk/drag-drop";
import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatError, MatSuffix } from "@angular/material/form-field";
import { MatTooltip } from "@angular/material/tooltip";
import { WidgetDto } from "@smallstack/axios-api-client";
import { LoadingElementDirective, TranslatePipe } from "@smallstack/common-components";
import { cloneObject, getSchemaDefaults } from "@smallstack/legacy-utils";
import { IconComponent } from "@smallstack/theme-components";
import { FormFieldTitleComponent, SchemaFormBase, SchemaFormWidgetComponent } from "@smallstack/widget-core";
import { JSONSchema7 } from "json-schema";
import { v4 } from "uuid";

@Component({
  selector: "smallstack-array-form-input",
  templateUrl: "./array-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormFieldTitleComponent,
    IconComponent,
    MatTooltip,
    NgClass,
    SchemaFormWidgetComponent,
    LoadingElementDirective,
    MatError,
    MatSuffix,
    TranslatePipe
  ]
})
export class ArrayFormInputComponent extends SchemaFormBase {
  public dragging: boolean = false;

  public addEntry() {
    return async (): Promise<void> => {
      let dataSnapshot = this.value();
      if (!Array.isArray(dataSnapshot)) dataSnapshot = [];
      const arraySchema: JSONSchema7 = this.schema().items as JSONSchema7;
      const initialValue = getSchemaDefaults(arraySchema);
      dataSnapshot.push(initialValue);
      this.setValue(cloneObject(dataSnapshot));
      await this.validate();
    };
  }

  public cloneEntry(index: number) {
    return async (): Promise<void> => {
      const value = this.value();
      const toBeCloned = value[index];
      if (toBeCloned) {
        const cloned = cloneObject(toBeCloned);
        if (typeof cloned === "object" && toBeCloned.id !== undefined) cloned.id = v4();
        (value as WidgetDto[]).splice(index + 1, 0, cloned);
        this.setValue(value);
        await this.validate();
      }
    };
  }

  public removeEntry(index: number) {
    return async (): Promise<void> => {
      const value = this.value();
      if (value !== undefined && value instanceof Array) {
        value.splice(index, 1);

        // emit new value
        this.setValue(value);
      }
      await this.validate();
    };
  }

  public moveRelative(index: number, relativePos: number): void {
    const value = cloneObject(this.value());
    moveItemInArray(value, index, index + relativePos);
    this.setValue(value);
  }

  public moveTop(index: number): void {
    const value = cloneObject(this.value());
    moveItemInArray(value, index, 0);
    this.setValue(value);
  }

  public moveBottom(index: number): void {
    const value = cloneObject(this.value());
    moveItemInArray(value, index, value.length - 1);
    this.setValue(value);
  }

  protected trackItem(item: any): any {
    return JSON.stringify(item);
  }
}
