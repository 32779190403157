import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SocketDtoDirectionEnum, SocketDtoTypeEnum } from "@smallstack/axios-api-client";
import { StoreRegistry } from "@smallstack/common-components";
import { ContactsStore } from "@smallstack/crm-components";
import { filterNullish, isNonEmptyString } from "@smallstack/legacy-utils";
import { PageableCrudStore } from "@smallstack/store";
import { LoaderComponent } from "@smallstack/store-components";
import { IconComponent } from "@smallstack/theme-components";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { BehaviorSubject } from "rxjs";

export enum TextSearchSockets {
  SEARCH_RESULTS = "searchResults"
}

@Widget({
  name: "TextSearch",
  templateName: "Text Suche",
  templateDescription: "Ein textbasiertes Suchfeld mit welchem Datensätze gesucht werden können",
  icon: "search",
  sockets: [
    {
      direction: SocketDtoDirectionEnum.Out,
      name: TextSearchSockets.SEARCH_RESULTS,
      type: SocketDtoTypeEnum.Objectarray
    }
  ],
  tags: AllWidgetTags,
  dataSchema: {
    type: "object",
    properties: {
      type: {
        title: "Datentyp",
        type: "string",
        "x-schema-form": {
          widget: "types"
        }
      },
      globalStoreSearch: {
        title: "Globale Store Suche aktiv",
        description:
          "Falls aktiv, dann wird die Textsuche auf den globalen Store des angegebenen Datentyps angewendet.",
        type: "boolean",
        default: true
      }
    }
  }
})
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "smallstack-text-search-widget",
  templateUrl: "./text-search-widget.component.html",
  imports: [FormsModule, IconComponent, LoaderComponent, AsyncPipe]
})
export class TextSearchWidgetComponent extends BaseWidgetComponent {
  protected searchText: string;
  protected searchText$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  protected isSearching$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  #store: PageableCrudStore;

  constructor(private storeRegistry: StoreRegistry) {
    super();
    this.subscription.add(
      this.data$.pipe(filterNullish({ filterEmptyObjects: true })).subscribe((data) => {
        if (!isNonEmptyString(data.type)) throw new Error("no store configured");
        this.#store = this.storeRegistry.getStore(data.type);
        if (!this.#store) throw new Error("no store found for " + data.type);
      })
    );
    this.subscription.add(
      this.searchText$.pipe(filterNullish({ filterEmptyObjects: true })).subscribe(async (searchText) => {
        this.isSearching$.next(true);
        if (isNonEmptyString(searchText)) {
          const results = await (this.#store as ContactsStore).performSearch({
            text: searchText,
            pageSize: 50,
            pageNumber: 1
          });
          void this.sendSocketData(TextSearchSockets.SEARCH_RESULTS, results.elements);
          if (this.data().globalStoreSearch === true) {
            await this.#store.reset();
            this.#store.setValue(results.elements);
          }
        } else {
          void this.sendSocketData(TextSearchSockets.SEARCH_RESULTS, undefined);
          if (this.data().globalStoreSearch === true) {
            await this.#store.reload();
          }
        }
        this.isSearching$.next(false);
      })
    );
  }

  protected reset(): void {
    this.searchText$.next(undefined);
    this.searchText = undefined;
    void this.sendSocketData(TextSearchSockets.SEARCH_RESULTS, undefined);
    if (this.data().globalStoreSearch === true) {
      void this.#store.reload();
    }
    this.cdr.markForCheck();
  }
}
