@if (options$ | async; as options) {
  <div class="flex flex-row space-x-2">
    <mat-form-field [appearance]="options?.appearance">
      <mat-label> Name </mat-label>
      <input matInput [required]="required" [(ngModel)]="iconName" (ngModelChange)="saveFormControl()" />
    </mat-form-field>
    <mat-form-field [appearance]="options?.appearance">
      <mat-label> Größe </mat-label>
      <input
        matInput
        type="number"
        min="1"
        [required]="required"
        [(ngModel)]="size"
        (ngModelChange)="saveFormControl()"
      />
    </mat-form-field>
    <mat-form-field [appearance]="options?.appearance">
      <mat-label> Farbe </mat-label>
      <input matInput type="color" [required]="required" [(ngModel)]="color" (ngModelChange)="saveFormControl()" />
    </mat-form-field>
    <mat-form-field [appearance]="options?.appearance">
      <mat-label> Theme </mat-label>
      <mat-select [(ngModel)]="theme" (ngModelChange)="saveFormControl()">
        @for (theme of themes; track theme) {
          <mat-option [value]="theme">
            {{ theme }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <smallstack-icon [name]="iconName" [size]="size?.toString()" [color]="color" [theme]="theme"></smallstack-icon>
}
