@if (contactsStore.currentPage$ | async; as contacts) {
  <div>
    @if (data().showSearchBar !== false) {
      <smallstack-store-search
        [store]="contactsStore"
        [filterNames]="FILTER_NAMES"
        [mode]="breakpointService.isMobile() ? 'simple' : 'advanced'"
        #storeSearch
      ></smallstack-store-search>
    }
    <smallstack-list-container [store]="contactsStore" [showPager]="true">
      <ng-template #content>
        @if (!breakpointService.isMobile()) {
          <table
            mat-table
            [dataSource]="contactsStore.currentPage$"
            style="width: 100%"
            matSort
            [smallstackMatSortStore]="contactsStore"
          >
            @for (columnName of data().columns; track columnName) {
              @if (columnName !== "actions") {
                <ng-container [matColumnDef]="columnName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <smallstack-i18n [data]="'@@components.crm.contacts.' + columnName"></smallstack-i18n>
                  </th>
                  <td mat-cell *matCellDef="let contact">
                    @if (columnName !== "avatar") {
                      <span>
                        {{ contact[columnName] || "-" }}
                      </span>
                    }
                    @if (columnName === "avatar") {
                      <smallstack-avatar-image
                        [label]="contact | displayName"
                        [imageSrc]="contact.avatarUrl"
                        panelClass="in-contacts-list"
                      ></smallstack-avatar-image>
                    }
                  </td>
                </ng-container>
              }
            }
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let contact">
                <div class="flex flex-row space-x-3">
                  <button class="action-button btn btn-outline" (click)="onOpenContactDetails(contact)">
                    <smallstack-icon alias="search"></smallstack-icon>
                  </button>
                  <button class="action-button btn btn-outline" (click)="openContactEditor(contact)">
                    <smallstack-icon alias="edit"></smallstack-icon>
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="data().columns"></tr>
            <tr mat-row *matRowDef="let row; columns: data().columns"></tr>
          </table>
        }
        @if (breakpointService.isMobile()) {
          @for (contact of contacts; track contact) {
            <div class="contact-item mobile flex flex-col items-stretch" (click)="onOpenContactDetails(contact)">
              <div class="flex flex-row space-x-2 items-center">
                @if (contact.avatarUrl) {
                  <smallstack-avatar-image
                    [imageSrc]="contact.avatarUrl"
                    panelClass="in-contacts-dialog"
                    class="flex-auto"
                  ></smallstack-avatar-image>
                } @else {
                  @if ((contact.firstName | nonEmptystring) || (contact.lastName | nonEmptystring)) {
                    <smallstack-icon name="contact-card" size="32"></smallstack-icon>
                  } @else {
                    <smallstack-icon name="organization" size="32"></smallstack-icon>
                  }
                }
                <div class="flex flex-row justify-between w-full">
                  <div class="flex flex-col">
                    <strong>{{ contact | displayName }}</strong>
                    <span>{{ contact?.email }}</span>
                  </div>
                  <div>
                    <button
                      class="action-button btn btn-outline"
                      (click)="openContactEditor(contact); $event.stopPropagation(); $event.preventDefault()"
                    >
                      <smallstack-icon alias="edit"></smallstack-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        }
      </ng-template>
    </smallstack-list-container>
  </div>
} @else {
  <div class="loading loading-spinner"></div>
}
