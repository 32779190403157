import { AsyncPipe, NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatError } from "@angular/material/form-field";
import { ContactDto } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { StoreState } from "@smallstack/store";
import { IconComponent } from "@smallstack/theme-components";
import { AvatarComponent } from "@smallstack/user-components";
import {
  FormControlDummyComponent,
  FormControlErrorComponent,
  ReactiveFormBasedInputWidgetComponent
} from "@smallstack/widget-core";
import { PublicContactsStore } from "../../../stores/public-contacts.store";
import { ContactPersonSelectComponent } from "./contact-person-select/contact-person-select.component";

@Component({
  selector: "smallstack-crm-contact-person-form-input",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./contact-person-form-input.component.html",
  styleUrls: ["./contact-person-form-input.component.scss"],
  imports: [
    NgClass,
    IconComponent,
    AvatarComponent,
    I18nComponent,
    MatError,
    FormControlErrorComponent,
    FormControlDummyComponent,
    AsyncPipe
  ]
})
export class ContactPersonFormInputComponent extends ReactiveFormBasedInputWidgetComponent implements OnInit {
  public selectedContactPerson: ContactDto;

  constructor(
    private dialog: MatDialog,
    private contactStore: PublicContactsStore
  ) {
    super();
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(
      this.formService.getValueByPath$(this.path()).subscribe(async (val) => {
        if (val) this.selectedContactPerson = await this.contactStore.get(val);
        else this.selectedContactPerson = undefined;
        this.cdr.markForCheck();
      })
    );
  }

  public async openContactPersonSelect(): Promise<void> {
    if (this.contactStore.state === StoreState.INITIAL) await this.contactStore.preload();
    const mappedIds: string[] = this.schema()["x-schema-form"].contactPersons || [];
    const contactPersons =
      mappedIds?.length > 0
        ? this.contactStore.value?.filter((person) => mappedIds.includes(person.id))
        : this.contactStore.value;
    void this.dialog
      .open(ContactPersonSelectComponent, {
        width: "100%",
        height: "100%",
        disableClose: false,
        panelClass: "fullscreen-dialog",
        autoFocus: false,
        data: { contactPersons }
      })
      .afterClosed()
      .subscribe((contactPerson: ContactDto) => {
        this.setValue(contactPerson?.id);
      });
  }
}
