import { KeyValuePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AbstractControl, UntypedFormControl } from "@angular/forms";
import { I18nComponent } from "@smallstack/i18n-components";

@Component({
  selector: "smallstack-form-control-error",
  templateUrl: "./form-control-error.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [I18nComponent, KeyValuePipe]
})
export class FormControlErrorComponent {
  @Input()
  public control: UntypedFormControl | AbstractControl;
}
