import {
  FORM_INPUT_DATE_TIME,
  FORM_INPUT_MONACO,
  FORM_INPUT_SLIDER,
  FORM_INPUT_STORES,
  FORM_INPUT_TYPES
} from "@smallstack/form-shared";
import { SchemaFormInputsRegistry } from "@smallstack/widget-core";
import {
  AutocompleteTypeSearchInputWidgetComponent,
  IntegerFormInputComponent,
  MarkdownFormInputComponent,
  PercentageFormInputComponent,
  PriceFormInputComponent,
  SchemaPropertySelectorFormInputComponent,
  StoresFormInputComponent,
  ThemeColorFormInputComponent,
  ThemedIconFormInputComponent,
  TimeFormInputComponent
} from "./components/form-inputs";
import { ArrayFormInputComponent } from "./components/form-inputs/array-form-input/array-form-input.component";
import { AutoCompleteFormInputComponent } from "./components/form-inputs/auto-complete-form-input/auto-complete-form-input.component";
import { CommaSeparatedStringArrayFormInputComponent } from "./components/form-inputs/comma-separated-string-array-form-input/comma-separated-string-array-form-input.component";
import { ContextVariableInputComponent } from "./components/form-inputs/context-variable-input/context-variable-input.component";
import { DateFormInputComponent } from "./components/form-inputs/date-form-input/date-form-input.component";
import { DatestringFormInputComponent } from "./components/form-inputs/datestring-form-input/datestring-form-input.component";
import { DatetimeFormInputComponent } from "./components/form-inputs/datetime-form-input/datetime-form-input.component";
import {
  DrawingPadFormInputComponent,
  FORM_INPUT_DRAWING_PAD
} from "./components/form-inputs/drawing-pad-form-input/drawing-pad-form-input.component";
import {
  FILE_FORM_INPUT,
  FileFormInputComponent
} from "./components/form-inputs/file-form-input/file-form-input.component";
import {
  FORM_INPUT_FILE_URL,
  FileUrlFormInputComponent
} from "./components/form-inputs/file-url-form-input/file-url-form-input.component";
import { ImageUploadFormInputComponent } from "./components/form-inputs/imageupload-form-input/imageupload-form-input.component";
import { Microsoft365FolderInputComponent } from "./components/form-inputs/microsoft365-folder-input/microsoft365-folder-input.component";
import { MonacoFormComponent } from "./components/form-inputs/monaco-form/monaco-form.component";
import { MultiStringSelectFormInputComponent } from "./components/form-inputs/multi-string-select-form-input/multi-string-select-form-input.component";
import { ObjectFormInputComponent } from "./components/form-inputs/object-form-input/object-form-input.component";
import {
  FORM_INPUT_SEARCH_QUERY_FILTERS,
  SearchQueryFiltersInputComponent
} from "./components/form-inputs/search-query-filters-input/search-query-filters-input.component";
import {
  FORM_INPUT_SEARCH_QUERY,
  SearchQueryFormInputComponent
} from "./components/form-inputs/search-query-form-input/search-query-form-input.component";
import { SliderFormInputComponent } from "./components/form-inputs/slider-form-input/slider-form-input.component";
import { StoreSelectFormInputComponent } from "./components/form-inputs/store-select-form-input/store-select-form-input.component";
import { StringSelectFormInputComponent } from "./components/form-inputs/stringselect-form-input/stringselect-form-input.component";
import { TypesFormInputComponent } from "./components/form-inputs/types-form-input/types-form-input.component";

/**
 * This method can be called for adding default FormInputComponents to the registry.
 */
export function addDefaultFormInputComponents(schemaFormInputsRegistry: SchemaFormInputsRegistry): void {
  console.log("addDefaultFormInputComponents");
  schemaFormInputsRegistry.addWidget("array", ArrayFormInputComponent);
  schemaFormInputsRegistry.addWidget("autocomplete", AutoCompleteFormInputComponent);
  schemaFormInputsRegistry.addWidget("commaseparatedstringarray", CommaSeparatedStringArrayFormInputComponent);
  schemaFormInputsRegistry.addWidget("ContextVariable", ContextVariableInputComponent);
  schemaFormInputsRegistry.addWidget("date", DateFormInputComponent);
  schemaFormInputsRegistry.addWidget("datestring", DatestringFormInputComponent);
  schemaFormInputsRegistry.addWidget("datetime", DatetimeFormInputComponent);
  schemaFormInputsRegistry.addWidget("time", TimeFormInputComponent);
  schemaFormInputsRegistry.addWidget("Time", TimeFormInputComponent);
  schemaFormInputsRegistry.addWidget(FORM_INPUT_DATE_TIME, DatetimeFormInputComponent);
  schemaFormInputsRegistry.addWidget(FORM_INPUT_DRAWING_PAD, DrawingPadFormInputComponent);
  schemaFormInputsRegistry.addWidget(FILE_FORM_INPUT, FileFormInputComponent);
  schemaFormInputsRegistry.addWidget(FORM_INPUT_FILE_URL, FileUrlFormInputComponent);
  schemaFormInputsRegistry.addWidget("imageupload", ImageUploadFormInputComponent);
  schemaFormInputsRegistry.addWidget("integer", IntegerFormInputComponent);
  schemaFormInputsRegistry.addWidget(FORM_INPUT_MONACO, MonacoFormComponent);
  schemaFormInputsRegistry.addWidget("multistringselect", MultiStringSelectFormInputComponent);
  schemaFormInputsRegistry.addWidget("object", ObjectFormInputComponent);
  schemaFormInputsRegistry.addWidget(FORM_INPUT_SEARCH_QUERY, SearchQueryFormInputComponent);
  schemaFormInputsRegistry.addWidget(FORM_INPUT_SEARCH_QUERY_FILTERS, SearchQueryFiltersInputComponent);
  schemaFormInputsRegistry.addWidget("storeselect", StoreSelectFormInputComponent);
  schemaFormInputsRegistry.addWidget(FORM_INPUT_STORES, StoresFormInputComponent);
  schemaFormInputsRegistry.addWidget("stringselect", StringSelectFormInputComponent);
  schemaFormInputsRegistry.addWidget(FORM_INPUT_TYPES, TypesFormInputComponent);
  schemaFormInputsRegistry.addWidget("themedicon", ThemedIconFormInputComponent);
  schemaFormInputsRegistry.addWidget("markdown", MarkdownFormInputComponent);
  schemaFormInputsRegistry.addWidget("Microsoft365Folder", Microsoft365FolderInputComponent);
  schemaFormInputsRegistry.addWidget("SchemaPropertySelector", SchemaPropertySelectorFormInputComponent);
  schemaFormInputsRegistry.addWidget("AutocompleteTypeSearch", AutocompleteTypeSearchInputWidgetComponent);
  schemaFormInputsRegistry.addWidget("Price", PriceFormInputComponent);
  schemaFormInputsRegistry.addWidget("Percentage", PercentageFormInputComponent);
  schemaFormInputsRegistry.addWidget("ThemeColor", ThemeColorFormInputComponent);
  schemaFormInputsRegistry.addWidget(FORM_INPUT_SLIDER, SliderFormInputComponent);
}
