import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";
import { TypeSelectComponent } from "../../type-select/type-select.component";

@Component({
  selector: "smallstack-types-form-input",
  templateUrl: "./types-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TypeSelectComponent]
})
export class TypesFormInputComponent extends SchemaFormBaseWidget {
  protected usePlural = computed(() => {
    return this.schema() && this.schema()["x-schema-form"] && this.schema()["x-schema-form"].usePlural;
  });
}
