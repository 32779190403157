import { ChangeDetectionStrategy, Component, effect } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import {
  FormControlDummyComponent,
  FormControlErrorComponent,
  ReactiveFormBasedInputWidgetComponent
} from "@smallstack/widget-core";
import { DatestringEditorComponent } from "../../datestring-editor/datestring-editor.component";

@Component({
  selector: "smallstack-datestring-form-input",
  templateUrl: "./datestring-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatestringEditorComponent, FormControlDummyComponent, FormControlErrorComponent]
})
export class DatestringFormInputComponent extends ReactiveFormBasedInputWidgetComponent {
  public defaultsToNow: boolean;
  public onlyDateInFuture: boolean;
  public onlyDateInPast: boolean;

  constructor() {
    super();

    effect(() => {
      const schema = this.schema();
      if (schema) {
        this.defaultsToNow = schema["x-schema-form"].defaultsToNow;
        this.onlyDateInFuture = schema["x-schema-form"].onlyDateInFuture;
        this.onlyDateInPast = schema["x-schema-form"].onlyDateInPast;
        this.formService.formGroup.addControl(this.path(), new UntypedFormControl());
        this.cdr.markForCheck();
      }
    });
  }
}
