import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import {
  BooleanEquationOperator,
  convertDotNotationPathToJsonSchemaPath,
  setJsonProperty
} from "@smallstack/legacy-utils";
import { FilterDataType, SearchByField, SearchByFieldMatcher, SearchQuery, TypeSchema } from "@smallstack/typesystem";
import { SchemaFormBaseWidget, SchemaFormTableComponent } from "@smallstack/widget-core";

export interface SearchQueryWidgetField extends SearchByField {
  type: string;
}

export const FORM_INPUT_SEARCH_QUERY = "searchquery";

@Component({
  selector: "smallstack-search-query-form-input",
  templateUrl: "./search-query-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SchemaFormTableComponent]
})
export class SearchQueryFormInputComponent extends SchemaFormBaseWidget implements OnInit {
  protected searchQuery: SearchQuery = { fieldSearches: [], logicalOperator: "and" };

  protected searchQuerySchema: TypeSchema = {
    type: "object",
    properties: {
      logicalOperator: {
        type: "string",
        title: "Logischer Operator",
        enum: ["and", "or"],
        description: "Bestimmen Sie hier, ob die Felder mit 'und' oder mit 'oder' verknüpft werden sollen"
      },
      fieldSearches: {
        type: "array",
        title: "Felder",
        items: {
          type: "object",
          properties: {
            type: {
              type: "string",
              title: "Typ",
              enum: Object.values(FilterDataType)
            },
            fieldname: { type: "string", title: "Feldname" },
            matcher: {
              type: "string",
              enum: Object.values(SearchByFieldMatcher),
              title: "Vergleichsmethode"
            },
            caseSensitive: {
              type: "boolean",
              title: "Groß-/Kleinschreibung beachten",
              "x-schema-form": {
                rules: [
                  {
                    action: "hide",
                    if: {
                      dataPath: "../type",
                      operator: BooleanEquationOperator.NOT_EQUALS,
                      value: FilterDataType.STRING
                    }
                  }
                ]
              }
            },
            value: {
              type: "string",
              title: "Wert",
              "x-schema-form": {
                rules: [
                  {
                    action: "extendSchema",
                    if: {
                      dataPath: "../type",
                      operator: BooleanEquationOperator.EQUALS,
                      value: FilterDataType.BOOLEAN
                    },
                    schema: { type: "boolean" }
                  },
                  {
                    action: "extendSchema",
                    if: {
                      dataPath: "../type",
                      operator: BooleanEquationOperator.EQUALS,
                      value: FilterDataType.NUMBER
                    },
                    schema: { type: "number" }
                  },
                  {
                    action: "extendSchema",
                    if: {
                      dataPath: "../type",
                      operator: BooleanEquationOperator.EQUALS,
                      value: FilterDataType.DATE
                    },
                    schema: { type: "number", "x-schema-form": { widget: "datetime" } }
                  },
                  {
                    action: "extendSchema",
                    if: {
                      dataPath: "../type",
                      operator: BooleanEquationOperator.EQUALS,
                      value: FilterDataType.ID
                    },
                    schema: { type: "string" }
                  },
                  {
                    action: "extendSchema",
                    if: {
                      dataPath: "../type",
                      operator: BooleanEquationOperator.EQUALS,
                      value: FilterDataType.FOREIGN_ID
                    },
                    schema: { type: "string" }
                  }
                ]
              }
            }
          }
        }
      }
    }
  };

  public override ngOnInit(): void {
    super.ngOnInit();

    // extend schema
    const currentSchema = this.formService.getSchema();
    const schemaPath = convertDotNotationPathToJsonSchemaPath(this.path());
    this.formService.setSchema(setJsonProperty(currentSchema, schemaPath, this.searchQuerySchema));
  }
}
