@if (options$ | async; as options) {
  @if (schema$ | async; as schema) {
    <div
      [ngClass]="{ 'mat-outline': options?.showLabel !== false }"
      style="padding-top: 20px"
      attr.data-test="schema-form-widget-fileIds-{{ path() }}"
    >
      @if (options?.showLabel !== false) {
        <mat-label class="flex flex-row space-x-1 items-center">
          <smallstack-form-field-title [path]="path()"></smallstack-form-field-title>
          @if (schema.description && options?.showHint !== false && options?.useInfoBox === true) {
            <div>
              <smallstack-icon
                alias="info"
                size="12"
                class="upper-icon"
                color="a0a0a0"
                [matTooltip]="schema.description | translate"
              ></smallstack-icon>
            </div>
          }
        </mat-label>
      }
      <div class="smallstack-grid small">
        @if (value$ | async; as value) {
          @for (fileId of value; track fileId) {
            <div class="smallstack-grid-card">
              <div class="content center-center">
                <smallstack-store-model [store]="fileStore" [modelId]="fileId">
                  <ng-template let-model="model">
                    <smallstack-file-preview [file]="model"></smallstack-file-preview>
                  </ng-template>
                </smallstack-store-model>
              </div>
              <div class="actions">
                <button (click)="removeFile(fileId)" class="btn btn-outline btn-warning">
                  <smallstack-i18n data="@@actions.delete"></smallstack-i18n>
                </button>
              </div>
            </div>
          }
        }
        <div class="smallstack-grid-card cursor-pointer" [loadingFn]="addFile()">
          <div class="content center-center">
            <smallstack-icon name="add" size="80"></smallstack-icon>
          </div>
        </div>
      </div>
    </div>
  }
}
