@if (schema()) {
  <div class="outline-border" attr.data-test="schema-form-widget-array-{{ path() }}">
    @if (options()?.showLabel !== false) {
      <div class="title flex flex-row space-x-1 items-center">
        <smallstack-form-field-title [path]="path()"></smallstack-form-field-title>
        @if (schema().description && options()?.showHint !== false && options()?.useInfoBox === true) {
          <smallstack-icon
            alias="info"
            size="12"
            class="-top-2 cursor-help"
            [matTooltip]="schema().description | translate"
            themeColor="daisyTheme.secondary.textColor"
            theme="fluency-systems-regular"
          ></smallstack-icon>
        }
      </div>
    }
    @if (schema().description && options()?.showHint !== false && options()?.useInfoBox !== true) {
      <div class="text-help" style="padding-bottom: 10px">{{ schema().description }}</div>
    }

    @if (value()) {
      <div class="flex flex-col gap-3">
        @for (entry of value(); track index; let index = $index) {
          <div class="card card-compact bordered shadow flex flex-row items-center w-full">
            @if (options().compactMode !== true) {
              <div class="flex flex-col justify-center items-center gap-1 p-1">
                @if (index !== 0) {
                  <div (click)="moveTop(index)" class="btn btn-circle btn-secondary btn-sm btn-hover cursor-pointer">
                    <smallstack-icon
                      size="20"
                      name="double-up"
                      tabindex="-1"
                      matTooltipPosition="right"
                      matTooltip="Ganz nach oben verschieben"
                      themeColor="daisyTheme.secondary.textColor"
                      theme="fluency-systems-regular"
                    ></smallstack-icon>
                  </div>
                }
                @if (index !== 0) {
                  <div
                    (click)="moveRelative(index, -1)"
                    class="btn btn-circle btn-secondary btn-sm btn-hover cursor-pointer"
                  >
                    <smallstack-icon
                      size="20"
                      name="thick-arrow-pointing-up"
                      tabindex="-1"
                      matTooltipPosition="right"
                      matTooltip="Nach oben verschieben"
                      themeColor="daisyTheme.secondary.textColor"
                      theme="fluency-systems-regular"
                    ></smallstack-icon>
                  </div>
                }
                @if (index !== value().length - 1) {
                  <div
                    (click)="moveRelative(index, 1)"
                    class="btn btn-circle btn-secondary btn-sm btn-hover cursor-pointer"
                  >
                    <smallstack-icon
                      size="20"
                      name="thick-arrow-pointing-down"
                      tabindex="-1"
                      matTooltip="Nach unten verschieben"
                      matTooltipPosition="right"
                      themeColor="daisyTheme.secondary.textColor"
                      theme="fluency-systems-regular"
                    ></smallstack-icon>
                  </div>
                }
                @if (index !== value().length - 1) {
                  <div (click)="moveBottom(index)" class="btn btn-circle btn-secondary btn-sm btn-hover cursor-pointer">
                    <smallstack-icon
                      size="20"
                      name="double-down"
                      tabindex="-1"
                      matTooltip="Ganz nach unten verschieben"
                      matTooltipPosition="right"
                      themeColor="daisyTheme.secondary.textColor"
                      theme="fluency-systems-regular"
                    ></smallstack-icon>
                  </div>
                }
              </div>
            }
            <div class="grow p-2" [ngClass]="{ 'border-r': value().length <= 1, 'border-x': value().length > 1 }">
              <smallstack-schema-form-widget [path]="path() + '.' + index"> </smallstack-schema-form-widget>
            </div>
            <div class="flex flex-col justify-center items-center gap-1 p-1">
              <div class="btn btn-circle btn-secondary btn-sm btn-hover cursor-pointer" [loadingFn]="cloneEntry(index)">
                <smallstack-icon
                  name="copy--v1"
                  size="20"
                  tabindex="-1"
                  matTooltip="Duplizieren"
                  matTooltipPosition="right"
                  attr.data-testid="schema-form-widget-array-{{ path() }}-clone"
                  themeColor="daisyTheme.secondary.textColor"
                  theme="fluency-systems-regular"
                ></smallstack-icon>
              </div>
              <div
                class="btn btn-circle btn-secondary btn-sm btn-hover cursor-pointer"
                [loadingFn]="removeEntry(index)"
              >
                <smallstack-icon
                  alias="remove"
                  size="20"
                  tabindex="-1"
                  matTooltip="Löschen"
                  matTooltipPosition="right"
                  data-test="remove-btn"
                  attr.data-testid="schema-form-widget-array-{{ path() }}-delete"
                  themeColor="daisyTheme.secondary.textColor"
                  theme="fluency-systems-regular"
                ></smallstack-icon>
              </div>
            </div>
          </div>
        }
      </div>
    }
    <div class="flex flex-col" [ngClass]="{ 'mt-2': value()?.length !== 0 }">
      <button [loadingFn]="addEntry()" data-test="add-btn" class="btn btn-sm btn-block btn-secondary">
        <smallstack-icon
          name="plus-math"
          size="20"
          themeColor="daisyTheme.secondary.textColor"
          theme="fluency-systems-regular"
        ></smallstack-icon>
      </button>
    </div>

    <mat-error data-test="validation-error-text" class="justify-end">{{ validationErrorText() }} </mat-error>
    @if (schema().description && options()?.showHint !== false && options()?.useInfoBox === true) {
      <div matSuffix>
        <smallstack-icon
          name="question-mark"
          theme="ios-glyphs"
          size="20"
          [matTooltip]="schema().description"
          style="cursor: help; padding-bottom: 4px"
        ></smallstack-icon>
      </div>
    }
  </div>
}
