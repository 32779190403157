<smallstack-file-explorer-input
  [ngModel]="value$ | async"
  name="fileInput"
  (ngModelChange)="setValue($event)"
  [fileStore]="fileStore"
  [fc]="formControl"
  [placeholder]="placeholder"
  ngDefaultControl
>
</smallstack-file-explorer-input>
