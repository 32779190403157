@if (options$ | async; as options) {
  @if (schema$ | async; as schema) {
    @if (mode === "multiselect") {
      <mat-form-field [appearance]="options?.appearance" class="h-full w-full min-h-full min-w-full">
        @if (options?.showLabel !== false) {
          <mat-label><smallstack-i18n [data]="label"></smallstack-i18n></mat-label>
        }
        <mat-select [formControl]="formControl" multiple>
          @for (option of $any(schema.items)?.enum; track option) {
            <mat-option [value]="option">
              @if (i18nPrefix) {
                <smallstack-i18n data="{{ i18nPrefix }}{{ option }}"></smallstack-i18n>
              } @else {
                <smallstack-i18n [data]="option"></smallstack-i18n>
              }
            </mat-option>
          }
        </mat-select>
        @if (schema.description && options?.showHint !== false) {
          <mat-hint>{{ schema.description }}</mat-hint>
        }
      </mat-form-field>
    } @else {
      <div class="dnd-container">
        <h3 class="text-muted">Benutzt</h3>
        <div
          cdkDropList
          #todoList="cdkDropList"
          [cdkDropListData]="used"
          [cdkDropListConnectedTo]="[doneList]"
          class="dnd-list"
          (cdkDropListDropped)="drop($event)"
        >
          @for (item of used; track item) {
            <div class="dnd-box" cdkDrag>{{ item }}</div>
          }
        </div>
      </div>
      <div class="dnd-container">
        <h3 class="text-muted">Verfügbar</h3>
        <div
          cdkDropList
          #doneList="cdkDropList"
          [cdkDropListData]="available"
          [cdkDropListConnectedTo]="[todoList]"
          class="dnd-list"
          (cdkDropListDropped)="drop($event)"
        >
          @for (item of available; track item) {
            <div class="dnd-box" cdkDrag>{{ item }}</div>
          }
        </div>
      </div>
      <smallstack-form-control-dummy [control]="formControl"></smallstack-form-control-dummy>
      <smallstack-form-control-error [control]="formControl"></smallstack-form-control-error>
    }
    <ng-template #dnd>
      <div class="dnd-container">
        <h3 class="text-muted">Benutzt</h3>
        <div
          cdkDropList
          #todoList="cdkDropList"
          [cdkDropListData]="used"
          [cdkDropListConnectedTo]="[doneList]"
          class="dnd-list"
          (cdkDropListDropped)="drop($event)"
        >
          @for (item of used; track item) {
            <div class="dnd-box" cdkDrag>{{ item }}</div>
          }
        </div>
      </div>
      <div class="dnd-container">
        <h3 class="text-muted">Verfügbar</h3>
        <div
          cdkDropList
          #doneList="cdkDropList"
          [cdkDropListData]="available"
          [cdkDropListConnectedTo]="[todoList]"
          class="dnd-list"
          (cdkDropListDropped)="drop($event)"
        >
          @for (item of available; track item) {
            <div class="dnd-box" cdkDrag>{{ item }}</div>
          }
        </div>
      </div>
      <smallstack-form-control-dummy [control]="formControl"></smallstack-form-control-dummy>
      <smallstack-form-control-error [control]="formControl"></smallstack-form-control-error>
    </ng-template>
  }
}
