import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, effect } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatFormField, MatHint, MatLabel } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { I18nComponent } from "@smallstack/i18n-components";
import {
  FormControlDummyComponent,
  FormControlErrorComponent,
  ReactiveFormBasedInputWidgetComponent
} from "@smallstack/widget-core";
import { JSONSchema7 } from "json-schema";

/**

These are some schema form examples:

```typescript
colorFlower: {
  title: "Colored Flower",
  description: "Select the colors of the flower",
  type: "array",
  items: {
    type: "string",
    enum: ["blue", "green", "yellow"]
  }
} as SchemaFormSchema
```

If you want the user to be able to sort via drag and drop, you can set the mode to `dnd`:
```typescript
colorFlower: {
  title: "Colored Flower",
  description: "Select the colors of the flower",
  type: "array",
  items: {
    type: "string",
    enum: ["blue", "green", "yellow"]
  },
  "x-schema-form": {
    "x-multiselect-mode": "dnd"
  }
} as SchemaFormSchema
```
 */
@Component({
  selector: "multi-string-select-form-input",
  templateUrl: "./multi-string-select-form-input.component.html",
  styleUrls: ["./multi-string-select-form-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormField,
    MatLabel,
    I18nComponent,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    MatHint,
    CdkDropList,
    CdkDrag,
    FormControlDummyComponent,
    FormControlErrorComponent,
    AsyncPipe
  ]
})
export class MultiStringSelectFormInputComponent extends ReactiveFormBasedInputWidgetComponent {
  public used: string[] = [];
  public available: string[] = [];
  public mode: "dnd" | "multiselect" = "multiselect";

  constructor() {
    super();

    effect(() => {
      const schema = this.schema();
      if (!schema) return;
      if (schema && schema["x-schema-form"]?.["x-multiselect-mode"])
        this.mode = schema["x-schema-form"]["x-multiselect-mode"];
      const value = this.getValue();
      this.used = value instanceof Array ? value : [];
      this.available = (schema.items as JSONSchema7).enum as string[];
      this.available = this.available?.filter((str) => !this.used.includes(str));
      this.cdr.markForCheck();
    });
  }

  public drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.setValue(this.used);
  }
}
