import { ChangeDetectionStrategy, Component, computed, Signal } from "@angular/core";
import { TypeSchemaProperties } from "@smallstack/typesystem";
import { FormOptions, SchemaFormBaseWidget, SchemaFormTableComponent } from "@smallstack/widget-core";
import { DuplicatesSearchComponent } from "../../duplicates-search/duplicates-search.component";

export interface ObjectWidgetOptions extends FormOptions {
  flexDirection?: string;
}

@Component({
  selector: "smallstack-object-form-input",
  templateUrl: "./object-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SchemaFormTableComponent, DuplicatesSearchComponent]
})
export class ObjectFormInputComponent extends SchemaFormBaseWidget {
  protected mapKeys = computed(() => {
    return Object.keys(this.value());
  });
  protected mapSchema: Signal<TypeSchemaProperties> = computed(() => {
    return {
      type: "object",
      properties: this.schema().additionalProperties
    };
  });
}
