<div class="form-control w-full">
  <input
    #dateInput
    (click)="dateInput.showPicker()"
    class="input input-bordered w-full"
    [type]="fixedTime === undefined ? 'datetime-local' : 'date'"
    (change)="dateChanged($event)"
    [ngModel]="inputDate"
    [max]="maxDate"
    [min]="minDate"
    data-test="date-input"
  />
</div>
