import { ChangeDetectionStrategy, Component } from "@angular/core";
import { WidgetEditorRendererComponent, WidgetRendererComponent } from "@smallstack/widget-core";
import { CmsPageAddWidgetComponent } from "../../cms-page-add-widget/cms-page-add-widget.component";
import { BaseContainerWidgetComponent } from "./base-container-widget.component";

@Component({
  selector: "smallstack-vertical-container-widget",
  templateUrl: "./base-container-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [WidgetEditorRendererComponent, WidgetRendererComponent, CmsPageAddWidgetComponent]
})
export class VerticalContainerWidgetComponent extends BaseContainerWidgetComponent {
  public static override direction: "row" | "column" = "column";
}
