import { Directive, OnInit, TemplateRef, ViewChild, computed, inject } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SchemaFormBaseWidget } from "@smallstack/widget-core";

@Directive()
export abstract class AbstractModalFormInput extends SchemaFormBaseWidget implements OnInit {
  protected matDialog = inject(MatDialog);

  @ViewChild("editor") public editorTemplateRef: TemplateRef<any>;

  public showAsModal = computed(() => {
    if (this.formService.globalFormOptions()?.compactMode === true) return true;
    else return this.formService.schema()["x-schema-form"].asModal === true;
  });
  public openedModal: MatDialogRef<any>;

  /**
   * use this method to open the editor as modal
   */
  public openAsModal(): void {
    this.openedModal = this.matDialog.open(this.editorTemplateRef, {
      closeOnNavigation: true,
      autoFocus: false
    });
    this.subscription.add(this.openedModal.afterClosed().subscribe(() => this.cdr.markForCheck()));
  }

  /**
   * use this method to close the opened modal
   */
  public closeModal(): void {
    if (this.openedModal) this.openedModal.close();
  }
}
