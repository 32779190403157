import { NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatCard } from "@angular/material/card";
import { RouterUtilService } from "@smallstack/common-components";
import { ICON_THEMES } from "@smallstack/core-common";
import { IconComponent } from "@smallstack/theme-components";
import { TypeSchema } from "@smallstack/typesystem";
import { AllWidgetTags, BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "DashboardGrid",
  icon: "activity-grid--v2",
  tags: AllWidgetTags,
  templateName: "Dashboard Grid",
  templateDescription: "Zeigt frei definierbare Icons und Texte an, welche verlinkt werden können",
  dataSchema: {
    type: "object",
    properties: {
      entries: {
        type: "array",
        title: "Einträge",
        items: {
          type: "object",
          properties: {
            icon: {
              type: "string",
              "x-schema-form": {
                widget: "icon"
              }
            } as TypeSchema,
            label: {
              type: "string",
              title: "Text"
            },
            link: {
              type: "string",
              title: "Verlinkung"
            },
            backgroundColor: {
              type: "string",
              title: "Hintergrundfarbe",
              default: "#ffffff",
              "x-schema-form": {
                widget: "color"
              }
            } as TypeSchema,
            fontColor: {
              type: "string",
              title: "Schriftfarbe",
              default: "#000000",
              "x-schema-form": {
                widget: "color"
              }
            } as TypeSchema
          }
        }
      },
      iconTheme: {
        type: "string",
        title: "Icon Theme",
        enum: ICON_THEMES
      },
      shadow: {
        type: "boolean",
        title: "Schatten",
        description: "Sollen die Kacheln einen Schatten werfen?",
        default: true
      }
    }
  }
})
@Component({
  selector: "smallstack-dashboard-grid-widget",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./dashboard-grid-widget.component.html",
  styleUrls: ["./dashboard-grid-widget.component.scss"],
  imports: [MatCard, NgStyle, IconComponent]
})
export class DashboardGridWidgetComponent extends BaseWidgetComponent {
  constructor(private routerUtilService: RouterUtilService) {
    super();
  }

  protected open(entry: { link?: string }): void {
    if (entry?.link) {
      void this.routerUtilService.navigateFullUrl(entry.link);
    }
  }
}
