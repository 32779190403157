import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PriceDto, PriceZoneDto } from "@smallstack/axios-api-client";
import { CurrencyComponent } from "@smallstack/i18n-components";
import { StoreModelComponent } from "@smallstack/store-components";
import { PriceZoneStore } from "../../stores/price-zone.store";
import { ProductsStore } from "../../stores/products.store";

@Component({
  selector: "smallstack-product-prices",
  templateUrl: "./product-prices.component.html",
  styleUrls: ["./product-prices.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [StoreModelComponent, CurrencyComponent]
})
export class ProductPricesComponent {
  @Input()
  public productId: string;

  public productPrices: PriceDto[];

  constructor(
    public priceZoneStore: PriceZoneStore,
    public productsStore: ProductsStore
  ) {}

  public trackByIndex(index: number, model: PriceZoneDto): number {
    return index;
  }
}
