import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { MatFormField, MatHint, MatLabel, MatSuffix } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { MatTooltip } from "@angular/material/tooltip";
import { UrlConformPipe } from "@smallstack/common-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { FormFieldTitleComponent, ReactiveFormBasedInputWidgetComponent } from "@smallstack/widget-core";

@Component({
  selector: "stringselect-form-input",
  templateUrl: "./stringselect-form-input.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormField,
    MatLabel,
    FormFieldTitleComponent,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    I18nComponent,
    MatHint,
    MatSuffix,
    IconComponent,
    MatTooltip,
    AsyncPipe,
    UrlConformPipe
  ]
})
export class StringSelectFormInputComponent extends ReactiveFormBasedInputWidgetComponent {}
